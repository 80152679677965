import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { getHighestUserGames, baseUrl } from "../../Api";

const UserStates = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [gameList, setGameList] = useState([]);
  const [gameName, setGameName] = useState("soccer");

  useEffect(() => {
    (async () => {
      const data = await getHighestUserGames({
        page: page,
        limit: sizePerPage,
        gameName,
      });
      setGameList(data.data);
      setTotalSize(data.count);
    })();
  }, [gameName, sizePerPage, page, totalSize]);

  const columns = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "userrank",
      text: "User Rank",
      formatter: (cell, row, rowIndex) => {
        return `# ${cell}`
      },
    },
    {
      dataField: "userName",
      text: "Name",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row">
          <div>
            {row?.profileImagePath == null ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={`${baseUrl}uploads/${row?.profileImagePath}`}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2">
            <span className="fw-bold"> {row?.fullname} </span> <br />
            {row?.userId}
          </div>
        </div>
      ),
    },
    {
      dataField: "premium",
      text: "Premium",
      formatter: (cell, row) => {
        return row?.premium == true ? (
          <button className="btn-success-green border-0 py-2 px-3 rounded">
            Active
          </button>
        ) : (
          <button className="btn-danger-red border-0 py-2 px-3 rounded">
            Inactive
          </button>
        );
      },
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: (cell, row) => {
        return cell == 1 ? "Male" : "Female";
      },
    },
    {
      dataField: "score",
      text: "Score",
    },
    {
      dataField: "age",
      text: "Age",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],

    page: page,
    totalSize: totalSize,
    custom: true,
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[...columns]}
                      data={[...gameList]}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Highest User Game Statistics
                            </h3>
                            <Row>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-start"
                              >
                                <div className="pt-4 ps-2">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-end"
                              >
                                <div className="pt-4">
                                  <select
                                    name="game"
                                    className="shadow-box form-select border-0 bg-white  ps-2 py-3 w-50 rounded-3"
                                    value={gameName}
                                    onChange={(e) => {
                                      setGameName(e.target.value);
                                    }}
                                  >
                                    <option value="soccer">Soccer</option>
                                    <option value="basketball">
                                      Basketball
                                    </option>
                                    <option value="baseball">Baseball</option>
                                    <option value="football">Football</option>
                                    <option value="volleyball">
                                      Volleyball
                                    </option>
                                    <option value="golf">Golf</option>
                                    <option value="tennis">Tennis</option>
                                    <option value="hockey">Hockey</option>
                                    <option value="lacrosse">Lacrosse</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Highest User Game Statistics
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        onTableChange={function () {}}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {gameList.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>
                              {/* <h5>
                                Showing {(page - 1) * sizePerPage + 1} -{" "}
                                {totalSize < sizePerPage
                                  ? totalSize
                                  : sizePerPage * page >= totalSize
                                  ? totalSize
                                  : sizePerPage * page}{" "}
                                of {totalSize}
                              </h5> */}
                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={[...columns]}
                  data={[...gameList]}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Highest User Game Statistics
                        </h3>
                        <Row>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-start"
                          >
                            <div className="pt-4 ps-2">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-end"
                          >
                            <div className="pt-4">
                              <select
                                name="game"
                                className="shadow-box form-select bg-white border-0 ps-3 py-3 w-50 rounded-3"
                                value={gameName}
                                onChange={(e) => {
                                  setGameName(e.target.value);
                                }}
                              >
                                <option value="soccer">Soccer</option>
                                <option value="basketball">Basketball</option>
                                <option value="baseball">Baseball</option>
                                <option value="football">Football</option>
                                <option value="volleyball">Volleyball</option>
                                <option value="golf">Golf</option>
                                <option value="tennis">Tennis</option>
                                <option value="hockey">Hockey</option>
                                <option value="lacrosse">Lacrosse</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Highest User Game Statistics
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    onTableChange={function () {}}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {gameList.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>
                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default UserStates;

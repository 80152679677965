import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useLocation, Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  getMotivationalSongs,
  baseUrl,
  getFreeSongs,
  getPaidSongs,
} from "../../Api";
import moment from "moment/moment";
import DeleteModal from "../../components/common/DeleteModal";

const MediaList = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("name");
  const [mediaList, setMediaList] = useState(
    name == null ? "motivationalVideo" : name
  );
  const [media, setMedia] = useState([]);
  const [freeSongsList, setFreeSongsList] = useState([]);
  const [paidSongsList, setPaidSongsList] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    allMedia()
  }, [mediaList, sizePerPage, page, totalSize]);

  const allMedia = async () => {
    switch (mediaList) {
      case "motivationalVideo":
        const motivationalSong = getMotivationalSongs({
          page: page,
          limit: sizePerPage,
        })
          .then((data) => {
            setMedia(data.data);
            setTotalSize(data.count);
          })
          .catch((err) => console.log(err));
        break;
      case "freeSongs":
        const freeSong = getFreeSongs({ page: page, limit: sizePerPage })
          .then((data) => {
            setFreeSongsList(data.data);
            setTotalSize(data.count);
          })
          .catch((err) => console.log(err));
        break;
      case "paidSongs":
        const paidSong = getPaidSongs({ page: page, limit: sizePerPage })
          .then((data) => {
            setPaidSongsList(data.data);
            setTotalSize(data.count);
          })
          .catch((err) => console.log(err));
        break;
    }
  }

  const columns = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "mediaTitle",
      text: "Media Title",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row">
          <div>
            {row?.thumbnailId == null ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={`${baseUrl}uploads/${row?.thumbnailId}`}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2 align-self-center">
            <span className="fw-bold"> {row?.title} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "likeCount",
      text: "Like Counter",
    },
    {
      dataField: "createdAt",
      text: "Upload Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
            <div className="btn-group">
              <>
                <button
                  className="border-0 me-3 py-2 px-2 rounded"
                >
                  <Link to="/add-media" state={{ media: row }}>
                    <img src="assets/edit.svg" alt="edit" />
                  </Link>
                </button>

                <button
                  className="border-0  py-2 px-2 rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() =>
                    setDeleteIndex({
                      isMedia: true,
                      index: row?.id,
                    })
                  }
                >
                  <img src="assets/delete.svg" alt="delete" />
                </button>
              </>
            </div>
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "mediaTitle",
      text: "Media Title",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row">
          <div>
            {row?.thumbnail == null ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={`${baseUrl}uploads/${row?.thumbnail}`}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2 align-self-center">
            <span className="fw-bold"> {row?.name} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "createdAt",
      text: "Upload Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "artist",
      text: "Artist Name",
    },
  ];

  const columns3 = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "mediaTitle",
      text: "Media Title",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row">
          <div>
            {row?.thumbnail == null ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={`${baseUrl}uploads/${row?.thumbnail}`}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2 align-self-center">
            <span className="fw-bold"> {row?.name} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "category",
      text: "Song Track",
      formatter: (cell, row) => {
        if (cell == 1) {
          return "Visualize Track";
        } else if (cell == 2) {
          return " Repeat Track";
        } else {
          return "Hype Pre-game Track";
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Upload Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "artist",
      text: "Artist Name",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    page: page,
    totalSize: totalSize,
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <DeleteModal deleteIndex={deleteIndex} allMedia={allMedia} />
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              {mediaList == "motivationalVideo" && (
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={media}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Motivational Video
                            </h3>
                            <Row>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-start"
                              >
                                <div className="pt-4 ps-2">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-end"
                              >
                                {/* <div className="pt-4">
                                  <select
                                    value={mediaList}
                                    onChange={(e) => {
                                      setMediaList(e.target.value);
                                    }}
                                    className="shadow-box form-select border-0 bg-white  ps-2 py-3 w-50 rounded-3"
                                  >
                                    <option value="motivationalVideo">
                                      Motivational Video
                                    </option>
                                    <option value="freeSongs">
                                      Free Songs
                                    </option>
                                    <option value="paidSongs">
                                      Paid Songs
                                    </option>
                                  </select>
                                </div> */}
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Motivational Video
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={function () {}}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        columns={columns}
                                        data={media}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {media.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>
                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}

              {mediaList == "freeSongs" && (
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns2}
                      data={freeSongsList}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Motivational Video
                            </h3>
                            <Row>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-start"
                              >
                                <div className="pt-4 ps-2">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-end"
                              >
                                {/* <div className="pt-4">
                                  <select
                                    value={mediaList}
                                    onChange={(e) => {
                                      setMediaList(e.target.value);
                                    }}
                                    className="shadow-box bg-white form-select border-0  ps-2 py-3 w-50 rounded-3"
                                  >
                                    <option value="motivationalVideo">
                                      Motivational Video
                                    </option>
                                    <option value="freeSongs">
                                      Free Songs
                                    </option>
                                    <option value="paidSongs">
                                      Paid Songs
                                    </option>
                                  </select>
                                </div> */}
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Motivational Video - Free Song Track{" "}
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={function () {}}
                                        keyField="id"
                                        responsive
                                        columns={columns2}
                                        data={freeSongsList}
                                        bordered={false}
                                        striped={false}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {freeSongsList.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>
                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}

              {mediaList == "paidSongs" && (
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns3}
                      data={paidSongsList}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Motivational Video
                            </h3>
                            <Row>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-start"
                              >
                                <div className="pt-4 ps-2">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-end"
                              >
                                {/* <div className="pt-4">
                                  <select
                                    value={mediaList}
                                    onChange={(e) => {
                                      setMediaList(e.target.value);
                                    }}
                                    className="shadow-box bg-white form-select border-0  ps-2 py-3 w-50 rounded-3"
                                  >
                                    <option value="motivationalVideo">
                                      Motivational Video
                                    </option>
                                    <option value="freeSongs">
                                      Free Songs
                                    </option>
                                    <option value="paidSongs">
                                      Paid Songs
                                    </option>
                                  </select>
                                </div> */}
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Motivational Video - Paid Song Track{" "}
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={function () {}}
                                        keyField="id"
                                        responsive
                                        columns={columns3}
                                        data={paidSongsList}
                                        bordered={false}
                                        striped={false}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        pagination={paginationFactory(options)}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {paidSongsList.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>

                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {mediaList == "motivationalVideo" && (
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={media}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Motivational Video
                        </h3>
                        <Row>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-start"
                          >
                            <div className="pt-4 ps-2">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-end"
                          >
                            {/* <div className="pt-4">
                              <select
                                value={mediaList}
                                onChange={(e) => {
                                  setMediaList(e.target.value);
                                }}
                                className="shadow-box bg-white form-select border-0  ps-2 py-3 w-50 rounded-3"
                              >
                                <option value="motivationalVideo">
                                  Motivational Video
                                </option>
                                <option value="freeSongs">Free Songs</option>
                                <option value="paidSongs">Paid Songs</option>
                              </select>
                            </div> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Motivational Video
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={function () {}}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    columns={columns}
                                    data={media}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {media.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>
                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}

          {mediaList == "freeSongs" && (
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns2}
                  data={freeSongsList}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Motivational Video
                        </h3>
                        <Row>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-start"
                          >
                            <div className="pt-4 ps-2">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-end"
                          >
                            {/* <div className="pt-4">
                              <select
                                value={mediaList}
                                onChange={(e) => {
                                  setMediaList(e.target.value);
                                }}
                                className="shadow-box bg-white form-select border-0  ps-2 py-3 w-50 rounded-3"
                              >
                                <option value="motivationalVideo">
                                  Motivational Video
                                </option>
                                <option value="freeSongs">Free Songs</option>
                                <option value="paidSongs">Paid Songs</option>
                              </select>
                            </div> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Motivational Video - Free Song Track{" "}
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={function () {}}
                                    keyField="id"
                                    responsive
                                    columns={columns2}
                                    data={freeSongsList}
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {freeSongsList.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>
                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}

          {mediaList == "paidSongs" && (
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns3}
                  data={paidSongsList}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Motivational Video
                        </h3>
                        <Row>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-start"
                          >
                            <div className="pt-4 ps-2">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-end"
                          >
                            {/* <div className="pt-4">
                              <select
                                value={mediaList}
                                onChange={(e) => {
                                  setMediaList(e.target.value);
                                }}
                                className="shadow-box bg-white form-select border-0  ps-2 py-3 w-50 rounded-3"
                              >
                                <option value="motivationalVideo">
                                  Motivational Video
                                </option>
                                <option value="freeSongs">Free Songs</option> }
                                <option value="paidSongs">Paid Songs</option>
                              </select>
                            </div> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Motivational Video - Paid Song Track{" "}
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={function () {}}
                                    keyField="id"
                                    responsive
                                    columns={columns3}
                                    data={paidSongsList}
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    pagination={paginationFactory(options)}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {paidSongsList.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>

                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default MediaList;

import React from "react";
import { baseUrl } from "../../Api";

const PlayBothVideo = ({ playVideoData }) => {
  return (
    <div>
      <div
        className="modal fade"
        id="reportVideo"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Reported Challenge Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <video
                    id="video2"
                    width="230"
                    height="240"
                    controls
                    controlsList="nodownload"
                    
                  >
                    <source
                      media="(max-height: 400px)"
                      src={`${baseUrl}uploads/${playVideoData?.ochallenges?.challen?.videoId}`}
                      type="video/mp4"
                    />
                  </video>
                  {!playVideoData?.ochallenges?.challen?.name ? (
                    <></>
                  ) : (
                    <p>
                      {" "}
                      <strong> Challenge Name: </strong>{" "}
                      {playVideoData?.ochallenges?.challen?.name}{" "}
                    </p>
                  )}
                  {!playVideoData?.ochallenges?.challen?.description ? (
                    <></>
                  ) : (
                    <p>
                      {" "}
                      <strong> Challenge Description: </strong>{" "}
                      {playVideoData?.ochallenges?.challen?.description}{" "}
                    </p>
                  )}
                </div>
                <div className="col-sm-6 col-12">
                  <video
                    id="video3"
                    width="230"
                    height="240"
                    controls
                    controlsList="nodownload"
                  >
                    <source
                      media="(max-height: 400px)"
                      src={`${baseUrl}uploads/${playVideoData?.ochallenges?.videoId}`}
                      type="video/mp4"
                    />
                  </video>
                  {!playVideoData?.ochallenges?.name ? (
                    <></>
                  ) : (
                    <p>
                      {" "}
                      <strong> Challenge Name: </strong>{" "}
                      {playVideoData?.ochallenges?.name}{" "}
                    </p>
                  )}
                  {!playVideoData?.ochallenges?.description ? (
                    <></>
                  ) : (
                    <p>
                      {" "}
                      <strong> Challenge Description: </strong>{" "}
                      {playVideoData?.ochallenges?.description}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayBothVideo;

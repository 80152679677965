import React from "react";
import { Button, Col, Row } from "reactstrap";

import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { signUpAdmin } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const notify = () => toast.success("User has been Registered Successfully");
  const errorNotify = () => toast.error("User has already Registered");
  const onSubmit = async (values) => {
    delete values.confirmPass;
    values.fullname = values.fName + values.lName;
    values.roleId = "4";
    delete values.fName;
    delete values.lName;
    const data = await signUpAdmin(values);
    if (data.success == true) {
      {
        notify();
      }
    } else {
      {
        errorNotify();
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
          const regexPass =
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
          if (!values.fName) {
            errors.fName = "This value is required";
          }
          if (!values.phone) {
            errors.phone = "This value is required";
          } else if(values.phone.length < 10){
            errors.phone = " It should contain 10 digits";
          } else if (values.phone.length > 10) {
            errors.phone = " It should contain 10 digits";
          }
          if (!values.lName) {
            errors.lName = "This value is required";
          }
          if (!values.email) {
            errors.email = "This value is required";
          } else if (!regexEmail.test(values.email)) {
            errors.email = "Please Provide valid Email ID";
          }
          if (!values.password) {
            errors.password = "This value is required";
          } else if (!regexPass.test(values.password)) {
            errors.password = "Please enter unique values";
          }
          if (!values.confirmPass) {
            errors.confirmPass = "This value is required";
          } else if (values.confirmPass !== values.password) {
            errors.confirmPass = "Password should match";
          }
          if (!values.agree_terms) {
            errors.agree_terms = "Please accept Terms and Conditions to continue";
          } 
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col
                xxl={4}
                className="align-self-center offset-xxl-1 px-3 px-md-5 px-xxl-0"
              >
                <p className="text-info-blue fw-bold pt-md-5 text-center text-xxl-start heading-1 p-0 m-0">
                  Welcome
                </p>
                <p className="text-gray text-center text-xxl-start pb-3 fs-5 p-0">
                  Enter your Details to sign up
                </p>
                <div className="row">
                  <div className="col-sm-6 col-12 pe-0 pe-sm-4">
                    <Field name="fName">
                      {({ input, meta }) => (
                        <div className="row pt-3">
                          <label className="fw-bold fs-5">First Name</label>
                          <div className="pt-2">
                            <input
                              {...input}
                              type="text"
                              placeholder="Your first name"
                              className={
                                meta.error && meta.touched
                                  ? "border border-1 border-danger form-control py-3"
                                  : "form-control py-3"
                              }
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger pt-2">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="phone">
                      {({ input, meta }) => (
                        <div className="row pt-3">
                          <label className="fw-bold fs-5">Phone</label>
                          <div className="pt-2">
                            <input
                              {...input}
                              type="number" onWheel={(e) => e.target.blur()}
                              placeholder="Your Phone"
                              className={
                                meta.error && meta.touched
                                  ? "border border-1 border-danger form-control py-3"
                                  : "form-control py-3"
                              }
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger pt-2">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="password">
                      {({ input, meta }) => (
                        <div className="row pt-3">
                          <label className="fw-bold fs-5">Password</label>
                          <div className="pt-2">
                            <input
                              {...input}
                              type="password"
                              autoComplete="true"
                              placeholder="Enter Your password"
                              className={
                                meta.error && meta.touched
                                  ? "border border-1 border-danger form-control py-3"
                                  : "form-control py-3"
                              }
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger pt-2">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-sm-6 col-12 pe-0 ps-sm-4">
                    <Field name="lName">
                      {({ input, meta }) => (
                        <div className="row pt-3">
                          <label className="fw-bold fs-5">Last Name</label>
                          <div className="pt-2">
                            <input
                              {...input}
                              type="text"
                              placeholder="Your first name"
                              className={
                                meta.error && meta.touched
                                  ? "border border-1 border-danger form-control py-3"
                                  : "form-control py-3"
                              }
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger pt-2">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="email">
                      {({ input, meta }) => (
                        <div className="row pt-3">
                          <label className="fw-bold fs-5">Email</label>
                          <div className="pt-2">
                            <input
                              {...input}
                              type="email"
                              placeholder="Your email"
                              className={
                                meta.error && meta.touched
                                  ? "border border-1 border-danger form-control py-3"
                                  : "form-control py-3"
                              }
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger pt-2">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="confirmPass">
                      {({ input, meta }) => (
                        <div className="row pt-3">
                          <label className="fw-bold fs-5">
                            Confirm Password
                          </label>
                          <div className="pt-2">
                            <input
                              {...input}
                              type="password"
                              placeholder="Your first name"
                              className={
                                meta.error && meta.touched
                                  ? "border border-1 border-danger form-control py-3"
                                  : "form-control py-3"
                              }
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger pt-2">
                                {meta.error}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <Field name="agree_terms">
                  {({ input, meta }) => (
                    <div className="form-check pt-2">
                      <input
                        {...input}
                        className="form-check-input fs-5"
                        type="checkbox"
                        value=""
                        id=""
                      />
                      <label
                        className="form-check-label text-gray pt-1"
                        htmlFor="flexCheckDefault"
                      >
                       <a href="##"> I agree to the{" "}
                        <span className="text-info-blue">
                          {" "}
                          Terms,Privacy Policy.
                        </span>
                        </a>
                      </label>
                      {meta.error && meta.touched && (
                        <div className="text-danger pt-2">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Button
                  type="submit"
                  className="btn-blue w-100 mt-2 mt-xl-5 py-3 fw-bold fs-5 border-0 mx-auto d-block"
                >
                  SIGN UP
                </Button>
                <p className="text-center pt-4 ">
                  <Link to="/login" className="text-decoration-none">
                    <span className="text-gray">
                      You already have an account?{" "}
                    </span>
                    <span className="text-info-blue fw-bold"> Log In</span>
                  </Link>
                </p>
              </Col>
              <Col
                xxl={6}
                className="offset-xxl-1 text-center text-xxl-start pt-4"
              >
                <img
                  src="assets/signup.svg"
                  className="img-fluid"
                  alt="login"
                />
              </Col>
            </Row>
          </form>
        )}
      />
    </>
  );
};

export default Signup;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faColumns,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import img1 from '../../../public/assets/dashboard.svg'
import SubMenu from "./SubMenu";
import { useEffect } from "react";
import { getAllModule, getModuleById } from "../../Api";

const SideBar = ({ isOpen, toggle, activeIndexs, setActiveIndexs }) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [menu, setMenu] = useState([]);
  const [moduleById, setModuleById] = useState([]);

  useEffect(() => {
    (async () => {
      if (JSON.parse(localStorage.getItem("authUser")).roleId == 5) {

        const data = await getModuleById({
          id: JSON.parse(localStorage.getItem("authUser")).id,
        });
        setModuleById(data.data.modules);
      }
      const data = await getAllModule();

      if (data.success == true) {
        setMenu(data.data);
        navigate("/Dashboard")
      } else {
        localStorage.removeItem("authUser");
        navigate("/login");
      }

      if (Array.isArray(data.data)) {
        data.data.forEach((element) => {
          if (element.path == location.pathname.split("/")[1]) {
            setActiveIndexs([element.id]);
          } else if (
            Array.isArray(element.module) &&
            element.module.length > 0
          ) {
            element.module.forEach((y) => {
              if (y.path == location.pathname.split("/")[1]) {
                setActiveIndexs([element.id, y.id]);
              }
            });
          }
        });
      }
    })();
  }, []);
  return (
    <div
      className={classNames("sidebar", "shadow px-2", { "is-open": isOpen })}
    >
      <div className="sidebar-header">
        <span onClick={toggle}>&times;</span>
        <h3 className="text-black fw-bold text-center py-4">Get Hype</h3>
        <p className="text-secondary fw-bold">MENU</p>
      </div>
      <div className="side-menu">
        {JSON.parse(localStorage.getItem("authUser")).roleId == 4 ? (
          <>
            <Nav vertical className="list-unstyled pb-3" id='left_side_menu'>
              {menu?.map((x, index) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.module.length > 0 ? (
                      <SubMenu
                        onClick={() => setActiveIndexs([0, x.id])}
                        key={x.id}
                        id={x.id}
                        title={x.name}
                        icon={x.path}
                        aicon={x.path}
                        items={x.module}
                        activeIndexs={activeIndexs}
                        setActiveIndexs={setActiveIndexs}
                      />
                    ) : (
                      <NavItem
                        key={x.id}
                        onClick={() => setActiveIndexs([0, x.id])}
                      >
                        <NavLink
                          className={
                            location.pathname.includes(x.path)
                              ? "active-item"
                              : ""
                          }
                          tag={Link}
                          to={"/" + x.path}
                        >
                          <img
                            className="me-3"
                            src={
                              location.pathname.includes(x.path)
                                ? `assets/${x.path}-hover.svg`
                                : `assets/${x.path}.svg`
                            }
                          />
                          {x.name}
                        </NavLink>
                      </NavItem>
                    )}
                  </React.Fragment>
                );
              })}
            </Nav>
          </>
        ) : (
          <>
            <Nav vertical className="list-unstyled pb-3">
              {menu
                ?.filter((y) => moduleById[y.id])
                .map((x, index) => {
                  return (
                    <React.Fragment key={x.id}>
                      {x.module.length > 0 ? (
                        <SubMenu
                          onClick={() => setActiveIndexs([0, x.id])}
                          moduleById={moduleById}
                          key={x.id}
                          id={x.id}
                          title={x.name}
                          icon={x.path}
                          aicon={x.path}
                          items={x.module}
                          activeIndexs={activeIndexs}
                          setActiveIndexs={setActiveIndexs}
                        />
                      ) : (
                        <NavItem
                          key={x.id}
                          onClick={() => setActiveIndexs([0, x.id])}
                        >
                          <NavLink
                            className={
                              location.pathname.includes(x.path)
                                ? "active-item"
                                : ""
                            }
                            tag={Link}
                            to={"/" + x.path}
                          >
                            <img
                              className="me-3"
                              src={
                                location.pathname.includes(x.path)
                                  ? `assets/${x.path}-hover.svg`
                                  : `assets/${x.path}.svg`
                              }
                            />
                            {x.name}
                          </NavLink>
                        </NavItem>
                      )}
                    </React.Fragment>
                  );
                })}
            </Nav>
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;

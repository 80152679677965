import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import {
  getPaidUserPremium,
  baseUrl,
  getGoogleAds,
  getPremiumUser,
  getAllReport,
  editChallenges,
} from "../../Api";
import moment from "moment/moment";
import { configOptions } from "final-form";
import PlayVideoData from "../../components/common/PlayVideoData";
import PlayBothVideo from "../../components/common/PlayBothVideo";

// Table data

const ChallengedReport = (props) => {

  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [allReports, setAllReports] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [playVideoData, setPlayVideoData] = useState(null);

  var myModalEl = document.getElementById("reportVideo");

  myModalEl?.addEventListener("hidden.bs.modal", function (event) {
    playVideo2("close");
  });
  var myModalE2 = document.getElementById("reportVideoOne1");
  myModalE2?.addEventListener("hidden.bs.modal", function (event) {
    playVideo1("close");
  });

  useEffect(() => {}, [playVideoData]);

  useEffect(() => {
    allChallenge();
  }, [page, sizePerPage, totalSize]);

  const playVideo2 = (status = "open") => {
    const video2 = document.getElementById("video2");
    const video3 = document.getElementById("video3");
    if (status == "open" && video2 && video3) {
      video2.load();
      video3.load();
      video2.play();
      video3.play();
    } else {
      video2.children[0].removeAttribute("src");
      video3.children[0].removeAttribute("src");
      video2.load();
      video3.load();
    }
  };

  const playVideo1 = (status = "open") => {
    const video1 = document.getElementById("video1");
    if (status == "open" && video1) {
      video1.load();
      video1.play();
    } else {
      video1.children[0].removeAttribute("src");
      video1.load();
    }
  };

  const allChallenge = async () => {
    const data = await getAllReport({
      page: page,
      limit: sizePerPage,
    });
    if (data && data.data) {
      setAllReports(data.data);
      setTotalSize(data.data.length);
    }
  };

  const columns = [
    {
      dataField: "index",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "challenges",
      text: "Challenge",
      classes: "items-center",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          width: "300px",
        };
      },
      formatter: (cell, row, ind) => {
        let thumbNailImage = row?.ochallenges?.challen;
        if (!thumbNailImage) {
          return (
            <div className="d-flex flex-row">
              <div className="position-relative">
                <img
                  src={`${baseUrl}uploads/${row?.ochallenges?.thumbnailId}`}
                  alt="avatar"
                  className="widthVideoImage"
                />
                <img
                  src="assets/videoImage.svg"
                  alt="videoImage"
                  className="videoImage"
                  data-bs-toggle="modal"
                  data-bs-target="#reportVideoOne1"
                  onClick={() => {
                    setPlayVideoData(row);
                    setTimeout(() => {
                      playVideo1();
                    }, 1000);
                  }}
                />
              </div>
              <div className="ps-2 align-self-center">
                <span className="fw-bold"> {row?.ochallenges?.name} </span>
              </div>
            </div>
          );
        } else {
          return (
            <div className="d-flex flex-row">
              <div className="position-relative d-flex">
                <img
                  src={`${baseUrl}uploads/${row?.ochallenges?.challen?.thumbnailId}`}
                  alt="avatar"
                  className="widthVideoImage1"
                />
                <img
                  src={`${baseUrl}uploads/${row?.ochallenges?.thumbnailId}`}
                  alt="avatar"
                  className="widthVideoImage2"
                />

                <img
                  src="assets/videoImage.svg"
                  alt="videoImage"
                  className="videoImage"
                  data-bs-toggle="modal"
                  data-bs-target="#reportVideo"
                  onClick={() => {
                    setPlayVideoData(row);
                    setTimeout(() => {
                      playVideo2();
                    }, 1000);
                  }}
                />
              </div>

              <div className="ps-2 align-self-center">
                <span className="fw-bold">
                  {" "}
                  {!row?.ochallenges?.name
                    ? row?.ochallenges?.challen.name
                    : row?.ochallenges?.name}{" "}
                </span>
              </div>
            </div>
          );
        }
      },
    },
    {
      dataField: "count",
      text: "Report Count",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          width: "300px",
        };
      },
    },
    {
      dataField: "challengeId",
      text: "Challenge Id",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          width: "500px",
        };
      },
    },
    JSON.parse(localStorage.getItem("authUser")).roleId == 5
      ? temp && temp.D == true
        ? {
            dataField: "action",
            headerAlign: (column, colIndex) => "right",
            text: "Action",
            align: (cell, row, rowIndex, colIndex) => {
              return "right";
            },
            formatter: (cell, row) => {
              return (
                <>
                  <div className="btn-group">
                    {row?.ochallenges?.isControversial == true ? (
                      <button
                        onClick={() => disableControversial(row?.challengeId)}
                        className="text-white fw-600 bg-success py-2 border-0 px-2 rounded-1"
                      >
                        Make As a Not-Restricted
                      </button>
                    ) : (
                      <></>
                    )}
                    <div>
                      <button
                        className="border-0 bh-light py-2 px-2 rounded ms-3"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          setDeleteIndex({
                            isChallenge: true,
                            index: row?.challengeId,
                            name: !row?.ochallenges?.name  ? row?.ochallenges?.challen.name : row?.ochallenges?.name
                          })
                        }
                      >
                        <img src="assets/delete.svg" alt="delete" />
                      </button>
                    </div>
                    <Link
                      to="/detail-report-challenge"
                      state={{ reportChallenge: row }}
                    >
                      <button className="border-0 ms-3 bg-light me-2 py-2 px-2 rounded">
                        <img src="assets/view.svg" alt="view" />
                      </button>
                    </Link>
                  </div>
                </>
              );
            },
          }
        : {
            dataField: "action",
            headerAlign: (column, colIndex) => "right",
            text: "Action",
            align: (cell, row, rowIndex, colIndex) => {
              return "right";
            },
            formatter: (cell, row) => {
              return (
                <>
                  <div className="btn-group">
                    {row?.ochallenges?.isControversial == true ? (
                      <button
                        onClick={() => disableControversial(row?.challengeId)}
                        className="text-white fw-600 bg-success py-2 border-0 px-2 rounded-1"
                      >
                        Make As a Not-Restricted
                      </button>
                    ) : (
                      <></>
                    )}
                    <Link
                      to="/detail-report-challenge"
                      state={{ reportChallenge: row }}
                    >
                      <button className="border-0 ms-3 bg-light me-2 py-2 px-2 rounded">
                        <img src="assets/view.svg" alt="view" />
                      </button>
                    </Link>
                  </div>
                </>
              );
            },
          }
      : {
          dataField: "action",
          headerAlign: (column, colIndex) => "right",
          text: "Action",
          align: (cell, row, rowIndex, colIndex) => {
            return "right";
          },
          formatter: (cell, row) => {
            return (
              <>
                <div className="btn-group">
                  {row?.ochallenges?.isControversial == true ? (
                    <button
                      onClick={() => disableControversial(row?.challengeId)}
                      className="text-white fw-600 bg-success py-2 border-0 px-2 rounded-1"
                    >
                      Make As a Not-Restricted
                    </button>
                  ) : (
                    <></>
                  )}
                  <div>
                    <button
                      className="border-0 bh-light py-2 px-2 rounded ms-3"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() =>
                        setDeleteIndex({
                          isChallenge: true,
                          index: row?.challengeId,
                          name: !row?.ochallenges?.name  ? row?.ochallenges?.challen.name : row?.ochallenges?.name
                        })
                      }
                    >
                      <img src="assets/delete.svg" alt="delete" />
                    </button>
                  </div>
                  <Link
                    to="/detail-report-challenge"
                    state={{ reportChallenge: row }}
                  >
                    <button className="border-0 ms-3 bg-light me-2 py-2 px-2 rounded">
                      <img src="assets/view.svg" alt="view" />
                    </button>
                  </Link>
                </div>
              </>
            );
          },
        },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const disableControversial = async (id) => {
    let temp = {
      isControversial: false,
    };
    const data = await editChallenges({ id: id, data: temp });
    if (data.success == true) {
      allChallenge();
    }
  };

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    page: page,
    totalSize: totalSize,
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              <PlayVideoData playVideoData={playVideoData} />
              <PlayBothVideo playVideoData={playVideoData} />
              <DeleteModal
                deleteIndex={deleteIndex}
                allChallenge={allChallenge}
              />

              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[...columns]}
                      data={[...allReports]}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Reported Challenges
                            </h3>
                            <Row>
                              <Col xxl="5" sm="12" className="text-start">
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  {" "}
                                  Reported Challenges
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={() => false}
                                        headerClasses="align-self-center"
                                        rowClasses=""
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        bootstrap4={true}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...props.baseProps}
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        pagination={paginationFactory(options)}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {allReports.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>
                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <PlayVideoData playVideoData={playVideoData} />
          <PlayBothVideo playVideoData={playVideoData} />
          <DeleteModal deleteIndex={deleteIndex} allChallenge={allChallenge} />

          {
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={[...columns]}
                  data={[...allReports]}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Reported Challenges
                        </h3>
                        <Row>
                          <Col xxl="5" sm="12" className="text-start">
                            <div className="pt-4">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              {" "}
                              Reported Challenges
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={() => false}
                                    headerClasses="align-self-center"
                                    rowClasses=""
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    bootstrap4={true}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...props.baseProps}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    pagination={paginationFactory(options)}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {allReports.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>
                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default ChallengedReport;

import React from 'react'
import {Col, Row } from "reactstrap";

const Breadcrumbs = (props) => {
  return (
    <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="d-sm-flex align-items-center justify-content-between">
              <h4 className='fw-bold text-uppercase ps-3 py-4'>{props.name}</h4>
            </div>
          </Col>
        </Row>
      </React.Fragment>
  )
}

export default Breadcrumbs
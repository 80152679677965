import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import {
  getPaidUserPremium,
  baseUrl,
  getGoogleAds,
  getPremiumUser,
} from "../../Api";
import moment from "moment/moment";

// Table data

const PaidUserPremium = (props) => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [paidUserList, setPaidUserList] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    (async () => {
      const data = await getPremiumUser();
      if (data && data.data) {
        setPaidUserList(data.data);
        setTotalSize(data.data.length);
      }

    })();
  }, []);

  const columns = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "userId",
      text: "User Id",
    },
    {
      dataField: "paidUser",
      text: "Name",
      classes: "items-center",
      formatter: (cell, row) => (

        <div className="d-flex flex-row">
          <div>
            {!row?.user?.profileImagePath ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.user?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2 align-self-center">
            <span className="fw-bold"> {row?.user?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "platform",
      text: "Platform",
      formatter: (cell, row) => {
        return cell == "Apple" ? "iOS" : "Android"
      },
    },
    {
      dataField: "productId",
      text: "Plan Name",
      formatter: (cell, row) => {
        let plan = cell?.includes("monthly") ? "monthly" : "yearly";
        return plan;
      },
    },
    {
      dataField: "expiryDate",
      text: "Expiry Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "expiryDate",
      text: "Plan Status",
      formatter: (cell, row) => {
 
        return cell && new Date(moment(cell).format("YYYY-MM-DD")) > new Date() ? (
          <button className="btn-success-green border-0 py-2 px-3 rounded">
            Active
          </button>
        ) : (
          <button className="btn-danger-red border-0 py-2 px-3 rounded">
            Inactive
          </button>
        );
      },
    },
  ];
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    page: page,
    totalSize: totalSize,
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {/* <DeleteModal deleteIndex={deleteIndex} allUser={allUser}/> */}
      {
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={[...columns]}
              data={[...paidUserList]}
              search
            >
              {(toolkitprops) => (
                <React.Fragment>
                  <div className="container-fluid page-content bg-light-blue px-lg-3">
                    <h3 className="text-black fw-bold text-uppercase pt-3">
                      Paid User - Premium
                    </h3>
                    <Row>
                      <Col xxl="5" sm="12" className="text-start">
                        <div className="pt-4">
                          <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                            Show
                          </p>
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              className=""
                              {...paginationProps}
                            />
                          </div>

                          <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                            Entries
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 rounded-3 bg-white px-4">
                        <h4 className="py-4 text-blue fw-600">Paid User</h4>
                        <Row>
                          <div className="table-responsive">
                            <div>
                              <BootstrapTable
                                remote
                                onTableChange={() => false}
                                headerClasses="align-self-center"
                                rowClasses=""
                                keyField="id"
                                responsive
                                bordered={false}
                                bootstrap4={true}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={
                                  "thead-light text-grey-light"
                                }
                                {...props.baseProps}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                      <h6 className="text-blue fw-600 ps-2">
                        {paidUserList.length == 0
                          ? "Showing 0"
                          : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${totalSize < sizePerPage
                            ? totalSize
                            : sizePerPage * page >= totalSize
                              ? totalSize
                              : sizePerPage * page
                          }
                            of ${totalSize}`}
                      </h6>
                      <div className="ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      }
    </React.Fragment>
  );
};

export default PaidUserPremium;

import React from "react";
import { Table, Input, Label } from "reactstrap";

const Editpermission = (props) => {
  if (props.getPermission && Object.keys(props.getPermission).length > 0) {
    let tempVar = props.getPermission ? Object.keys(props.getPermission) : "";

    let tempVar2 = props.getPermission
      ? tempVar.map((x) => {
          return (props.permission[`"${x}"`] = props.getPermission[x]);
        })
      : [];
  }

  return (
    <>
      <tr className="align-middle">
        <th
          scope="row"
          className={`${props.module.moduleId != null ? "ps-4" : ""}`}
        >
          {`${props.module.moduleId != null ? "- " + props.name : props.name}`}
        </th>
        <td>
          <div className="d-block d-lg-inline text-center text-lg-start">
            <Input
              id={`A${props.module.id}`}
              name={`A${props.module.id}`}
              type="checkbox"
              className="fs-4"
              value={props.permission[`"${props.module.id}"`] ?  props.permission[`"${props.module.id}"`].A : false }
              onChange={(e) => {
                props.setP(
                  props.permission,
                  "A",
                  props.module.id,
                  props.module.moduleId
                );
              }}
              checked={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].A : false}
            />
          </div>
          <Label
            className="ps-3 pt-2 d-block d-lg-inline"
            for={`A${props.module.id}`}
          >
            All Check
          </Label>
        </td>

        <td>
          <div className="d-block d-lg-inline text-center text-lg-start">
            <Input
              id={`C${props.module.id}`}
              name={`C${props.module.id}`}
              type="checkbox"
              value={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].C : false}
              className="fs-4"
              onChange={(e) => {
                props.setP(
                  props.permission,
                  "C",
                  props.module.id,
                  props.module.moduleId
                );
              }}
              checked={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].C : false}
            />
          </div>
          <Label
            className="ps-3 pt-2 d-block d-lg-inline"
            check
            for={`C${props.module.id}`}
          >
            Create
          </Label>
        </td>
        <td>
          <div className="d-block d-lg-inline text-center text-lg-start">
            <Input
              id={`R${props.module.id}`}
              name={`R${props.module.id}`}
              type="checkbox"
              className="fs-4"
              value={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].R : false}
              onChange={(e) => {
                props.setP(
                  props.permission,
                  "R",
                  props.module.id,
                  props.module.moduleId
                );
              }}
              checked={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].R : false}
            />
          </div>
          <Label
            className="ps-3 pt-2 d-block d-lg-inline"
            check
            for={`R${props.module.id}`}
          >
            Read
          </Label>
        </td>
        <td>
          <div className="d-block d-lg-inline text-center text-lg-start">
            <Input
              id={`U${props.module.id}`}
              name={`U${props.module.id}`}
              type="checkbox"
              value={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].U : false}
              className="fs-4"
              onChange={(e) => {
                props.setP(
                  props.permission,
                  "U",
                  props.module.id,
                  props.module.moduleId
                );
              }}
              checked={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].U : false}
            />
          </div>
          <Label
            className="ps-3 pt-2 d-block d-lg-inline"
            check
            for={`U${props.module.id}`}
          >
            Edit
          </Label>
        </td>
        <td>
          <div className="d-block d-lg-inline text-center text-lg-start">
            <Input
              id={`D${props.module.id}`}
              name={`D${props.module.id}`}
              type="checkbox"
              value={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].D : false}
              className="fs-4"
              onChange={(e) => {
                props.setP(
                  props.permission,
                  "D",
                  props.module.id,
                  props.module.moduleId
                );
              }}
              checked={props.permission[`"${props.module.id}"`] ? props.permission[`"${props.module.id}"`].D : false}
            />
          </div>
          <Label
            className="ps-3 pt-2 d-block d-lg-inline"
            check
            for={`D${props.module.id}`}
          >
            Delete
          </Label>
        </td>
      </tr>
      {props.module.module &&
        props.module.module.map((x) => {
          return (
            <Editpermission
              key={x.id}
              allModule={props.allModule}
              setP={props.setP}
              permission={props.permission}
              name={x.name}
              module={x}
            />
          );
        })}
    </>
  );
};

export default Editpermission;

import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { loginUserAPI } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    values.signupvia = 1;
    const data = await loginUserAPI(values);
    if (data.success == true) {
      localStorage.setItem("authUser", JSON.stringify(data.data));
      {
        const notify = () => toast.success("Login Successful");
        notify();
      }
      if (JSON.parse(localStorage.getItem("authUser")).roleId == 4) {
        setTimeout(() => {
          navigate("/Dashboard");
          window.location.reload();
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/user");
          window.location.reload();
        }, 2000);
      }
    }
    if (data.response.data.success == false) {
      {
        const errorNotify = () => toast.error(data.response.data.message);
        errorNotify();
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
          const regexPass =
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
          if (!values.email) {
            errors.email = "This value is required";
          } else if (!regexEmail.test(values.email)) {
            errors.email = "Please Provide valid Email ID";
          }
          if (!values.password) {
            errors.password = "This value is required";
          } else if (!regexPass.test(values.password)) {
            errors.password = "Please enter unique values";
          }
          if (!values.agree_terms) {
            errors.agree_terms =
              "Please accept Terms and Conditions to continue";
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={(event) => handleSubmit(event.preventDefault())}>
            <Row>
              <Col
                lg={4}
                className="align-self-center offset-lg-1 px-3 px-md-5 px-lg-0"
              >
                <p className="text-info-blue fw-bold pt-md-5 text-center text-lg-start heading-1 p-0 m-0">
                  Welcome Back
                </p>
                <p className="text-gray text-center text-lg-start pb-3 fs-5 p-0">
                  Enter your email and password to Log In
                </p>
                <Field name="email">
                  {({ input, meta }) => (
                    <div className="row">
                      <label className="fw-bold fs-5">Email</label>
                      <div className="pt-2">
                        <input
                          {...input}
                          type="email"
                          placeholder="Your email address"
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger form-control py-3"
                              : "form-control py-3"
                          }
                        />
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="password">
                  {({ input, meta }) => (
                    <div className="row pt-4">
                      <label className="fw-bold fs-5">Password</label>
                      <div className="pt-2">
                        <input
                          {...input}
                          type="password"
                          autoComplete="true"
                          placeholder="Enter Your password"
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger form-control py-3"
                              : "form-control py-3"
                          }
                        />
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <div className="text-start text-lg-end">
                  <div className="pt-2 pt-md-3">
                    <Link to="/forgotPass" className="text-decoration-none">
                      <span className="text-info-blue fw-bold">
                        Forgot Password?
                      </span>
                    </Link>
                  </div>
                </div>
                <Field name="agree_terms">
                  {({ input, meta }) => (
                    <div className="form-check pt-3">
                      <input
                        {...input}
                        className="form-check-input fs-5"
                        type="checkbox"
                        value=""
                        id="iAgree"
                      />
                      <label
                        className="form-check-label text-gray pt-1 ps-2"
                        htmlFor="flexCheckDefault"
                      >
                        <a href="javascript:void(0)">
                          {" "}
                          <span className="text-blue">
                            {" "}
                            <label htmlFor="iAgree"> I agree to the </label>
                          </span>
                          <a target="_blank" href="/#/terms-conditions">
                            {" "}
                            <span className="text-info-blue"> Terms,</span>{" "}
                          </a>{" "}
                          <a target="_blank" href="/#/privacy-policy">
                            {" "}
                            <span className="text-info-blue">
                              {" "}
                              Privacy Policy.{" "}
                            </span>{" "}
                          </a>{" "}
                        </a>
                      </label>
                      {meta.error && meta.touched && (
                        <div className="text-danger pt-2">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Button
                  type="submit"
                  className="btn-blue w-100 mt-2 mt-xl-5 py-3 fw-bold fs-5 border-0 mx-auto d-block"
                >
                  LOG IN
                </Button>
                {/* <p className="text-center pt-4 "><Link to="/signup" className="text-decoration-none">
                  <span className="text-gray">Don't have an account?</span>
                  <span className="text-info-blue fw-bold"> Sign up</span>
              </Link>
              </p> */}
              </Col>
              <Col
                lg={6}
                className="offset-lg-1 text-center text-lg-start pt-4"
              >
                <img
                  src="assets/login.png"
                  className="login-image"
                  alt="login"
                />
              </Col>
            </Row>
          </form>
        )}
      />
    </>
  );
};

export default Login;

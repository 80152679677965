import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import { getUserTrack, baseUrl } from "../../Api";
import moment from "moment";

const UserTrack = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [userTrackList, setUserTrackList] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    allUserTrack();
  }, [page, sizePerPage]);

  const allUserTrack = async () => {
    const data = await getUserTrack({ page: page, limit: sizePerPage });
    setUserTrackList(data.data);
    setTotalSize(data.count);
  };

  const columns = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "paidUser",
      text: "App User Name",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row">
          <div>
            {!row?.user?.profileImagePath ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.user?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2 align-self-center">
            <span className="fw-bold"> {row?.user?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "referenceUser",
      text: "Referred by User",
      formatter: (cell, row) => {
        return cell.fullname;
      },
    },
    {
      dataField: "createdAt",
      text: "Created date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "referralCode",
      text: "Referral Code",
    },
    // JSON.parse(localStorage.getItem("authUser")).roleId == 5
    //   ? temp && temp.D == true
    //     ? {
    //         dataField: "action",
    //         text: "Action",
    //         formatter: (cell, row) => {
    //           return (
    //             <div className="btn-group">
    //               <button
    //                 className="border-0  py-2 px-2 rounded"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#exampleModal"
    //                 onClick={() =>
    //                   setDeleteIndex({ isUserTrack: true, index: row?.id })
    //                 }
    //               >
    //                 <img src="assets/delete.svg" alt="delete" />
    //               </button>
    //             </div>
    //           );
    //         },
    //       }
    //     : {}
    //   : {
    //       dataField: "action",
    //       text: "Action",
    //       formatter: (cell, row) => {
    //         return (
    //           <div className="btn-group">
    //             <button
    //               className="border-0  py-2 px-2 rounded"
    //               data-bs-toggle="modal"
    //               data-bs-target="#exampleModal"
    //               onClick={() =>
    //                 setDeleteIndex({ isUserTrack: true, index: row?.id })
    //               }
    //             >
    //               <img src="assets/delete.svg" alt="delete" />
    //             </button>
    //           </div>
    //         );
    //       },
    //     },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    // onSizePerPageChange: (sizePerPage, page) => {
    //   setPage(page);
    //   setSizePerPage(sizePerPage);
    // },
    // sizePerPageList: [
    //   {
    //     text: "10",
    //     value: 10,
    //   },
    //   {
    //     text: "20",
    //     value: 20,
    //   },
    //   {
    //     text: "30",
    //     value: 30,
    //   },
    // ],

    page: page,
    totalSize: totalSize,
    custom: true,
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              <DeleteModal
                deleteIndex={deleteIndex}
                allUserTrack={allUserTrack}
              />
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={userTrackList}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Referred Track User 
                            </h3>
                            <Row>
                              <Col xxl="5" sm="12" className="text-start">
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue">
                                  Referred Track User 
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        onTableChange={function () {}}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                            <h6 className="text-blue fw-600 ps-2">
                           {userTrackList.length == 0 ? "Showing 0" : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${totalSize < sizePerPage
                              ? totalSize
                              : sizePerPage * page >= totalSize
                              ? totalSize
                              : sizePerPage * page}
                            of ${totalSize}`} 
                          </h6>

                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <DeleteModal deleteIndex={deleteIndex} allUserTrack={allUserTrack} />
          {
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={userTrackList}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Referred Track User 
                        </h3>
                        <Row>
                          <Col xxl="5" sm="12" className="text-start">
                            <div className="pt-4">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Referred Track User 
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    onTableChange={function () {}}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light text-grey-light"}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                           {userTrackList.length == 0 ? "Showing 0" : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${totalSize < sizePerPage
                              ? totalSize
                              : sizePerPage * page >= totalSize
                              ? totalSize
                              : sizePerPage * page}
                            of ${totalSize}`} 
                          </h6>

                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default UserTrack;

import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APIKEY,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("authUser"));
  if (user?.token) {
    config.headers.common["Authorization"] = `${user.token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  if (response.status === 401)
    return new Promise(async (resolve, reject) => {
      localStorage.removeItem("token");
      reject(null);
    });
  else if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202
  ) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
});

export const baseUrl = process.env.REACT_APP_APIKEY + "/api/";

// user login api
export const loginUserAPI = async (data) => {
  let result;
  try {
    result = await axios
      .post(process.env.REACT_APP_APIKEY + "/api/users/login", data)
      .then((x) => x.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// user Sing up Api
export const signUpAdmin = async (data) => {
  let result;
  try {
    result = await axios
      .post(process.env.REACT_APP_APIKEY + "/api/users", data)
      .then((x) => x.data);
  } catch (error) {
    result = error.response.data;
  }
  return result;
};

// add new merchandise
export const addMerchandise = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/merchandise", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// add user permission
export const addUserPermission = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/userPermissions", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// edit user permission
export const editUserPermission = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .put("/api/userPermissions", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// add new categories
export const addCategories = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/categories", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all categories
export const getAllCategories = async (id) => {
  let result;
  try {
    result = await axiosInstance
      .get(`/api/categories/getAll/${id}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get report by Id
export const getReportById = async ({ id, page, limit }) => {
  let result;
  let urlString = `/api/reports/${id}?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all Order by Id
export const getOrderById = async ({ id }) => {
  let result;
  try {
    result = await axiosInstance
      .get(`/api/order/getById/${id}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all users
export const getAllUser = async ({ page, limit, roleId, name }) => {
  let result;
  let urlString = `/api/users?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  if (roleId) {
    urlString += `&roleId=${roleId}`;
  }
  if (name) {
    urlString += `&name=${name}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all module
export const getAllModule = async () => {
  let result;
  let urlString = `/api/module/`;
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error.response;
  }
  return result;
};
// get all module
export const getDashboard = async () => {
  let result;
  let urlString = `/api/dashboard`;
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error.response;
  }
  return result;
};

// get all google ads
export const getGoogleAds = async (isAndroid) => {
  let result;
  let urlString = `/api/googleAds`;
  if (isAndroid) {
    urlString += `?isAndroid=${isAndroid}`;
  } else {
    urlString += `?isAndroid=${isAndroid}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all premium
export const getPremiumUser = async () => {
  let result;
  let urlString = `/api/premiumUser`;
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get module by id
export const getModuleById = async ({ id }) => {
  let result;
  let urlString = `/api/userPermissions/${id}`;
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error.response;
    return error;
  }
  return result;
};

// get module by id
export const getShippingRate = async () => {
  let result;
  let urlString = `/api/shipping`;
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error.response;
    return error;
  }
  return result;
};

// get merchandise refund
export const getMerchandiseRefund = async ({ page, limit, status }) => {
  let result;
  let urlString = `/api/refund?`;

  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  if (!(typeof status == "string")) {
    {
      urlString += `&status=${status}`;
    }
  } else if (status == "") {
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all order
export const getAllSalesOrder = async ({ page, limit }) => {
  let result;
  let urlString = `/api/order/getAll?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all report
export const getAllReport = async ({ page, limit }) => {
  let result;
  let urlString = `/api/reports?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// disable users
export const disableUser = async (values) => {
  let result;
  try {
    result = await axiosInstance
      .put("/api/users/", values)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get all users
export const getHighestUserGames = async ({ page, limit, gameName }) => {
  let result;
  let urlString = `/api/${gameName}/admin?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }

  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get motivational video
export const getMotivationalSongs = async ({ page, limit }) => {
  let result;
  let urlString = `/api/motivationalClips?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get free songs
export const getFreeSongs = async ({ page, limit }) => {
  let result;
  let urlString = `/api/playlist/?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get paid songs
export const getPaidSongs = async ({ page, limit }) => {
  let result;
  let urlString = `/api/audioTracks/getAll?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get All games
export const getAllGames = async ({ page, limit }) => {
  let result;
  let urlString = `/api/games/admin?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// delete All games
export const deleteGames = async (id) => {
  let result;
  try {
    result = await axiosInstance
      .delete(`/api/games/${id}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// delete user
export const deleteUser = async (id) => {
  let result;
  try {
    result = await axiosInstance
      .delete(`/api/users/delete/${id}?deletedByAdmin=true`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// delete Challenges
export const deleteChallenges = async (id) => {
  let result;
  try {
    result = await axiosInstance
      .delete(`/api/challenges?challengeId=${id}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get free songs
export const addFreeSongs = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/playlist", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// google ads create
export const addGoogleAds = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/googleAds", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// add shipping rate
export const addShippingRate = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/shipping", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get motivational songs
export const addMotivationalSongs = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/motivationalClips", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get motivational songs
export const updateMotivationalSongs = async ({id, data}) => {
  let result;
  try {
    result = await axiosInstance
      .put(`/api/motivationalClips/${id}`, data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};
// get motivational songs
export const deleteMotivationalSongs = async ({id}) => {
  let result;
  try {
    result = await axiosInstance
      .delete(`/api/motivationalClips/${id}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get paid songs
export const addPaidSongs = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/audioTracks", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get paid songs
export const addGames = async (data) => {
  let result;
  try {
    result = await axiosInstance
      .post("/api/games", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get refered user track
export const getUserTrack = async ({ page, limit }) => {
  let result;
  let urlString = `/api/users/getAllRefer?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// delete refered track
export const deleteReferedTrack = async (id) => {
  let result;
  try {
    result = await axiosInstance
      .delete(`/api/users/refer/${id}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get refered user track
export const getAllMerchandise = async ({ page, limit }) => {
  let result;
  let urlString = `/api/merchandise?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// get paid user premium
export const getPaidUserPremium = async ({ page, limit }) => {
  let result;
  let urlString = `/api/premiumUser?`;
  if (page && limit) {
    page = parseInt(page);
    page = page - 1;
    urlString += `page=${page}&limit=${limit}`;
  }
  try {
    result = await axiosInstance.get(urlString).then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// disable merchandise
export const disableMerchandise = async (values) => {
  let result;
  try {
    result = await axiosInstance
      .put("/api/merchandise/status", values)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// disable refund
export const disableRefund = async (values) => {
  let result;
  try {
    result = await axiosInstance
      .put("/api/refund", values)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// edit merchandise
export const editMerchandise = async (values) => {
  let result;

  try {
    result = await axiosInstance
      .put("/api/merchandise/status", values)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// edit challenges
export const editChallenges = async ({ id, data }) => {
  let result;

  try {
    result = await axiosInstance
      .put(`/api/challenges?id=${id}`, data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
  }
  return result;
};

// create OTP
export const createOtp = async ({ email }) => {
  let result;

  try {
    result = await axiosInstance
      .post(`/api/users/getotp/${email}`)
      .then((res) => res.data);
  } catch (error) {
    result = error;
    result = error;
  }
  return result;
};

// verify OTP
export const verifyOtp = async (data) => {
  let result;

  try {
    result = await axiosInstance
      .post("/api/users/verifyotp", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
    result = error;
  }
  return result;
};

// reset password
export const resetPassword = async (data) => {
  let result;

  try {
    result = await axiosInstance
      .post("/api/users/change/password", data)
      .then((res) => res.data);
  } catch (error) {
    result = error;
    result = error;
  }
  return result;
};

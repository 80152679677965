import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import { baseUrl, getAllSalesOrder, getMerchandiseRefund } from "../../Api";
import moment from "moment";

const MerchandiseRefund = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState("10");
  const [orderList, setOrderList] = useState([]);
  const [status, setStatus] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    allMerchandiseRefund();
  }, [page, sizePerPage, status]);

  const allMerchandiseRefund = async () => {
    const data = await getMerchandiseRefund({
      page: page,
      limit: sizePerPage,
      status: status == "true" ? 1 : status == "false" ? 0 : "",
    });
    if (data && data.data) {
      setOrderList(data.data);
    }
    setTotalSize(data.count);
  };

  const columns = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "userName",
      text: "User Name",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row align-items-center">
          <div>
            {!row?.user?.profileImagePath ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.user?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2">
            <span className="fw-bold"> {row?.user?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "createdAt",
      text: "Order Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "orderId",
      text: "Order Id",
    },
    {
      dataField: "stripeId",
      text: "Stripe ID",
      formatter: (cell, row) => {
        return row?.payment.stripeId;
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row) => {
        return `$ ${cell}`;
      },
    },
    {
      dataField: "paymentMethod",
      text: "Payment Type",
      formatter: (cell, row) => {
        return row?.payment.paymentMethod;
      },
    },
    {
      dataField: "isRefunded",
      text: "Status",
      formatter: (cell, row) => {
        if (cell == true) {
          return (
            <button className="btn-success-green border-0 py-2 px-3 rounded">
              Refunded
            </button>
          );
        } else {
          return (
            <button className="btn-danger-red border-0 py-2 px-3 rounded">
              Pending
            </button>
          );
        }
      },
    },
    JSON.parse(localStorage.getItem("authUser")).roleId == 5
      ? {
          dataField: "action",
          text: "Action",
          formatter: (cell, row) => {
            if (row?.isRefunded == true) {
              return temp && temp.R == true ? (
                <Link to="/sales-view-detail" state={{ orderRefund: row }}>
                  <button className="border-0 bg-light me-2 py-2 px-2 rounded">
                    <img src="assets/view.svg" alt="view" />
                  </button>
                </Link>
              ) : (
                <></>
              );
            } else {
              return temp && temp.D == true ? (
                <div className="btn-group">
                  <Link to="/sales-view-detail" state={{ orderRefund: row }}>
                    <button className="border-0 bg-light me-2 py-2 px-2 rounded">
                      <img src="assets/view.svg" alt="view" />
                    </button>
                  </Link>

                  <button
                    className="border-0 bg-transparent"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() =>
                      setDeleteIndex({
                        isRefund: true,
                        index: row?.id,
                        userName: row?.user?.fullname,
                        stripeId: row?.payment.stripeId,
                      })
                    }
                  >
                    <img src="assets/rightpopup.svg" alt="delete" />
                  </button>
                </div>
              ) : (
                <></>
              );
            }
          },
        }
      : {
          dataField: "action",
          text: "Action",
          formatter: (cell, row) => {
            if (row?.isRefunded == true) {
              return (
                <Link to="/sales-view-detail" state={{ orderRefund: row }}>
                  <button className="border-0 bg-light me-2 py-2 px-2 rounded">
                    <img src="assets/view.svg" alt="view" />
                  </button>
                </Link>
              );
            } else {
              return (
                <div className="btn-group">
                  <Link to="/sales-view-detail" state={{ orderRefund: row }}>
                    <button className="border-0 bg-light me-2 py-2 px-2 rounded">
                      <img src="assets/view.svg" alt="view" />
                    </button>
                  </Link>

                  <button
                    className="border-0 bg-transparent"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() =>
                      setDeleteIndex({
                        isRefund: true,
                        index: row?.id,
                        userName: row?.user?.fullname,
                        stripeId: row?.payment.stripeId,
                      })
                    }
                  >
                    <img src="assets/rightpopup.svg" alt="delete" />
                  </button>
                </div>
              );
            }
          },
        },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    page: page,
    totalSize: totalSize,
    custom: true,
  };

  return (
    <React.Fragment>
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              <DeleteModal
                deleteIndex={deleteIndex}
                allMerchandiseRefund={allMerchandiseRefund}
              />
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[...columns]}
                      data={orderList}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Merchandise Refund
                            </h3>
                            <Row>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-start"
                              >
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                lg={{ size: 3, offset: 3 }}
                                md="12"
                                className="text-center text-lg-end"
                              >
                                <div className="pt-4">
                                  <label className="pe-2" htmlFor="status">
                                    <h6>Pending Status</h6>
                                  </label>
                                  <select
                                    name="status"
                                    className="border-light-primary ps-2 py-3 border-0 bg-white  shadow-box form-select w-50 rounded-3"
                                    value={status}
                                    onChange={(e) => {
                                      setStatus(e.target.value);
                                    }}
                                  >
                                    <option value="">All</option>
                                    <option value="true">Refunded</option>
                                    <option value="false">Pending</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Merchandise Refund
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={() => false}
                                        headerClasses="align-self-center"
                                        rowClasses=""
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        bootstrap4={true}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {orderList.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>

                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <DeleteModal
            deleteIndex={deleteIndex}
            allMerchandiseRefund={allMerchandiseRefund}
          />
          {
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={[...columns]}
                  data={orderList}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Merchandise Refund
                        </h3>
                        <Row>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-start"
                          >
                            <div className="pt-4">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          <Col
                            lg={{ size: 3, offset: 3 }}
                            md="12"
                            className="text-center text-lg-end"
                          >
                            <div className="pt-4">
                              <label className="pe-2" htmlFor="status">
                                <h6>Pending Status</h6>
                              </label>
                              <select
                                name="status"
                                className="border-light-primary ps-2 py-3 border-0 bg-white shadow-box form-select w-50 rounded-3"
                                value={status}
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              >
                                <option value="">All</option>
                                <option value="true">Refunded</option>
                                <option value="false">Pending</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Merchandise Refund
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={() => false}
                                    headerClasses="align-self-center"
                                    rowClasses=""
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    bootstrap4={true}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {orderList.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>
                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default MerchandiseRefund;

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import { json, Link, useLocation, useNavigate } from "react-router-dom";

const SubMenu = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(
    !props.items.filter((x) => x.path == location.pathname.split("/")[1]).length
  );
  const toggle = () => setCollapsed(!collapsed);
  const { id, icon, aicon, title, items, img, activeIndexs, setActiveIndexs } =
    props;

  // const toggle = () => {
  //   let openMenuList = document.getElementById("left_side_menu").childNodes;
  //   for (const key in openMenuList) {
  //     if (openMenuList[key].tagName == "DIV" && id == openMenuList[key].id) {
  //       openMenuList[key].childNodes[0].classList.remove(
  //         "menu-open",
  //         "active-item"
  //       );
  //       openMenuList[key].childNodes[1].classList.remove("show");
  //     }
  //   }
  // };
  // const navitem = () => {

  //   let item = items.find(
  //     (x) => x.name.includes("list") || x.name.includes("List")
  //   );
  //   setActiveIndexs([id, item.id]);
  //   navigate(item.path);
  // };

  return items.length ? (
    <div id={id}>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open active-item": !collapsed })}
      >
        <NavLink
          className={`d-flex align-items-center justify-content-between dropdown-toggle ${
            activeIndexs.includes(id) ? "active-item" : ""
          }`}
        >
          <div>
            <img
              className="me-3"
              src={
                activeIndexs.includes(id)
                  ? `assets/${icon}-hover.svg`
                  : `assets/${icon}.svg`
              }
            />
            {title}
          </div>
          {!collapsed ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </NavLink>
      </NavItem>

      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {JSON.parse(localStorage.getItem("authUser")).roleId == 5
          ? items
              .filter((x) => props.moduleById[x.id])
              .map((item, index) => (
                <NavItem
                  key={item.id}
                  className="ps-5"
                  onClick={() => {
                    setActiveIndexs([id, item.id]);
                  }}
                >
                  <NavLink
                    className={
                      location.pathname.includes(item.path) ? "active-item" : ""
                    }
                    tag={Link}
                    to={item.path}
                  >
                    {`- ${item.name}`}
                  </NavLink>
                </NavItem>
              ))
          : items.map((item, index) => (
              <NavItem
                key={item.id}
                className="ps-5"
                onClick={() => {
                  setActiveIndexs([id, item.id]);
                }}
              >
                <NavLink
                  className={
                    location.pathname.includes(item.path) ? "active-item" : ""
                  }
                  tag={Link}
                  to={item.path}
                >
                  {`- ${item.name}`}
                </NavLink>
              </NavItem>
            ))}
      </Collapse>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default SubMenu;

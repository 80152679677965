import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../Api";

const Topbar = ({ toggleSidebar }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const navigate = useNavigate();
  const removeToken = () => {
    localStorage.removeItem("authUser");
    navigate("/login");
    window.location.reload(true);
  };

  return (
    <Navbar
      className="navbar shadow-sm p-3 bg-white text-center text-lg-start"
      expand="md"
    >
      <Button color="white" className="border-0" onClick={toggleSidebar}>
        <i className="fa-solid fa-bars fs-4"></i>
      </Button>
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar className="align-self-center">
        <Nav className="ms-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
              {localStorage.getItem("authUser") ? (
                <img
                  src={
                    JSON.parse(localStorage.getItem("authUser"))
                      .profileImagePath
                      ? JSON.parse(localStorage.getItem("authUser"))
                          .profileImagePath
                      : "assets/contact.svg"
                  }
                  className="width-25 rounded-circle"
                  alt="admin"
                />
              ) : (
                <img src="assets/admin.svg" className="img-fluid" alt="admin" />
              )}
              <span className="text-black fw-bold ps-4">
                {JSON.parse(localStorage.getItem("authUser")).fullname}
              </span>
              <i className="fa-solid fa-chevron-down ps-3"></i>
            </DropdownToggle>
            <DropdownMenu end>
              {localStorage.getItem("authUser") ? (
                ""
              ) : (
                <DropdownItem>Login</DropdownItem>
              )}

              <Link onClick={() => removeToken()}>
                {" "}
                <DropdownItem>Logout</DropdownItem>
              </Link>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Topbar;

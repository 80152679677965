import React from "react";
import { Row } from "reactstrap";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Form, Field } from "react-final-form";
import { addCategories } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCategories = () => {
  const notify = () => toast.success("Category has been added Successfully");
  const onSubmit = async (values) => {
    const data = await addCategories(values);
    if (data.success == true) {
      {
        notify();
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container-fluid page-content bg-light-blue">
        <Row>
          <Breadcrumbs name={"Add Category"} />
        </Row>
        <Row>
          <Form
            onSubmit={onSubmit}
            initialValues={{parentId:"", name:""}}
            validate={(values) => {
              const errors = {};
              if (!values.parentId) {
                errors.parentId = "This value is required";
              }
              if (!values.name) {
                errors.name = "This value is required";
              }
              return errors;
            }}
            render={({
              handleSubmit,
              submitting,
              pristine,
              values,
              errors,
              touched,
              form,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="bg-white px-4 pb-3 mb-5 rounded">
                  <h4 className="text-blue pt-3 fw-bold">Category Detail</h4>
                  <Field name="parentId">
                    {({ input, meta }) => (
                      <>
                        <div className="row">
                          <label htmlFor="parentId">
                            <h5 className="text-blue pt-3">Parent category</h5>
                          </label>
                          <div className="col-lg-4 col-12">
                            <select
                              {...input}
                              name="parentId"
                              className="w-100 form-select border-light-blue rounded py-3"
                            >
                              <option value="">Select</option>
                              <option value="1">Men</option>
                              <option value="2">Female</option>
                              <option value="3">child</option>
                            </select>
                          </div>
                          <p className="font-blue pt-2 mb-0">
                            Select Product Status
                          </p>
                        </div>
                        {meta.error && meta.touched && (
                          <div className="text-danger">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                  <Field name="name">
                    {({ input, meta }) => (
                      <>
                        <div className="mb-3 row">
                          <label htmlFor="name">
                            <h5 className="text-blue pt-3">Category Name</h5>
                          </label>
                          <input
                            {...input}
                            type="text"
                            className="form-control border-light-blue text-black py-3 fw-600"
                            placeholder="Category Name"
                          />
                          <p className="font-blue pt-2 mb-0">
                            A Category name is required and recommended to be
                            unique.
                          </p>
                        </div>
                        {meta.error && meta.touched && (
                          <div className="text-danger">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </div>

                <div className="my-5 text-lg-end text-center">
                  <button
                    type="button"
                    onClick={() => {
                      for (let keys in values) {
                        form.resetFieldState(keys);
                      }
                      form.reset();
                    }}
                    className="btn btn-light btn-lg text-secondary fw-bold me-3"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-blue btn-lg text-white fw-bold"
                  >
                    Add Category
                  </button>
                </div>
              </form>
            )}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AddCategories;

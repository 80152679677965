import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { resetPassword } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Resetpass = () => {
  const navigate = useNavigate()
  const location = useLocation();

  const onSubmit = async (values) => {
    values.email = location.state.email;
    values.otp = location.state.otp;
    delete values.confirmPassword;
    const data = await resetPassword(values);
    const notify = () => toast.success(data.data);
    
    if (data.success == true) {
      {
        notify();
      }
    }
    navigate("/login")
  };

  return (
    <>
     <ToastContainer />
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          const regexPass =
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
          if (!values.password) {
            errors.password = "This value is required";
          } else if (!regexPass.test(values.password)) {
            errors.password = "Please enter unique values";
          }
          if (values.confirmPassword != values.password) {
            errors.confirmPassword = "Password must match";
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={(event) => handleSubmit(event.preventDefault())}>
            <Row>
              <Col
                lg={4}
                className="align-self-center offset-lg-1 px-3 px-md-5 px-lg-0"
              >
                <p className="text-info-blue fw-bold pt-md-5 text-center text-lg-start heading-1 p-0 m-0">
                  New Password
                </p>
                <p className="text-gray text-center text-lg-start fs-5 p-0">
                  Enter your New Password and Confirm Password.
                </p>
                <Field name="password">
                  {({ input, meta }) => (
                    <div className="row">
                      <label className="fw-bold fs-5">Password</label>
                      <div className="pt-2">
                        <input
                          {...input}
                          type="password"
                          autoComplete="true"
                          placeholder="Enter Your password"
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger form-control py-3"
                              : "form-control py-3"
                          }
                        />
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="confirmPassword">
                  {({ input, meta }) => (
                    <div className="row pt-3">
                      <label className="fw-bold fs-5">Confirm Password</label>
                      <div className="pt-2">
                        <input
                          {...input}
                          type="password"
                          autoComplete="true"
                          placeholder="Enter Your password"
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger form-control py-3"
                              : "form-control py-3"
                          }
                        />
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Button
                  type="submit"
                  className="btn-blue w-100 mt-2 mt-xl-5 py-3 fw-bold fs-5 border-0 mx-auto d-block"
                >
                  SAVE PASSWORD
                </Button>
              </Col>
              <Col
                lg={6}
                className="offset-lg-1 text-center text-lg-start pt-4"
              >
                <img
                  src="assets/password.svg"
                  className="img-fluid"
                  alt="login"
                />
              </Col>
            </Row>
          </form>
        )}
      />
    </>
  );
};

export default Resetpass;

import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import { baseUrl, getAllSalesOrder } from "../../Api";
import moment from "moment";

const SalesOrderListing = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState("10");
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    allSalesOrder();
  }, [page, sizePerPage]);

  const allSalesOrder = async () => {
    const data = await getAllSalesOrder({
      page: page,
      limit: sizePerPage,
    });
    if (data && data.data) {
      setOrderList(data.data);
      setTotalSize(data.count);
    }
  };

  const columns = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "id",
      text: "Order Id",
    },
    {
      dataField: "userName",
      text: "Customer",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row align-items-center">
          <div>
            {!row?.user?.profileImagePath  ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.user?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2">
            <span className="fw-bold"> {row?.user?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        if (cell == 1) {
          return (
            <button className="btn-success-green border-0 py-2 px-3 rounded">
              Ordered
            </button>
          );
        } else if (cell == 2) {
          return (
            <button className="btn-blue-info border-0 py-2 px-3 rounded">
              Delivered
            </button>
          );
        } else {
          return (
            <button className="btn-danger-red border-0 py-2 px-3 rounded">
              Refunded
            </button>
          );
        }
      },
    },
    {
      dataField: "TotalAmount",
      text: "Total Amount",
      formatter: (cell, row) => {
        return `$ ${cell}`;
      },
    },
    {
      dataField: "createdAt",
      text: "Order Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "phone",
      text: "Phone No",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="btn-group">
            <Link to="/sales-view-detail" state={{ salesOrder: row }}>
              <button className="border-0 bg-light me-2 py-2 px-2 rounded">
                <img src="assets/view.svg" alt="view" />
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    // onSizePerPageChange: (sizePerPage, page) => {
    //   setPage(page);
    //   setSizePerPage(sizePerPage);
    // },
    // sizePerPageList: [
    //   {
    //     text: "10",
    //     value: 10,
    //   },
    //   {
    //     text: "20",
    //     value: 20,
    //   },
    //   {
    //     text: "30",
    //     value: 30,
    //   },
    // ],

    page: page,
    totalSize: totalSize,
    custom: true,
  };

  return (
    <React.Fragment>
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[...columns]}
                      data={orderList}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Sales Orders Listing
                            </h3>
                            <Row>
                              <Col
                                lg="6"
                                md="12"
                                className="text-center text-lg-start"
                              >
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              {/* <Col lg="6" md="12" className="text-center text-lg-end">
                        <div className="pt-4">
                          <select
                            name="game"
                            className="border-gray ps-2 py-2 w-50 rounded-3"
                          >
                            <option value="soccer">Soccer</option>
                            <option value="basketball">Basketball</option>
                            <option value="baseball">Baseball</option>
                            <option value="volleyball">Volleyball</option>
                            <option value="football">Football</option>
                          </select>
                        </div>
                      </Col> */}
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Sales Orders Listing
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={() => false}
                                        headerClasses="align-self-center"
                                        rowClasses=""
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        bootstrap4={true}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {orderList.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>

                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={[...columns]}
                  data={orderList}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Sales Orders Listing
                        </h3>
                        <Row>
                          <Col
                            lg="6"
                            md="12"
                            className="text-center text-lg-start"
                          >
                            <div className="pt-4">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          {/* <Col lg="6" md="12" className="text-center text-lg-end">
                        <div className="pt-4">
                          <select
                            name="game"
                            className="border-gray ps-2 py-2 w-50 rounded-3"
                          >
                            <option value="soccer">Soccer</option>
                            <option value="basketball">Basketball</option>
                            <option value="baseball">Baseball</option>
                            <option value="volleyball">Volleyball</option>
                            <option value="football">Football</option>
                          </select>
                        </div>
                      </Col> */}
                        </Row>
                        <Row>
                          <Col className="mt-3 rounded-3 bg-white px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Sales Orders Listing
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={() => false}
                                    headerClasses="align-self-center"
                                    rowClasses=""
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    bootstrap4={true}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {orderList.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>

                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default SalesOrderListing;

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";

// Table data

const products = [
  {
    id: 1,
    orderId: "#SHP-0058001",
    shippingType: "Flat Shipping Rate",
    referedUser: "Grace Green",
    date: "12/9/2021",
    orderNo: "12",
    total: "$89.00",
    status: "completed"
  },
  {
    id: 2,
    orderId: "#SHP-0058001",
    shippingType: "Flat Shipping Rate",
    referedUser: "Nick Logan",
    date: "12/9/2021",
    orderNo: "10",
    total: "$89.00",
    status: "inProgress"
  },
  {
    id: 3,
    orderId: "#SHP-0058001",
    shippingType: "Flat Shipping Rate",
    referedUser: "Dianne Russell",
    date: "12/9/2021",
    orderNo: "22",
    total: "$89.00",
    status: "cancelled"
  },
  {
    id: 4,
    orderId: "#SHP-0058001",
    shippingType: "Flat Shipping Rate",
    referedUser: "Kristin Watson",
    date: "12/9/2021",
    orderNo: "20",
    total: "$89.00",
    status: "inTransit"
  },
];

const ShippingDetail = (props) => {

  const [productData, setProductData] = useState(products);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [deleteId, setDeleteId] = useState(0);
  const [totalSize, setTotalSize] = useState(100);

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "orderId",
      text: "Order ID",
    },
    {
      dataField: "shippingType",
      text: "Shipping Type",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "status",
      text: "Payment Details",
      formatter: (cell, row) => {
        if (row?.status == "completed") {
          return (
            <div>
              <button className="btn-success-green border-0 py-2 px-3 rounded">
              Completed
              </button>
            </div>
          );
        } else if (row?.status == "inProgress") {
          return (
            <div>
            <button className="btn-warning-yelloww border-0 py-2 px-3 rounded">
            In Progress
            </button>
          </div>
          );
        } else if (row?.status == "cancelled") {
          return (
            <div>
              <button className="btn-danger-red border-0 py-2 px-3 rounded">
              Cancelled
            </button>
            
            </div>
          );
        } else if (row?.status == "inTransit") {
          return (
            <div>
              <button className="btn-blue-info border-0 py-2 px-3 rounded">
              In Transit
            </button>
            </div>
          );
        }
      },
    },
    {
      dataField: "orderNo",
      text: "No. Orders",
    },
    {
      dataField: "total",
      text: "Total",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="btn-group">
           <Link to="/view-shipping-detail"> <button className="border-0 me-3 py-2 px-2 rounded">
              <img src="assets/view.svg" alt="view" />
            </button></Link>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: products.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={[...columns]}
              data={[...productData]}
              search
            >
              {(toolkitprops) => (
                <React.Fragment>
                  <div className="container-fluid page-content bg-light-blue px-lg-3">
                    <h3 className="text-black fw-bold text-uppercase pt-3">
                      Shipping Details
                    </h3>
                    <Row>
                      <Col
                        xxl="5"
                        sm="12"
                        className="text-xxl-start text-center"
                      >
                        <div className="pt-4">
                          <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              className=""
                              {...paginationProps}
                            />
                          </div>

                          <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 rounded-3 bg-white px-4">
                        <h4 className="py-4 text-blue fw-600">Shipping Information</h4>
                        <Row>
                          <div className="table-responsive">
                            <div>
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light text-grey-light"}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                      <h5>Showing 1 - 10 of 350</h5>

                      <div className="ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      }
    </React.Fragment>
  );
};

export default ShippingDetail;

import React from "react";

const Home = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light pt-xxl-4 bg-white">
        <div className="container-fluid px-xxl-5 px-3">
          <div className="ps-0 ps-lg-5">
            <div className="ps-5">
              <img
                src="assets/images/mainlogo.png"
                alt="logo"
                className="img-fluid width-100"
              />
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ps-5">
                <a className="nav-link nav-links d-inline-block" href="##home">
                  Home
                </a>
              </li>
              <li className="nav-item ps-5">
                <a className="nav-link nav-links d-inline-block" href="##about">
                  About
                </a>
              </li>
              {/* <li className="nav-item ps-5">
                <a className="nav-link nav-links d-inline-block" href="##blog">
                  Blog
                </a>
              </li> */}
              <li className="nav-item ps-5">
                <a
                  className="nav-link nav-links d-inline-block"
                  href="##features"
                >
                  Features
                </a>
              </li>
              <li className="nav-item ps-5">
                <a
                  className="nav-link nav-links d-inline-block"
                  href="##contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="d-lg-flex d-block ps-0">
              {/* <li className="nav-item dropdown list-unstyled pe-xxl-3">
                <a
                  className="nav-link dropdown-toggle text-dark fs-5 fw-bold ps-0"
                  href="##"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  En <i className="fa-solid fa-angle-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="##">
                      Language 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="##">
                      Language 2
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item list-unstyled pe-xxl-5">
                <a
                  className="nav-link d-inline-block text-dark fs-5 fw-bold ps-0"
                  href="##"
                >
                  <img
                    src="assets/images/contact-vector.png"
                    alt="contact-vector"
                    className="img-fluid"
                  />
                  My Account
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <section id="#home">
        <div className="container-fluid px-xxl-5 pt-5">
          <div className="row d-none d-md-block">
            <img src="assets/images/main-image.png" alt="main image logo" />
          </div>
          <div className="d-block d-md-none px-xxl-5">
            <div className="col-xl-2 p-0 p-lg-2 align-self-center col-12">
              <img
                src="assets/images/hero-sec-img-1.png"
                alt="hero-sec-img-1"
                className="img-fluid d-block mx-auto hero-sec-img border-radius"
              />
            </div>
            <div className="col-xl-7">
              <div className="row">
                <div className="col-xl-8 col-sm-6 p-0 p-lg-2 col-12">
                  <img
                    src="assets/images/hero-sec-img-2.png"
                    alt="hero-sec-img-2"
                    className="img-fluid w-100 h-100 p-2 p-xl-0"
                  />
                </div>
                <div className="col-xl-4 col-sm-6 p-0 p-lg-2 col-12">
                  <img
                    src="assets/images/hero-sec-img-3.png"
                    alt="hero-sec-img-3"
                    className="img-fluid w-100 h-100 p-2 p-xl-0"
                  />
                </div>
                <div className="col-xl-4 mt-xl-4 col-sm-6 p-0 p-lg-2 col-12">
                  <img
                    src="assets/images/hero-sec-img-4.png"
                    alt="hero-sec-img-4"
                    className="img-fluid w-100 p-2 p-xl-0"
                  />
                </div>
                <div className="col-xl-8 mt-xl-4 col-sm-6 p-0 p-lg-2 col-12">
                  <img
                    src="assets/images/hero-sec-img-5.png"
                    alt="hero-sec-img-5"
                    className="img-fluid w-100 h-100 p-2 p-xl-0 border-radius"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 align-self-center col-12">
              <img
                src="assets/images/hero-sec-img-6.png"
                alt="hero-sec-img-6"
                className="img-fluid d-block mx-auto hero-sec-img"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="#about">
        <div className="container-fluid text-center px-xl-5 pb-5">
          <h1 className="about-sec-h1 font-bold text-blue1">
            Get Hype is a Motivational App That Targets Youth Sports Players
          </h1>
          <p className="about-sec-p pt-xl-4">
            “Some of our features includes music playlists, motivational
            audio/video, user video challenges, messaging, scheduling, countdown
            clock, games, photo albums, statistics and much more.”
          </p>
          <div className="row pe-xl-5">
                  <div className="offset-md-3 col-md-3 col-12">
                    <a href="https://apps.apple.com/us/app/get-hype-app/id6446924741?platform=iphone">
                      <img
                        src="assets/images/blog-sec-img-1.png"
                        alt="blog-sec-img-1"
                        className="img-fluid p-3"
                      />
                    </a>
                  </div>
                  <div className="col-md-3 col-12">
                    <a href="https://play.google.com/store/apps/details?id=com.gethype&platform=andriod">
                      <img
                        src="assets/images/blog-sec-img-2.png"
                        alt="blog-sec-img-2"
                        className="img-fluid p-3"
                      />
                    </a>
                  </div>
                </div>
          {/* <div className="row pt-5">
            
            <div className="col-md-6 text-center text-md-start">
              <a href="##" className="text-decoration-none ps-md-3 ps-0">
                <img
                  src="assets/images/video-image.png"
                  alt="video image"
                  className="img-fluid video-img"
                />
                <p className="d-inline text-dark fw-bold fs-4 align-self-center ps-3">
                  How to Work
                </p>
              </a>
            </div>
          </div> */}
        </div>
      </section>
      <section id="#company">
        <div className="container-fluid px-xxl-5 py-xl-5">
          <h1 className="fw-bold title-text pt-5 text-center">
            Cooperate with the company 👏
          </h1>
          <div className="row pt-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-2 col-sm-4 col-12 align-self-center">
              <img
                src="assets/images/cooparate-logo-1.png"
                alt="Reebok"
                className="img-fluid w-75 d-block mx-auto"
              />
            </div>
            <div className="col-lg-2 col-sm-4 col-12 align-self-center">
              <img
                src="assets/images/cooparate-logo-2.png"
                alt="co-operate-2"
                className="img-fluid w-75 d-block mx-auto"
              />
            </div>
            <div className="col-lg-2 col-sm-4 col-12 align-self-center">
              <img
                src="assets/images/cooparate-logo-3.png"
                alt="co-operate-3"
                className="img-fluid w-75 d-block mx-auto"
              />
            </div>
            <div className="col-lg-2 col-sm-4 mb-5 mb-sm-0 col-12 offset-sm-1 offset-lg-0 align-self-center">
              <img
                src="assets/images/cooparate-logo-4.png"
                alt="adidas"
                className="img-fluid w-75 d-block mx-auto"
              />
            </div>
            <div className="col-lg-2 col-sm-4 col-12 offset-sm-1 offset-lg-0 align-self-center">
              <img
                src="assets/images/cooparate-logo-5.png"
                alt="under armour"
                className="img-fluid w-75 d-block mx-auto"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <section id="#works">
        <div className="container-fluid px-xxl-5 py-5">
          <h1 className="fw-bold title-text pt-5 text-center">How it works</h1>
          <p className="work-sec-p pt-4">
            Get Hype is created to help the player get in the zone before a
            performance. During off time the player can stay connected with
            other athletes that will help keep each other stay motivated.
          </p>
          <div className="px-xxl-5 pt-5">
            <div className="px-xxl-5">
              <div className="row px-xl-5">
                <div className="col-xxl-4 col-md-6 col-12">
                  <img
                    src="assets/images/how-we-work-img-1.png"
                    alt="how-we-work-img-1"
                    className="img-fluid d-block mx-auto"
                  />
                </div>
                <div className="col-xxl-4 ps-0 ps-md-3 col-md-6 col-12">
                  <img
                    src="assets/images/how-we-work-img-2.png"
                    alt="how-we-work-img-2"
                    className="img-fluid d-block mx-auto"
                  />
                </div>
                <div className="col-xxl-4 col-md-6 offset-sm-0 offset-md-3 offset-xxl-0 col-12">
                  <img
                    src="assets/images/how-we-work-img-3.png"
                    alt="how-we-work-img-3"
                    className="img-fluid px-0 px-sm-5 d-block mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="#features">
        <div className="container-fluid py-5">
          <div className="row pt-xl-5">
            <div className="col-xxl-6 col-xl-12 align-self-center">
              <img
                src="assets/images/best-feature-img-1.png"
                alt="best-feature-img"
                className="img-fluid"
              />
            </div>
            <div className="col-xxl-6 ps-xl-5">
              <h1 className="best-feture-section-h1 ps-xxl-5 text-center text-xl-start">
                Get Hype App Features <br />
              </h1>
              <div className="row ps-xl-5 pt-4">
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">
                            Countdown Clock
                          </h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">
                            My Challenges For Players
                          </h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">
                            Player’s Every Game Statistics
                          </h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">Message</h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">Playlist</h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">
                            Motivational Clips
                          </h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 text-sm-start text-center">
                  <div className="card border-0 mb-3">
                    <div className="row g-0">
                      <div className="col-1 text-center">
                        <img
                          src="assets/images/best-feature-img-2.png"
                          alt="best-feature-img-2"
                          className="img-fluid mt-4 pt-2"
                        />
                      </div>
                      <div className="col-11">
                        <div className="card-body">
                          <h5 className="card-title card-title-h5">
                            Many More Features...
                          </h5>
                          {/* <p className="card-text text-card pt-4">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid py-5 px-xl-5">
          <div className="px-xxl-5">
            <div className="row px-xl-5 bg-gray pt-5">
              <div className="col-xl-6 col-lg-12 text-xl-start text-center pt-xl-5">
                <h1 className="blog-sec-h1 fw-bold ps-xl-3 pt-xl-5">
                  Download app from the app store & Google Play
                </h1>
                <p className="blog-sec-p pt-2 ps-xl-3">
                  Learn into that problem translating our vision of having a
                  market leading platfrom.
                </p>
                <div className="row pe-xl-5">
                  <div className="col-md-6 col-12">
                    <a href="https://apps.apple.com/us/app/get-hype-app/id6446924741?platform=iphone">
                      <img
                        src="assets/images/blog-sec-img-1.png"
                        alt="blog-sec-img-1"
                        className="img-fluid p-3"
                      />
                    </a>
                  </div>
                  <div className="col-md-6 col-12">
                    <a href="https://play.google.com/store/apps/details?id=com.gethype&platform=andriod">
                      <img
                        src="assets/images/blog-sec-img-2.png"
                        alt="blog-sec-img-2"
                        className="img-fluid p-3"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 text-xl-start text-center pt-xl-5">
                <img
                  src="assets/images/blog-sec-img-3.png"
                  alt="blog-sec-img-3"
                  className="img-fluid ms-xl-5 mt-5"
                >
                </img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="py-xl-4">
        <hr className="hr-line my-3" />
      </div>
      <section id="#contact">
        <div className="container-fluid px-xl-5 py-md-5 py-2">
          <div className="row px-xxl-5">
            <div className="col-xl-6 px-xl-5 col-md-6 col-12 text-center text-xl-start pt-5 pt-xl-0">
              <img
                src="assets/images/mainlogo.png"
                alt="logo"
                className="img-fluid width-100"
              />
              <p className="contact-sec-p pt-xl-3 pb-xl-5">
                Play better, Improve faster. The most complete mobile app for
                players.
              </p>
              <div className="align-items-center">
                <img
                  src="assets/images/mail-icon.png"
                  alt="mail-icon"
                  className="mail-icon"
                />
                <a
                  href="mailto:leanna@get-hype-app.com"
                  className="d-inline text-decoration-none"
                >
                  <p className="d-inline ps-1 email-p text-break">
                    Leanna@get-hype-app.com
                  </p>
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-12 text-center text-xl-start pt-5 pt-xl-0">
              <h4 className="fw-bold pb-xl-4">What we do</h4>
              <p>
                <a
                  href="##home"
                  className="text-decoration-none contact-sec-link"
                >
                  Home
                </a>
              </p>
              <p>
                <a
                  href="##works"
                  className="text-decoration-none contact-sec-link"
                >
                  How it works
                </a>
              </p>
              <p>
                <a
                  href="##features"
                  className="text-decoration-none contact-sec-link"
                >
                  Features
                </a>
              </p>
              {/* <p>
                <a
                  href="##blog"
                  className="text-decoration-none contact-sec-link"
                >
                  Blog
                </a>
              </p> */}
              <p>
                <a
                  target="_blank"
                  href="/#/privacy-policy"
                  className="text-decoration-none contact-sec-link"
                >
                  Privacy Policy
                </a>
              </p>
              <p>
                <a
                  href="/#/terms-conditions"
                  target="_blank"
                  className="text-decoration-none contact-sec-link"
                >
                  Terms And Condition
                </a>
              </p>
            </div>
            <div className="col-xl-2 ps-xl-5 col-md-6 col-12 text-center text-xl-start pt-5 pt-xl-0">
              <h4 className="fw-bold pb-xl-4">Who we are</h4>
              <p>
                <a
                  href="##about"
                  className="text-decoration-none contact-sec-link"
                >
                  About Us
                </a>
              </p>
              <p>
              
                <a href="mailto:leanna@get-hype-app.com" className="text-decoration-none contact-sec-link">
                  Contact Us
                </a>
              </p>
              {/* <p>
                <a
                  href="javascript:void(0)"
                  className="text-decoration-none contact-sec-link"
                >
                  Careers
                </a>
              </p>
              <p>
                <a
                  href="javascript:void(0)"
                  className="text-decoration-none contact-sec-link"
                >
                  FAQ
                </a>
              </p>
              <p>
                <a
                  href="javascript:void(0)"
                  className="text-decoration-none contact-sec-link"
                >
                  Insight
                </a>
              </p>
              <p>
                <a
                  href="javascript:void(0)"
                  className="text-decoration-none contact-sec-link"
                >
                  Help
                </a>
              </p> */}
            </div>
            <div className="col-xl-2 ps-xl-5 col-md-6 col-12 text-center text-xl-start pt-5 pt-xl-0">
              <h4 className="fw-bold pb-xl-4">Follow us</h4>
              <p>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  className="text-decoration-none contact-sec-link"
                >
                  Instagram
                </a>
              </p>
              <p>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  className="text-decoration-none contact-sec-link"
                >
                  Facebook
                </a>
              </p>
              <p>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  className="text-decoration-none contact-sec-link"
                >
                  Twitter
                </a>
              </p>
            </div>
          </div>
          <p className="text-center pt-4 fs-5">
            Copyright© {new Date().getFullYear()}. Get Hype, LLC. All Right
            Reserved.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Home;

import React from "react";
import {  HashRouter } from "react-router-dom";
import "./index.css";
import "./App.css";
import Content from "./components/content/Content";
const App = (props) => {

  return (
    <HashRouter>
      {/* <div className="App wrapper"> */}
        <Content  />
      {/* </div> */}
   </HashRouter>
  );

  
};

export default App;

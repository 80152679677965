import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import {
  getPaidUserPremium,
  baseUrl,
  getGoogleAds,
  getPremiumUser,
  getAllReport,
  editChallenges,
  getReportById,
} from "../../Api";
import moment from "moment/moment";

// Table data

const reasonArray = [
  "Something else",
  "Nudity or Sexual Activity",
  "Hate Speech or Symbols",
  "I Just Don't Like It",
  "False Information",
  "Bullying or Harassment",
  "Scam or Fraud",
  "Violence or Dangerous Organisation",
  "Intellectual Property Violence",
  "Sale of Illegal or Regulated Goods",
  "Suicide or Self-Injury",
  "Eating Disorders",
  "Spam",
  "Terrorism",
  "Offensive",
  "Copyright / Trademark Infringe",
  "Content no Visible / Playable",
  "Drug Promotion",
  "Child Abuse",
];

const DetailReportChallenge = (props) => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [reportById, setReportById] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState("");

  const location = useLocation();
  useEffect(() => {
    allChallenge();
  }, [page, sizePerPage]);

  const allChallenge = async () => {
    let id = location.state.reportChallenge.challengeId;
    const data = await getReportById({
      id: id,
      page: page,
      limit: sizePerPage,
    });
    if (data && data.data) {
      setReportById(data.data);
      setTotalSize(data.count);
    }
  };

  const columns = [
    {
      dataField: "index",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "appUserName",
      text: "App User Name",
      formatter: (cell, row) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <div>
              {!row?.user?.profileImagePath ? (
                <img src="assets/Avatar.png" className="width-50" />
              ) : (
                <img
                  src={`${baseUrl}uploads/${row?.user?.profileImagePath}`}
                  className="width-50"
                />
              )}
            </div>
            <div className="ps-2">
              <span className="fw-bold"> {row?.user?.fullname} </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "reason",
      text: "Reason Of Reporting",
      formatter: (cell, row) => {
        if (row.reasonId == 1) {
          return row.reason;
        } else if (row.reasonId >= 0 && row.reasonId < reasonArray.length)
          return reasonArray[row.reasonId - 1];
        return "No reason";
      },
    },
    {
      dataField: "createdAt",
      text: "Reported date & Time",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY hh:mm A");
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    page: page,
    totalSize: totalSize,
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <DeleteModal deleteIndex={deleteIndex} allChallenge={allChallenge} />
      {
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={[...columns]}
              data={[...reportById]}
              search
            >
              {(toolkitprops) => (
                <React.Fragment>
                  <div className="container-fluid page-content bg-light-blue px-lg-3">
                    <h3 className="text-black fw-bold text-uppercase pt-3">
                      Details of Reported Challenges
                    </h3>
                    <Row className="bg-white rounded-3 my-3 p-3">
                      <div className="d-flex align-items-center">
                        <div>
                          {!reportById[0]?.reportedUser?.profileImagePath  ? (
                            <img
                              className="width-150"
                              src="assets/images/deleteAvatar.png"
                              alt="reportUser"
                            />
                          ) : (
                            <img
                              className="width-150"
                              src={reportById[0]?.reportedUser?.profileImagePath}
                              alt="reportUser"
                            />
                          )}
                        </div>
                        <div className="ps-3">
                          <h5 className="text-dark fw-600">
                            {reportById[0]?.reportedUser?.fullname}
                          </h5>
                          <p>- This Challenge has Been Created By This User</p>
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() =>
                              setDeleteIndex({
                                isDeleteUser: true,
                                index: reportById[0]?.reportedUser?.id,
                                name: reportById[0]?.reportedUser?.fullname,
                              })
                            }
                            className="py-2 px-5 border-0 bg-danger rounded-2 text-white fw-600"
                          >
                            Delete User
                          </button>
                        </div>
                      </div>
                    </Row>  

                    <Row>
                      <Col xxl="5" sm="12" className="text-start">
                        <div className="pt-4">
                          <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                            Show
                          </p>
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              className=""
                              {...paginationProps}
                            />
                          </div>

                          <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                            Entries
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 rounded-3 bg-white px-4">
                        <h4 className="py-4 text-blue fw-600">
                          Details of Reported Challenges
                        </h4>

                        <Row>
                          <div className="table-responsive">
                            <div>
                              <BootstrapTable
                                remote
                                onTableChange={() => false}
                                headerClasses="align-self-center"
                                rowClasses=""
                                keyField="id"
                                responsive
                                bordered={false}
                                bootstrap4={true}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={
                                  "thead-light text-grey-light"
                                }
                                {...props.baseProps}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                      <h6 className="text-blue fw-600 ps-2">
                        {reportById.length == 0
                          ? "Showing 0"
                          : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                      </h6>
                      <div className="ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      }
    </React.Fragment>
  );
};

export default DetailReportChallenge;

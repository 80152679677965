import React from 'react'
import { baseUrl } from '../../Api'

const PlayVideoData = ({playVideoData}) => {
  return (


    <div>
       <div
        className="modal fade"
        id="reportVideoOne1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
              Reported Challenge Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body d-flex align-items-center justify-content-center">
              <video
                id="video1"
                width="370"
                height="240"
                controls
                controlsList="nodownload"
              >
                <source
                  src={`${baseUrl}uploads/${playVideoData?.ochallenges?.videoId}`}
                  type="video/mp4"
                  media="(max-height: 400px)"
                />
              </video>
            </div>

            <p className="text-center">
              <strong> Challenge Name: </strong> {playVideoData?.ochallenges?.name}
            </p>
            <p className="text-center">
              <strong> Challenge Description: </strong> {playVideoData?.ochallenges?.description}
            </p>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayVideoData

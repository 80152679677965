import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";

import { Form, Field } from "react-final-form";
import { createOtp, verifyOtp } from "../../Api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const ForgotPass = () => {
  const location = useLocation();
  const notify = () => toast.success("User has been Registered Successfully");
  
  const [emailName, setEmailName] = useState("");
  const [disable, setDisable] = useState(false);
  const [otpdisable, setOtpDisable] = useState(true);
  const [time, setTime] = useState("");
  const [resendOtp, setResendOtp] = useState(true);
  const [count, setCount] = useState(0);
  const [myInterval, setMyInterval] = useState();
  const navigate = useNavigate();

  let a = 60;
  const getMinutes = () => {
    if (a == -1) {
      setResendOtp(false);
      clearInterval(myInterval);
      setTime("");
      return 0;
    }
    let minute = a--;
    let minutes = parseInt(minute / 60);
    let second = parseInt(minute / 60) ? minute - 60 : minute;
    if (second < 10) {
      second = "0" + second;
    }
    setTime(`${minutes}:${second}`);
  };


  useEffect(() => {
    if (resendOtp == false) clearInterval(myInterval);
  }, [myInterval, resendOtp]);
  if (count == 4) {
    alert("some issue with your email");
    navigate("/login");
  
    window.location.reload(true);
  }

  const getEmail = async () => {
    const data = await createOtp({ email: emailName });
    setOtpDisable(false);
  };

  const onSubmit = async (values) => {
    values.email = emailName;
    const data = await verifyOtp(values);
    if (data.success == true) {
      {
        notify();
      }
      navigate("/reset-password",{
        state: {email: emailName, otp:values.otp} 
    })
    } 
    const errorNotify = () => toast.error(data.response.data.message);
    if (data.response.data.success == false) {

      {
        errorNotify();
      }
    } 
  };

  const getResendOtp = async () => {
    const data = await createOtp({ email: emailName });
  };

  return (
    <React.Fragment>
    <ToastContainer />
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          values.email = emailName;
          const errors = {};
          const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
          if (!values.email) {
            errors.email = "This value is required";
          } else if (!regexEmail.test(values.email)) {
            errors.email = "Please Provide valid Email ID";
          }
          if (!values.otp) {
            errors.otp = "This value is required";
          } else if (values.otp.length < 4 || values.otp.length > 4) {
            errors.otp = "Please Provide valid otp";
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col
                lg={4}
                className="align-self-center offset-lg-1 px-3 px-md-5 px-lg-0"
              >
                <p className="text-info-blue fw-bold pt-md-5 text-center text-lg-start heading-1 p-0 m-0">
                  Forgot Password
                </p>
                <p className="text-gray text-center text-lg-start pb-3 fs-5 p-0">
                  Enter your email and get OTP to reset password
                </p>

                <Field name="email">
                  {({ input, meta }) => (
                    <div className="row">
                      <label className="fw-bold fs-5">Email</label>
                      <div className="pt-2 input-group">
                        <input
                          {...input}
                          type="email"
                          placeholder="Your email address"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger form-control py-3"
                              : "form-control py-3"
                          }
                          disabled={disable == true ? true : false}
                          value={emailName}
                          onChange={(e) => setEmailName(e.target.value)}
                        />
                        <button
                          {...input}
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger py-3 input-group-text btn-blue px-3 text-white fw-600"
                              : "input-group-text btn-blue px-3 text-white fw-600  py-3"
                          }
                          disabled={disable == true ? true : false}
                          onClick={() => {
                            getEmail();
                            setDisable(true);
                            setCount(count + 1);
                            setMyInterval(setInterval(getMinutes, 1000));
                          }}
                        >
                          <span className="" id="basic-addon2">
                            Get OTP
                          </span>
                        </button>
                      </div>
                      {meta.error && meta.touched && (
                        <div className="text-danger pt-2">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="otp">
                  {({ input, meta }) => (
                    <div className="row pt-4">
                      <label className="fw-bold fs-5">OTP</label>
                      <div className="pt-2">
                        <input
                          {...input}
                          type="number" onWheel={(e) => e.target.blur()}
                          autoComplete="true"
                          placeholder="Your OTP"
                          pattern="/^SW\d{4}$/"
                          className={
                            meta.error && meta.touched
                              ? "border border-1 border-danger form-control py-3"
                              : "form-control py-3"
                          }
                          disabled={otpdisable == true ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <div>
                  <p className="fw-bold fs-5 text-center text-lg-end pt-2">
                    <button
                      type="button"
                      onClick={() => {
                        setCount(count + 1);
                        setResendOtp(true);
                        setMyInterval(setInterval(getMinutes, 1000));
                        getResendOtp();
                      }}
                      disabled={resendOtp}
                      className="bg-transparent border-0 fw-bold"
                    >
                      {" "}
                      Resend OTP{" "}
                    </button>{" "}
                    <span>{time}</span>
                  </p>
                </div>
                <Button
                  type="submit"
                  className="btn-blue w-100 mt-2 mt-xl-5 py-3 fw-bold fs-5 border-0 mx-auto d-block"
                  emailName={emailName}
                >
                  RESET PASSWORD
                </Button>
              </Col>
              <Col
                lg={6}
                className="offset-lg-1 text-center text-lg-start pt-4"
              >
                <img
                  src="assets/forgotPass.svg"
                  className="img-fluid"
                  alt="forgotPass"
                />
              </Col>
            </Row>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default ForgotPass;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Row, Card, Col, Label } from "reactstrap";
import Dropzone from "react-dropzone";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { render } from "react-dom";
import {
  addMerchandise,
  baseUrl,
  editMerchandise,
  getAllCategories,
} from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, useField } from "react-final-form-hooks";

const AddMerchandise = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }

  const location = useLocation();

  let formdata = location?.state?.merchandise;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [isSize, setIsSize] = useState(false);
  const [deletedArray, setDeletedArray] = useState([]);
  const [category, setcategory] = useState("1");
  const [categoryList, setcategoryList] = useState([]);
  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState("0");
  const [discountCount, setDiscountCount] = useState();
  const [quantityPrice, setQuantityPrice] = useState();
  const [addEditQuantity, setAddEditQuantityPrice] = useState(0);
  const [addStockProductSize, setAddStockProductSize] = useState({
    addS: 0,
    addM: 0,
    addL: 0,
    addXL: 0,
    addXXL: 0,
  });

  const countDiscount = (priced = 0, discounted = 0) => {
    let temp = priced - (priced * discounted) / 100;
    setDiscountCount(Math.ceil(temp));
  };

  useEffect(() => {
    (async () => {
      const data = await getAllCategories(category);
      setcategoryList(data.data);
    })();
  }, [category]);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  };

  const handleAcceptedFiles2 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles2([...selectedFiles2, ...files]);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const navigate = useNavigate();

  const onSubmit = async (values) => {
    if (formdata) {
      values.id = formdata.id;
      delete values.quantity;
      delete values.discount2;
      delete values.countDiscount;
      delete values.s;
      delete values.l;
      delete values.m;
      delete values.xl;
      delete values.xxl;
      values.basePrice = price;
      values.discountPercentage = discount
        ? discount
        : formdata.discountPercentage;

      values.hasSizeAvailable = isSize;
      values.discountedPrice = discountCount
        ? discountCount
        : formdata.discountedPrice;
      values.addQuantity = addEditQuantity;
      if (isSize) {
        values.addS = addStockProductSize.addS;
        values.addL = addStockProductSize.addL;
        values.addM = addStockProductSize.addM;
        values.addXL = addStockProductSize.addXL;
        values.addXXL = addStockProductSize.addXXL;
      }
      let formData = new FormData();
      deletedArray.forEach((x) => formData.append("deletedMedia", x));
      Object.keys(values).forEach((x) => formData.append(x, values[x]));
      formData.delete("thumbnail");

      let thumbnail = selectedFiles.filter((x) => "formattedSize" in x);
      if (Array.isArray(thumbnail) && thumbnail.length) {
        formData.append("thumbnail", thumbnail[0]);
      }

      if (Object.keys(formdata.media).length) {
        let media = selectedFiles2.filter((x) => "formattedSize" in x);
        formData.delete("media");
        for (let i = 0; i < media.length; i++) {
          formData.append("media", media[i]);
        }
      }

      let totalAvlQty = 0;

      if (isSize) {
        totalAvlQty +=
          parseInt(addStockProductSize.addS) +
          parseInt(addStockProductSize.addL) +
          parseInt(addStockProductSize.addM) +
          parseInt(addStockProductSize.addXL) +
          parseInt(addStockProductSize.addXXL);
        if (totalAvlQty == addEditQuantity) {
          const data = await editMerchandise(formData);
          if (data.success == true) {
            const notify = () => toast.success(data.message);
            notify();
            setTimeout(() => {
              navigate("/Merchandise-List");
            }, 2000);
          } else {
            const errorNotify = () => toast.error(data.message);
            errorNotify();
          }
        } else {
          const errorNotify = () =>
            toast.warning("Available Quantity and Product Size doesn't Match");
          errorNotify();
        }
      } else {
        const data = await editMerchandise(formData);
        if (data.success == true) {
          const notify = () => toast.success(data.message);
          notify();
          setTimeout(() => {
            navigate("/Merchandise-List");
          }, 2000);
        } else {
          const errorNotify = () => toast.error(data.message);
          errorNotify();
        }
      }
    } else {
      let totalQty = 0;
      values.basePrice = price;
      values.hasSizeAvailable = isSize;
      values.discountPercentage = discount;
      values.categoriesName = category;
      values.quantity = quantityPrice;

      if (isSize == false) {
        delete values.s;
        delete values.m;
        delete values.l;
        delete values.xl;
        delete values.xxl;
      }
      let formData = new FormData();
      Object.keys(values).forEach((x) => formData.append(x, values[x]));
      if (selectedFiles.length) {
        formData.append("thumbnail", selectedFiles[0]);
      }
      if (selectedFiles2.length) {
        for (let i = 0; i < selectedFiles2.length; i++) {
          formData.append("media", selectedFiles2[i]);
        }
      }
      if (isSize) {
        totalQty =
          parseInt(values.s) +
          parseInt(values.l) +
          parseInt(values.m) +
          parseInt(values.xl) +
          parseInt(values.xxl);
        if (totalQty > quantityPrice) {
          const errorNotify = () =>
            toast.error("Product Size is grater then Quantity");
          errorNotify();
        } else {
          const data = await addMerchandise(formData);
          if (data.success == true) {
            const notify = () => toast.success(data.message);
            notify();
            setTimeout(() => {
              navigate("/Merchandise-List");
            }, 2000);
          } else {
            const errorNotify = () => toast.error(data.message);
            errorNotify();
          }
        }
      } else {
        const data = await addMerchandise(formData);
        if (data.success == true) {
          const notify = () => toast.success(data.message);
          notify();
          setTimeout(() => {
            navigate("/Merchandise-List");
          }, 2000);
        } else {
          const errorNotify = () => toast.error(data.message);
          errorNotify();
        }
      }
    }
  };

  const handleChange = (e) => {
    setAddStockProductSize((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (formdata) {
      setIsSize(formdata.hasSizeAvailable);
      setDiscount(formdata.discountPercentage);
      setDiscountCount(formdata.discountedPrice);
      setPrice(formdata.basePrice);
      setcategory(formdata.category.parentId.toString());
      form.reset({
        name: formdata.name,
        thumbnail: setSelectedFiles([
          {
            name: formdata.thumbnail,
            preview: `${baseUrl}uploads/${formdata.thumbnail}`,
            path: `${baseUrl}uploads/${formdata.thumbnail}`,
          },
        ]),
        description: formdata.description,
        discountPercentage: formdata.discountPercentage,
        basePrice: formdata.basePrice,
        media:
          typeof formdata.media == "string"
            ? []
            : Object.keys(formdata.media).forEach((x) => {
                let temp = {
                  name: x,
                  preview: `${baseUrl}uploads/${x}`,
                  path: `${baseUrl}uploads/${x}`,
                };
                return temp;
              }),

        discountType:
          formdata.discountPercentage == 0
            ? (() => {
                Object.assign(discountType.meta, {
                  active: true,
                  data: {},
                  dirty: true,
                  dirtySinceLastSubmit: false,
                  invalid: false,
                  modified: true,
                  modifiedSinceLastSubmit: false,
                  pristine: false,
                  submitFailed: false,
                  submitSucceeded: false,
                  submitting: false,
                  touched: false,
                  valid: true,
                  visited: true,
                  validating: false,
                });
                return "NoDiscount";
              })()
            : (() => {
                Object.assign(discountType.meta, {
                  active: true,
                  data: {},
                  dirty: true,
                  dirtySinceLastSubmit: false,
                  invalid: false,
                  modified: true,
                  modifiedSinceLastSubmit: false,
                  pristine: false,
                  submitFailed: false,
                  submitSucceeded: false,
                  submitting: false,
                  touched: false,
                  valid: true,
                  visited: true,
                  validating: false,
                });
                return "percentage";
              })(),
        discountedPrice: formdata.discountedPrice,
        quantity: setQuantityPrice(formdata.avlQuantity),
        categories: formdata.category.id,
        hasSizeAvailable: isSize,
        s: formdata.s,
        l: formdata.l,
        m: formdata.m,
        xl: formdata.xl,
        xxl: formdata.xxl,
        status: formdata.status.toString(),
        discount2: discount,
        countDiscount: discountCount,
      });

      if (typeof formdata.media == "string") {
        setSelectedFiles2([]);
      } else {
        let media = [];
        media = Object.keys(formdata.media);
        let mediaObject = media.map((x) => {
          let temp = {
            name: x,
            preview: `${baseUrl}uploads/${x}`,
            path: `${baseUrl}uploads/${x}`,
          };
          return temp;
        });
        setSelectedFiles2(mediaObject);
      }
    }
  }, []);

  const validate = (values) => {
    values.basePrice = price;
    values.discount2 = discount;
    values.categoriesName = category;
    values.quantity = quantityPrice;
    values.addQuantity = addEditQuantity;
    values.addS = addStockProductSize.addS;
    values.addM = addStockProductSize.addM;
    values.addL = addStockProductSize.addL;
    values.addXL = addStockProductSize.addXL;
    values.addXXL = addStockProductSize.addXXL;

    const errors = {};

    if (!values.name) {
      errors.name = "This value is required";
    }
    if (selectedFiles.length < 1) {
      errors.thumbnail = "This value is required";
    }
    if (selectedFiles2.length < 1) {
      errors.media = "This value is required";
    } else if (selectedFiles2.length < 2) {
      errors.media = "Please Provide value more than 2";
    }
    if (!values.description) {
      errors.description = "This value is required";
    }
    if (!values.basePrice) {
      errors.basePrice = "This value is required";
    } else if (values.basePrice < 1) {
      errors.basePrice = "Please provide value more than 0.";
    }
    if (!values.discountType) {
      errors.discountType = "This value is required";
    }
    if (values.discountType == "percentage") {
      if (!values.discount2) {
        errors.discount2 = "This value is required";
      } else if (values.discount2 < 0 || values.discount2 > 100) {
        errors.discount2 = "Please provide value between 0 to 100";
      }
    }
    if (!values.quantity) {
      errors.quantity = "This value is required";
    } else if (values.quantity < 0) {
      errors.quantity = "Please Provide Quantity more than 0";
    }
    if (!values.categoriesName) {
      errors.categoriesName = "This value categories is required";
    }
    if (!values.categories) {
      errors.categories = "This value is required";
    }
    if (!values.status) {
      errors.status = "This value is required";
    }

    if (!formdata) {
      if (isSize == true) {
        if (!values.s) {
          errors.s = "This value is required";
        }
        if (!values.m) {
          errors.m = "This value is required";
        }
        if (!values.l) {
          errors.l = "This value is required";
        }
        if (!values.xl) {
          errors.xl = "This value is required";
        }
        if (!values.xxl) {
          errors.xxl = "This value is required";
        }
      }
    }

    if (formdata) {
      if (values.addQuantity < 0) {
        errors.addQuantity = "Please provide value more than 0.";
      }
      if (isSize == true) {
        if (addEditQuantity > 0) {
          if (values.addS < 0) {
            errors.addS = "Quantity Should not than 0";
          }
          if (values.addM < 0) {
            errors.addM = "Quantity Should not than 0";
          }
          if (values.addL < 0) {
            errors.addL = "Quantity Should not than 0";
          }
          if (values.addXL < 0) {
            errors.addXL = "Quantity Should not than 0";
          }
          if (values.addXXL < 0) {
            errors.addXXL = "Quantity Should not than 0";
          }
        }
      }
    }

    return errors;
  };

  const {
    form,
    handleSubmit,
    values,
    pristine,
    submitting,
    initialValues,
    errors,
    hasValidationErrors,
  } = useForm({
    onSubmit,
    validate,
    initialValues: {
      name: "",
      thumbnail: "",
      description: "",
      media: "",
      basePrice: "",
      discountType: "",
      discount2: "",
      quantity: "",
      categoriesName: "",
      categories: "",
      hasSizeAvailable: "",
      status: "",
      s: "",
      m: "",
      xl: "",
      l: "",
      xxl: "",
      addQuantity: "",
      addS: "",
      addM: "",
      addL: "",
      addXL: "",
      addXXL: "",
    },
  });

  const name = useField("name", form);
  const thumbnail = useField("thumbnail", form);
  const description = useField("description", form);
  const media = useField("media", form);
  const basePrice = useField("basePrice", form);
  const discountType = useField("discountType", form);
  const discount2 = useField("discount2", form);
  const quantity = useField("quantity", form);
  const categoriesName = useField("categoriesName", form);
  const categories = useField("categories", form);
  const hasSizeAvailable = useField("hasSizeAvailable", form);
  const status = useField("status", form);
  const s = useField("s", form);
  const m = useField("m", form);
  const l = useField("l", form);
  const xl = useField("xl", form);
  const xxl = useField("xxl", form);
  const addQuantity = useField("addQuantity", form);
  const addS = useField("addS", form);
  const addM = useField("addM", form);
  const addL = useField("addL", form);
  const addXL = useField("addXL", form);
  const addXXL = useField("addXXL", form);

  useEffect(() => {}, [selectedFiles2, selectedFiles]);
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container-fluid px-3 page-content pb-5 bg-light-blue">
        <Row>
          {formdata ? (
            <Breadcrumbs name={"Edit Merchandise"} />
          ) : (
            <Breadcrumbs name={"Add Merchandise"} />
          )}
        </Row>
        <form onSubmit={handleSubmit}>
          <Row className="bg-white px-4 pb-5 rounded-3">
            <div>
              <h5 className="text-blue fw-600 py-3">Product Detail</h5>
            </div>
            <div>
              <label htmlFor="name">
                <h5 className="text-blue pt-3"> Product Name </h5>
              </label>
              <input
                {...name.input}
                type="text"
                className="form-control border-light-blue text-black py-3 fw-600"
                placeholder="First Name"
              />
              <p className="font-blue pt-2 mb-0">
                A product name is required and recommended to be unique.
              </p>
              {name.meta.touched && name.meta.error && (
                <p className="text-danger">{name.meta.error}</p>
              )}
            </div>
            <div>
              <div className="row">
                <div className="col-lg-2 col-12">
                  <label htmlFor="thumbnail">
                    <h5 className="text-blue pt-3">Thumbnail</h5>
                  </label>
                </div>
                <div className="col-lg-10 col-12 pt-4">
                  <Dropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpeg", ".jpg"],
                    }}
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone text-center">
                        <div
                          className="dz-message needsclick pt-5"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="fs-2 fa-solid text-gray fa-cloud-arrow-up"></i>
                          </div>
                          <h4>Drop logo here or click to upload.</h4>
                          <p className="font-blue fs-6">
                            Set the product thumbnail image. Only *.png, *.jpg
                            and *.jpeg image files are accepted
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div
                    className="dropzone-previews d-flex mt-3"
                    id="file-previews"
                  >
                    {selectedFiles.length > 0 ? (
                      selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border-0 d-inline"
                            key={i + "-file"}
                          >
                            <div className="thumb">
                              <img
                                data-dz-thumbnail=""
                                className="img-fluid d-inline thumbInner"
                                alt={f.name}
                                src={f.preview}
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedFiles(selectedFiles.pop());

                                  setSelectedFiles([...selectedFiles]);
                                }}
                                className="bg-transparent border-0"
                              >
                                {" "}
                                <div className="middle">
                                  <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                </div>
                              </button>
                            </div>
                          </Card>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {thumbnail.meta.touched && thumbnail.meta.error && (
                <p className="text-danger">{thumbnail.meta.error}</p>
              )}
            </div>
            <div>
              <label htmlFor="description">
                <h5 className="text-blue pt-3"> Description </h5>
              </label>
              <textarea
                {...description.input}
                rows="8"
                className="form-control border-light-blue text-black py-3 fw-600"
                placeholder="Enter description"
              ></textarea>
              <p className="font-blue fs-6 pt-1 mb-0">
                Set a description to the product for better visibility.
              </p>
              {description.meta.touched && description.meta.error && (
                <p className="text-danger">{description.meta.error}</p>
              )}
              <hr className="border-gray" />
            </div>
            <div>
              <div className="row">
                <label htmlFor="media">
                  <h5 className="text-blue pt-3">Media</h5>
                </label>
                <div className="pt-2">
                  <Dropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpeg", ".jpg"],
                    }}
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFiles2(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone-sec">
                        <div className="d-flex pt-4" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="ps-3 mb-3">
                            <img
                              src="assets/mediafile.svg"
                              alt="media file"
                              className="mt-2"
                            />
                          </div>
                          <div className="ps-3">
                            <h5 className="fw-600">Select the Media.</h5>
                            <p>Upload Media files</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles2.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                          key={i + "-file"}
                        >
                          <div className="thumb">
                            <img
                              data-dz-thumbnail=""
                              className="img-fluid d-inline thumbInner"
                              alt={f.name}
                              src={f.preview}
                            />
                            <button
                              type="button"
                              value={f.path}
                              onClick={(e) => {
                                let deleteItem = selectedFiles2.splice(i, 1);
                                if (!("formattedSize" in deleteItem[0])) {
                                  deletedArray.push(deleteItem[0].name);
                                }
                                setSelectedFiles2([...selectedFiles2]);
                              }}
                              className="bg-transparent border-0"
                            >
                              <div className="middle">
                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                              </div>
                            </button>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              </div>
              {media.meta.touched && media.meta.error && (
                <p className="text-danger">{media.meta.error}</p>
              )}
              <hr className="border-gray" />
            </div>
            <div className="pb-3">
              <label htmlFor="basePrice">
                <h5 className="text-blue pt-3"> Base Price </h5>
              </label>
              <input
                {...basePrice.input}
                type="number"
                onWheel={(e) => e.target.blur()}
                className="form-control border-light-blue text-black py-3 fw-600"
                placeholder="Enter Base Price"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                  countDiscount(e.target.value, discount);
                }}
              />
              <p className="font-blue pt-2 mb-0">
                Set The Price Included All Taxes.
              </p>
              {basePrice.meta.touched && basePrice.meta.error && (
                <p className="text-danger">{basePrice.meta.error}</p>
              )}
              <hr className="border-gray" />
            </div>
            <div>
              <div className="row">
                <div className="col-xl-3 radio-class col-lg-6 mb-4 mb-xl-0 col-12 py-3 px-2 me-5">
                  <label className="form-check-label">
                    <input
                      {...discountType.input}
                      checked={
                        discountType.input.value == "NoDiscount" ? true : false
                      }
                      className="form-check-input"
                      name="discountType"
                      component="input"
                      type="radio"
                      value="NoDiscount"
                      onClick={() => {
                        setIsShown(false);
                        setDiscount("0");
                        countDiscount(price);
                      }}
                    />
                    <h6 className="d-inline ps-3"> No Discount</h6>
                  </label>
                </div>
                <div className="col-xl-3 radio-class col-lg-6 mb-4 mb-xl-0 col-12 py-3 px-2 me-5">
                  <label className="form-check-label">
                    <input
                      {...discountType.input}
                      checked={
                        discountType.input.value == "percentage" ? true : false
                      }
                      className="form-check-input"
                      name="discountType"
                      component="input"
                      type="radio"
                      value="percentage"
                      onClick={() => setIsShown(true)}
                    />
                    <h6 className="d-inline ps-3"> Percentage %</h6>
                  </label>
                </div>
              </div>
              {discountType.meta.touched && discountType.meta.error && (
                <p className="text-danger">{discountType.meta.error}</p>
              )}
            </div>

            {formdata?.discountPercentage || isShown ? (
              <>
                <div>
                  <label htmlFor="discount2">
                    <h5 className="text-blue pt-3">
                      {" "}
                      Set Discount Percentage(%){" "}
                    </h5>
                  </label>
                  <input
                    {...discount2.input}
                    className="form-control border-light-blue text-black py-3 fw-600"
                    placeholder="Set Discount Percentage"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                      countDiscount(price, e.target.value);
                    }}
                  />
                  <p className="font-blue pt-2 mb-0">
                    Set a percentage discount to be applied on this product.
                  </p>
                  {discount2.meta.touched && discount2.meta.error && (
                    <p className="text-danger">{discount2.meta.error}</p>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <div className="pb-3">
              <label htmlFor="discountedPrice" className="col-12">
                <h5 className="text-blue pt-3"> Discounted Price</h5>
              </label>
              <input
                readOnly={true}
                className="form-control border-light-blue text-black py-3 fw-600"
                value={discountCount}
              />
              <hr className="border-gray" />
            </div>
            <div className="row pb-3">
              <div className="col-lg-6 col-12 pe-0 pe-lg-3">
                <label htmlFor="quantity" className="col-12">
                  <h5 className="text-blue pt-3">
                    {" "}
                    {formdata ? "Available Quantity" : "Quantity"}{" "}
                  </h5>
                </label>
                <input
                  {...quantity.input}
                  value={quantityPrice}
                  onChange={(e) => {
                    setQuantityPrice(e.target.value);
                  }}
                  readOnly={formdata ? true : false}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="form-control border-light-blue text-black py-3 fw-600"
                  placeholder="Enter Quantity"
                />
                <p className="font-blue py-2 mb-0">
                  Enter the product quantity.
                </p>

                {quantity.meta.touched && quantity.meta.error && (
                  <p className="text-danger">{quantity.meta.error}</p>
                )}
              </div>

              {formdata ? (
                <>
                  <div className="col-lg-6 col-12 ps-0 ps-lg-3">
                    <label htmlFor="addQuantity" className="col-12">
                      <h5 className="text-blue pt-3">Add Quantity</h5>
                    </label>
                    <input
                      {...addQuantity.input}
                      value={addEditQuantity}
                      onChange={(e) => {
                        setAddEditQuantityPrice(e.target.value);
                      }}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control border-light-blue text-black py-3 fw-600"
                      placeholder="Enter Quanity"
                    />
                    <p className="font-blue py-2 mb-0">
                      Enter the product Quantity.
                    </p>

                    {addQuantity.meta.touched && addQuantity.meta.error && (
                      <p className="text-danger">{addQuantity.meta.error}</p>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="row pb-3">
              <h4 className="text-blue pt-3 fw-bold">Product details</h4>
              <div className="col-lg-6 col-12 pe-0 pe-lg-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="categories">
                    <h5 className="text-blue pt-3"> Parent Category</h5>
                  </label>
                  <Link to="/add-categories">
                    <button className="border-0 bg-transparent">
                      <img
                        src="assets/addbutton.svg"
                        alt="add-button"
                        className="mt-2"
                      />
                    </button>
                  </Link>
                </div>
                <select
                  {...categoriesName.input}
                  value={category}
                  className="form-select border-light-blue bg-transparent ps-2 py-3 rounded-3 w-100 text-black fw-600"
                  onChange={(e) => {
                    setcategory(e.target.value);
                  }}
                >
                  <option value="1">Men</option>
                  <option value="2">Women</option>
                  <option value="3">Child</option>
                </select>

                <p className="font-blue py-2 mb-0">Select Product Category</p>

                {categoriesName.meta.touched && categoriesName.meta.error && (
                  <p className="text-danger">{categoriesName.meta.error}</p>
                )}
              </div>
              <div className="col-lg-6 col-12 ps-0 ps-lg-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="categories">
                    <h5 className="text-blue pt-3">Category Name</h5>
                  </label>
                  <Link to="/add-categories">
                    <button className="border-0 bg-transparent">
                      <img
                        src="assets/addbutton.svg"
                        alt="add-button"
                        className="mt-2"
                      />
                    </button>
                  </Link>
                </div>
                <select
                  {...categories.input}
                  name="categories"
                  className="form-select border-light-blue bg-transparent ps-2 py-3 rounded-3 w-100 text-black fw-600"
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {categoryList?.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </select>

                <p className="font-blue py-2 mb-0">
                  Select Product Category Name.
                </p>

                {categories.meta.touched && categories.meta.error && (
                  <p className="text-danger">{categories.meta.error}</p>
                )}
              </div>
            </div>

            <div className="row">
              <h4 className="text-blue pt-3 fw-bold">Product Size </h4>

              <div className="ps-3">
                <div className="form-check form-switch">
                  <h4>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      onChange={(e) => {
                        e.target.checked ? setIsSize(true) : setIsSize(false);
                      }}
                      checked={isSize}
                    />
                  </h4>
                  <h5>
                    <label
                      className="form-check-label pt-1"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Add Size
                    </label>
                  </h5>
                </div>
                {hasSizeAvailable.meta.touched &&
                  hasSizeAvailable.meta.error && (
                    <p className="text-danger">{hasSizeAvailable.meta.error}</p>
                  )}
              </div>

              {isSize && (
                <>
                  <div>
                    <h5 className="fw-600  pt-4 text-blue">
                      Available Product Sizes
                    </h5>
                  </div>

                  <div className="d-flex flex-row justify-content-xxl-between justify-content-lg-around justify-content-md-between flex-wrap">
                    <div>
                      <label htmlFor="small-size" className="d-block pt-3">
                        <h6 className="text-blue"> Small Size </h6>
                      </label>
                      <input
                        {...s.input}
                        readOnly={formdata ? true : false}
                        className="from-control border-gray rounded-1 py-2 ps-2"
                        placeholder="0"
                      />
                      <p className="font-blue pt-2 mb-0">
                        Enter Product Quantity Size Wise
                      </p>
                      {s.meta.touched && s.meta.error && (
                        <p className="text-danger">{s.meta.error}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="medium-size" className="d-block pt-3">
                        <h6 className="text-blue"> Medium Size </h6>
                      </label>
                      <input
                        {...m.input}
                        readOnly={formdata ? true : false}
                        className="from-control border-gray rounded-1 py-2 ps-2"
                        placeholder="0"
                      />
                      <p className="font-blue pt-2 mb-0">
                        Enter Product Quantity Size Wise
                      </p>
                      {m.meta.touched && m.meta.error && (
                        <p className="text-danger">{m.meta.error}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="large-size" className="d-block pt-3">
                        <h6 className="text-blue"> Large Size </h6>
                      </label>
                      <input
                        {...l.input}
                        readOnly={formdata ? true : false}
                        className="from-control border-gray rounded-1 py-2 ps-2"
                        placeholder="0"
                      />
                      <p className="font-blue pt-2 mb-0">
                        Enter Product Quantity Size Wise
                      </p>
                      {l.meta.touched && l.meta.error && (
                        <p className="text-danger">{l.meta.error}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="xl-size" className="d-block pt-3">
                        <h6 className="text-blue"> Xl Size </h6>
                      </label>
                      <input
                        {...xl.input}
                        readOnly={formdata ? true : false}
                        className="from-control border-gray rounded-1 py-2 ps-2"
                        placeholder="0"
                      />
                      <p className="font-blue pt-2 mb-0">
                        Enter Product Quantity Size Wise
                      </p>
                      {xl.meta.touched && xl.meta.error && (
                        <p className="text-danger">{xl.meta.error}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="xxl-size" className="d-block pt-3">
                        <h6 className="text-blue"> XXl Size </h6>
                      </label>
                      <input
                        {...xxl.input}
                        readOnly={formdata ? true : false}
                        className="from-control border-gray rounded-1 py-2 ps-2"
                        placeholder="0"
                      />
                      <p className="font-blue pt-2 mb-0">
                        Enter Product Quantity Size Wise
                      </p>
                      {xxl.meta.touched && xxl.meta.error && (
                        <p className="text-danger">{xxl.meta.error}</p>
                      )}
                    </div>
                  </div>

                  {formdata ? (
                    <>
                      <div>
                        <h5 className="fw-600  pt-4 text-blue">
                          Add stock Product Sizes
                        </h5>
                      </div>
                      <div className="d-flex flex-row justify-content-xxl-between justify-content-lg-around justify-content-md-between flex-wrap">
                        <div>
                          <label htmlFor="small-size" className="d-block pt-3">
                            <h6 className="text-blue"> Add Stock Small Size</h6>
                          </label>
                          <input
                            {...addS.input}
                            className="from-control border-gray rounded-1 py-2 ps-2"
                            value={addStockProductSize.addS}
                            onChange={(e) => handleChange(e)}
                            name="addS"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                          <p className="font-blue pt-2 mb-0">
                            Enter Product Quantity Size Wise
                          </p>
                          {addS.meta.touched && addS.meta.error && (
                            <p className="text-danger">{addS.meta.error}</p>
                          )}
                        </div>
                        <div>
                          <label htmlFor="medium-size" className="d-block pt-3">
                            <h6 className="text-blue">
                              {" "}
                              Add Stock Medium Size
                            </h6>
                          </label>
                          <input
                            {...addM.input}
                            className="from-control border-gray rounded-1 py-2 ps-2"
                            value={addStockProductSize.addM}
                            onChange={(e) => handleChange(e)}
                            name="addM"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                          <p className="font-blue pt-2 mb-0">
                            Enter Product Quantity Size Wise
                          </p>
                          {addM.meta.touched && addM.meta.error && (
                            <p className="text-danger">{addM.meta.error}</p>
                          )}
                        </div>
                        <div>
                          <label htmlFor="large-size" className="d-block pt-3">
                            <h6 className="text-blue">
                              {" "}
                              Add Stock Large Size{" "}
                            </h6>
                          </label>
                          <input
                            {...addL.input}
                            className="from-control border-gray rounded-1 py-2 ps-2"
                            value={addStockProductSize.addL}
                            onChange={(e) => handleChange(e)}
                            name="addL"
                            min={0}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="0"
                          />
                          <p className="font-blue pt-2 mb-0">
                            Enter Product Quantity Size Wise
                          </p>
                          {addL.meta.touched && addL.meta.error && (
                            <p className="text-danger">{addL.meta.error}</p>
                          )}
                        </div>
                        <div>
                          <label htmlFor="xl-size" className="d-block pt-3">
                            <h6 className="text-blue"> Add Stock XL Size </h6>
                          </label>
                          <input
                            {...addXL.input}
                            className="from-control border-gray rounded-1 py-2 ps-2"
                            value={addStockProductSize.addXL}
                            onChange={(e) => handleChange(e)}
                            name="addXL"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                          <p className="font-blue pt-2 mb-0">
                            Enter Product Quantity Size Wise
                          </p>
                          {addXL.meta.touched && addXL.meta.error && (
                            <p className="text-danger">{addXL.meta.error}</p>
                          )}
                        </div>
                        <div>
                          <label htmlFor="xxl-size" className="d-block pt-3">
                            <h6 className="text-blue"> Add Stock XXL Size </h6>
                          </label>
                          <input
                            {...addXXL.input}
                            className="from-control border-gray rounded-1 py-2 ps-2"
                            value={addStockProductSize.addXXL}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleChange(e)}
                            name="addXXL"
                          />
                          <p className="font-blue pt-2 mb-0">
                            Enter Product Quantity Size Wise
                          </p>
                          {addXXL.meta.touched && addXXL.meta.error && (
                            <p className="text-danger">{addXXL.meta.error}</p>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <div>
                <div className="row pt-2">
                  <h4 className="text-blue pt-3 fw-bold">Status</h4>
                  <label htmlFor="status">
                    <h5 className="text-blue pt-3">Status</h5>
                  </label>
                  <div className="col-lg-6 pt-2">
                    <select
                      {...status.input}
                      className="form-control form-select border-light-blue text-black fw-600 py-3 w-100"
                      placeholder="Enter basePrice"
                    >
                      <option value="">Select Status</option>
                      <option value="true">Enable</option>
                      <option value="false">Disable</option>
                    </select>
                  </div>
                  <p className="font-blue pt-2 mb-0">Select Product Status</p>
                  {status.meta.touched && status.meta.error && (
                    <p className="text-danger">{status.meta.error}</p>
                  )}
                </div>
              </div>
            </div>
          </Row>
          {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
            temp && (temp.C == true || temp.U == true) ? (
              <div className="mt-5 text-lg-end text-center">
                <button
                  type="reset"
                  onClick={() => {
                    setSelectedFiles([]);
                    setSelectedFiles2([]);
                    setIsSize(null);
                    setDiscount("0");
                    setPrice(0);
                    setDiscountCount(null);
                    setcategory("1");
                    setQuantityPrice();
                    for (let keys in values) {
                      form.resetFieldState(keys);
                    }
                    form.reset();
                  }}
                  className="btn btn-light btn-lg text-secondary fw-bold me-3"
                >
                  Discard
                </button>
                {formdata ? (
                  <button
                    type="submit"
                    className="btn btn-blue btn-lg text-white fw-bold"
                  >
                    Update Product
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-blue btn-lg text-white fw-bold"
                  >
                    Add Product
                  </button>
                )}
              </div>
            ) : (
              <div></div>
            )
          ) : (
            <div className="mt-5 text-lg-end text-center">
              <button
                type="reset"
                onClick={() => {
                  setSelectedFiles([]);
                  setSelectedFiles2([]);
                  setIsSize(null);
                  setDiscount("0");
                  setDiscountCount(null);
                  setPrice(0);
                  setcategory("1");
                  setQuantityPrice();
                  for (let keys in values) {
                    form.resetFieldState(keys);
                  }
                  form.reset();
                }}
                className="btn btn-light btn-lg text-secondary fw-bold me-3"
              >
                Discard
              </button>
              <button
                type="submit"
                className="btn btn-blue btn-lg text-white fw-bold"
              >
                {formdata ? "Update Product" : "Add Product"}
              </button>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddMerchandise;

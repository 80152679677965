import React, { Component, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import { useEffect } from "react";
import {
  getAllMerchandise,
  baseUrl,
  getShippingRate,
  addShippingRate,
} from "../../Api";
import { toast, ToastContainer } from "react-toastify";

const MerchandiseList = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [merchandiseList, setMerchandiseList] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [newShippingRate, setNewShippingRate] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    allMerchandise();
    shippingRate();
  }, [sizePerPage, page, totalSize]);

  const allMerchandise = async () => {
    const data = await getAllMerchandise({ page: page, limit: sizePerPage });
    setMerchandiseList(data.data);
    setTotalSize(data.count);
  };

  const shippingRate = async () => {
    const data = await getShippingRate();

    if (data.data == null) {
      setNewShippingRate(0);
    } else {
      setNewShippingRate(data.data.amount);
    }
  };

  const handleKeyPress = async (e) => {
    let amount = newShippingRate;
    if (e.key == "Enter") {
      const data = await addShippingRate({ amount: amount });
      if (data.success == true) {
        const notify = toast.success(data.message);
        notify();
      }
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],

    page: page,
    totalSize: totalSize,
    custom: true,
  };

  let sendToAddMerchandise = (row) => {};

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <ToastContainer />
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              <DeleteModal
                deleteIndex={deleteIndex}
                allMerchandise={allMerchandise}
              />
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[
                        {
                          dataField: "srNo",
                          text: "#",
                          formatter: (cell, row, rowIndex) => {
                            if (rowIndex + 1 < 10) {
                              return "0" + (rowIndex + 1);
                            } else {
                              return rowIndex + 1;
                            }
                          },
                        },
                        {
                          dataField: "productName",
                          text: "Name",
                          classes: "items-center",
                          formatter: (cell, row) => (
                            <div className="d-flex flex-row">
                              <div>
                                {row?.thumbnail == null ? (
                                  <img
                                    src="assets/Avatar.png"
                                    className="width-50"
                                  />
                                ) : (
                                  <img
                                    src={`${baseUrl}uploads/${row?.thumbnail}`}
                                    className="width-50"
                                  />
                                )}
                              </div>
                              <div className="ps-2 align-self-center">
                                <span className="fw-bold"> {row?.name} </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          dataField: "category",
                          text: "Category",
                          formatter: (cell, row) => {
                            return cell.name;
                          },
                        },
                        {
                          dataField: "quantity",
                          text: "Quantity",
                        },
                        {
                          dataField: "discountedPrice",
                          text: "Discounted Price",
                        },
                        {
                          dataField: "status",
                          text: "Status",
                          formatter: (cell, row) => {
                            return cell == true ? (
                              <button className="btn-success-green border-0 py-2 px-3 rounded">
                                Active
                              </button>
                            ) : (
                              <button className="btn-danger-red border-0 py-2 px-3 rounded">
                                Inactive
                              </button>
                            );
                          },
                        },
                        JSON.parse(localStorage.getItem("authUser")).roleId == 5
                          ? temp && (temp.D == true || temp.U == true)
                            ? {
                                dataField: "",
                                text: "Action",
                                formatter: (cell, row) => {
                                  return (
                                    <div>
                                      <div className="btn-group">
                                        {temp && temp.U == true ? (
                                          <button
                                            onClick={() => {
                                              sendToAddMerchandise(row);
                                            }}
                                            className="border-0 me-3 py-2 px-2 rounded"
                                          >
                                            <Link
                                              to="/Edit-Merchandise"
                                              state={{ merchandise: row }}
                                            >
                                              <img
                                                src="assets/edit.svg"
                                                alt="edit"
                                              />
                                            </Link>
                                          </button>
                                        ) : (
                                          <></>
                                        )}

                                        {temp && temp.D == true ? (
                                          <button
                                            className="border-0  py-2 px-2 rounded"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() =>
                                              setDeleteIndex({
                                                isMerchandise: true,
                                                index: row?.id,
                                              })
                                            }
                                          >
                                            <img
                                              src="assets/delete.svg"
                                              alt="delete"
                                            />
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                              }
                            : {}
                          : {
                              dataField: "",
                              text: "Action",
                              formatter: (cell, row) => {
                                return (
                                  <div>
                                    <div className="btn-group">
                                      <>
                                        <button
                                          onClick={() => {
                                            sendToAddMerchandise(row);
                                          }}
                                          className="border-0 me-3 py-2 px-2 rounded"
                                        >
                                          <Link
                                            to="/Edit-Merchandise"
                                            state={{ merchandise: row }}
                                          >
                                            <img
                                              src="assets/edit.svg"
                                              alt="edit"
                                            />
                                          </Link>
                                        </button>

                                        <button
                                          className="border-0  py-2 px-2 rounded"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          onClick={() =>
                                            setDeleteIndex({
                                              isMerchandise: true,
                                              index: row?.id,
                                            })
                                          }
                                        >
                                          <img
                                            src="assets/delete.svg"
                                            alt="delete"
                                          />
                                        </button>
                                      </>
                                    </div>
                                  </div>
                                );
                              },
                            },
                      ]}
                      data={merchandiseList}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              Merchandise List
                            </h3>
                            <Row>
                              <Col xxl="6" sm="12" className="text-start">
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xxl="6"
                                sm="12"
                                className="text-xxl-end text-start"
                              >
                                <div className="pt-4">
                                  <label>
                                    <h5 className="fw-600 fs-6">
                                      {" "}
                                      Set Shipping Rate
                                    </h5>
                                  </label>
                                  <input
                                    className="ms-1 py-2 text_input2 ps-2 bg-transparent border-end-0 right_prefix"
                                    value="$"
                                  />
                                  <input
                                    type="text"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    value={newShippingRate}
                                    onChange={(e) =>
                                      setNewShippingRate(e.target.value)
                                    }
                                    className="text_input bg-transparent prefix_right py-2 border-start-0 ps-1"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 bg-white rounded-3 px-4">
                                <h4 className="py-4 text-blue fw-600">
                                  Merchandise List
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={function () {}}
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light text-grey-light"
                                        }
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h6 className="text-blue fw-600 ps-2">
                                {merchandiseList.length == 0
                                  ? "Showing 0"
                                  : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                              </h6>

                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <DeleteModal
            deleteIndex={deleteIndex}
            allMerchandise={allMerchandise}
          />
          {
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={[
                    {
                      dataField: "srNo",
                      text: "#",
                      formatter: (cell, row, rowIndex) => {
                        if (rowIndex + 1 < 10) {
                          return "0" + (rowIndex + 1);
                        } else {
                          return rowIndex + 1;
                        }
                      },
                    },
                    {
                      dataField: "productName",
                      text: "Name",
                      classes: "items-center",
                      formatter: (cell, row) => (
                        <div className="d-flex flex-row">
                          <div>
                            {row?.thumbnail == null ? (
                              <img
                                src="assets/Avatar.png"
                                className="width-50"
                              />
                            ) : (
                              <img
                                src={`${baseUrl}uploads/${row?.thumbnail}`}
                                className="width-50"
                              />
                            )}
                          </div>
                          <div className="ps-2 align-self-center">
                            <span className="fw-bold"> {row?.name} </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      dataField: "category",
                      text: "Category",
                      formatter: (cell, row) => {
                        return cell.name;
                      },
                    },
                    {
                      dataField: "quantity",
                      text: "Quantity",
                    },
                    {
                      dataField: "discountedPrice",
                      text: "Discounted Price",
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (cell, row) => {
                        return cell == true ? (
                          <button className="btn-success-green border-0 py-2 px-3 rounded">
                            Active
                          </button>
                        ) : (
                          <button className="btn-danger-red border-0 py-2 px-3 rounded">
                            Inactive
                          </button>
                        );
                      },
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (cell, row) => {
                        return (
                          <div>
                            <div className="btn-group">
                              <button
                                onClick={() => {
                                  sendToAddMerchandise(row);
                                }}
                                className="border-0 me-3 py-2 px-2 rounded"
                              >
                                <Link
                                  to="/Edit-Merchandise"
                                  state={{ merchandise: row }}
                                >
                                  <img src="assets/edit.svg" alt="edit" />
                                </Link>
                              </button>

                              <button
                                className="border-0  py-2 px-2 rounded"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() =>
                                  setDeleteIndex({
                                    isMerchandise: true,
                                    index: row?.id,
                                  })
                                }
                              >
                                <img src="assets/delete.svg" alt="delete" />
                              </button>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                  data={merchandiseList}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <div className="container-fluid page-content bg-light-blue px-lg-3">
                        <h3 className="text-black fw-bold text-uppercase pt-3">
                          Merchandise List
                        </h3>
                        <Row>
                          <Col xxl="6" sm="12" className="text-start">
                            <div className="pt-4">
                              <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                Show
                              </p>
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  className=""
                                  {...paginationProps}
                                />
                              </div>

                              <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                Entries
                              </p>
                            </div>
                          </Col>
                          <Col
                            xxl="6"
                            sm="12"
                            className="text-xxl-end text-start"
                          >
                            <div className="pt-4">
                              <label>
                                <h5 className="fw-600 fs-6">
                                  {" "}
                                  Set Shipping Rate
                                </h5>
                              </label>
                              <input
                                className="ms-1 py-2 text_input2 ps-2 bg-transparent border-end-0 right_prefix"
                                value="$"
                              />
                              <input
                                type="text"
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={newShippingRate}
                                onChange={(e) =>
                                  setNewShippingRate(e.target.value)
                                }
                                className="text_input bg-transparent prefix_right py-2 border-start-0 ps-1"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-3 bg-white rounded-3 px-4">
                            <h4 className="py-4 text-blue fw-600">
                              Merchandise List
                            </h4>
                            <Row>
                              <div className="table-responsive">
                                <div>
                                  <BootstrapTable
                                    remote
                                    onTableChange={function () {}}
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={
                                      "thead-light text-grey-light"
                                    }
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>

                        <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                          <h6 className="text-blue fw-600 ps-2">
                            {merchandiseList.length == 0
                              ? "Showing 0"
                              : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                          </h6>

                          <div className="ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          }
        </>
      )}
    </React.Fragment>
  );
};

export default MerchandiseList;

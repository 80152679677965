import React from "react";

const Terms = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light py-xxl-4 bg-white">
        <div className="container-fluid px-xxl-5 px-3">
          <div className="ps-0 ps-md-5">
            <a href="/#/">
              <img
                src="assets/images/mainlogo.png"
                alt="logo"
                className="img-fluid width-100"
              />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ps-xl-5">
                <a className="nav-link nav-links d-inline-block" href="##home">
                  Home
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a className="nav-link nav-links d-inline-block" href="##about">
                  About
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a className="nav-link nav-links d-inline-block" href="##blog">
                  Blog
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a
                  className="nav-link nav-links d-inline-block"
                  href="##feature"
                >
                  Feature
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a
                  className="nav-link nav-links d-inline-block"
                  href="##contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="d-lg-flex d-block ps-0">
              {/* <li className="nav-item dropdown list-unstyled pe-xxl-3">
                <a
                  className="nav-link dropdown-toggle text-dark fs-5 fw-bold ps-0"
                  href="##"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  En <i className="fa-solid fa-angle-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="##">
                      Language 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="##">
                      Language 2
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item list-unstyled pe-xxl-5">
                <a
                  className="nav-link d-inline-block text-dark fs-5 fw-bold ps-0"
                  href="##"
                >
                  <img
                    src="assets/images/contact-vector.png"
                    alt="contact-vector"
                    className="img-fluid"
                  />
                  My Account
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="bg-light-blue text-info-blue fw-bold py-5 text-center">
        <h1 className="py-5">Terms And Condition</h1>
      </div>
      <div className="container pt-3">
        <p className="pt-5">
          By downloading, browsing, accessing, or using this Get Hype
          Application (“Get Hype Mobile Application”), you agree to be bound by
          these Terms and Conditions of Use. We reserve the right to amend these
          terms and conditions at any time. If you disagree with any of these
          Terms and Conditions of Use, you must immediately discontinue your
          access to the Get Hype Mobile Application and your use of the services
          offered on the Get Hype Mobile Application. Continued use of the Get
          Hype Mobile Application will constitute acceptance of these Terms and
          Conditions of Use, as may be amended from time to time.
        </p>

        <h5 className="pt-2 text-info-blue">TERMS TO USE</h5>

        <p className="pt-1">
          Get Hype LLC is committed to ensuring that the app is as useful and
          efficient as possible. For that reason, we reserve the right to make
          changes to the app or to charge for its services, at any time and for
          any reason. We will never charge you for the app or its services
          without making it very clear to you exactly what you’re paying for.
        </p>
        <ul>
          <li>
            <p>
              {" "}
              The Get Hype app stores and processes personal data that you have
              provided to us, in order to provide Get Hype LLC Service. It’s
              your responsibility to keep your phone and access to the app
              secure. We therefore recommend that you do not jailbreak or root
              your phone, which is the process of removing software restrictions
              and limitations imposed by the official operating system of your
              device. It could make your phone vulnerable to
              malware/viruses/malicious programs, compromise your phone’s
              security features and it could mean that the Get Hype app won’t
              work properly or at all.
            </p>
          </li>
          <li>
            <p>
              {" "}
              Get Hype LLC cannot always take responsibility for the way you use
              the app i.e. You need to make sure that your device stays charged
              – if it runs out of battery and you can’t turn it on to avail the
              Service, Get Hype LLC cannot accept responsibility.
            </p>
          </li>
          <li>
            <p>
              {" "}
              At some point, we may wish to update the app. The app is available
              for Android and iOS– the requirements for the system (and for any
              additional systems we decide to extend the availability of the app
              to) may change, and you’ll need to download the updates if you
              want to keep using the app. It is your responsibility to keep the
              app version up to date. Get Hype LLC does not promise that it will
              always update the app so that it is relevant to you and/or works
              with the version that you have installed on your device. However,
              you promise to always accept updates to the application when
              offered to you, we may also wish to stop providing the app, and
              may terminate use of it at any time without giving notice of
              termination to you
            </p>
          </li>
          <li>
            <p>
              {" "}
              We strongly recommend that you only download the Get Hype
              applications from Play Store or App Store. Doing so will ensure
              that your apps are legitimate and safe from malicious software.
            </p>
          </li>
        </ul>

        <h5 className="pt-2 text-info-blue">GENERAL TERMS</h5>
        <ul>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray">
                {" "}
                License Grant;{" "}
              </h6>{" "}
              Compliance with Terms of Use. The Application is licensed, not
              sold, and Get Hype LLC reserves all rights not expressly granted
              in this Agreement. Subject to the terms and conditions hereof, Get
              Hype LLC grants you a personal, nonexclusive, non-transferable,
              non-sublicensable, limited license to download and use the
              Application on a mobile device that you own or control
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray">
                {" "}
                License Restrictions.{" "}
              </h6>{" "}
              Except as specifically provided herein, You may not: (i)
              distribute or make the Application available over a network where
              it could be used by multiple devices at the same time; (ii) copy
              the Application; (iii) modify, adapt, translate, reverse engineer,
              make alterations, decompile, disassemble or make derivative works
              based on the Application, except as otherwise permitted by law; or
              (iv) use, rent, loan, sub-license, lease, distribute or attempt to
              grant other rights to the Application to third parties.
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray">
                {" "}
                Your Warranty to Get Hype LLC.
              </h6>{" "}
              You represent and warrant that: (i) You have the authority to bind
              Yourself to this Agreement; (ii) Your use of the Application will
              be solely for purposes that are permitted by this Agreement; and
              (iii) Your use of the Application will comply with all local and
              national and laws, rules, and regulations (“Laws”).
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray"> Privacy.</h6> By using
              the Application, you agree that Get Hype LLC may collect and use
              certain information about you, your mobile device, your use of the
              Application and the Application’s performance in accordance with
              the Privacy Policy, as may be amended from time to time.
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray"> Indemnity. </h6> You
              agree to indemnify and hold harmless Get Hype LLC and its
              affiliates, officers, directors, employees, consultants, agents
              and anyone providing information or software used in the
              Application from any and all claims arising from, related to, or
              incidental to Your use of the Application.
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray">
                {" "}
                Termination.{" "}
              </h6>{" "}
              This Agreement is effective until terminated. Get Hype LLC may
              immediately terminate this Agreement at any time at its sole
              discretion with or without notice to you. Additionally, Your
              rights under this Agreement will terminate automatically if you
              fail to comply with any term(s) of this Agreement. Upon
              termination, all legal rights and licenses granted to You
              hereunder shall terminate immediately and You shall cease all use
              of the Application and destroy all copies of the Application. All
              sections that may be reasonably interpreted to or are intended to
              survive this Agreement will survive this Agreement.
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray"> Severability.</h6> You
              agree that the terms and conditions stated in this Agreement are
              severable. If any paragraph, provision, or clause in this
              Agreement shall be found or be held to be invalid or unenforceable
              in any jurisdiction, the remainder of this agreement shall be
              valid and enforceable.
            </p>
          </li>
          <li>
            <p>
              {" "}
              <h6 className="fw-bold d-inline text-gray">
                {" "}
                Assignment and Transfer.
              </h6>{" "}
              Get Hype LLC may assign, transfer, sell, rent or lend this
              Agreement, in whole or in part, at any time without notice to You.
              You may not assign this Agreement or any part of it or any rights
              to use the Application, in whole or in part, either temporarily or
              permanently, to any other party. Any attempt to do so is void.
            </p>
          </li>
        </ul>

        <h5 className="pt-2 text-info-blue">
          REGISTRATION AND ACCOUNT SECURITY
        </h5>

        <p className="pt-2">
          Our app users provide their real names and information, and we need
          your help to keep it that way. Here are some commitments you make to
          us relating to registering and maintaining the security of your
          account: 1. You will not provide any false personal information on our
          app, or create an account for anyone other than yourself without
          permission. 2. You will not create more than one personal account. 3.
          If we disable your account, you will not create another one without
          our permission. 4. You will not use our app if you are under 5. 5. You
          will not use our app if you are a convicted sex offender. 6. You will
          keep your contact information accurate and up-to-date. 7. You will not
          share your password (or in the case of developers, your secret key),
          let anyone else access your account, or do anything else that might
          jeopardize the security of your account. SUBSCRIPTIONS Our services
          are available on a subscription basis (“Subscription(s)”). You will be
          billed in advance on a recurring and periodic basis (“Billing Cycle”).
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions. You may cancel your
          Subscription renewal from your Google Play or App Store account or by
          contacting the customer support team. A valid payment method,
          including a credit card, is required to process the payment for your
          Subscription. You shall provide accurate and complete billing
          information including full name, address, state, zip code, telephone
          number, and valid payment method information. By submitting such
          payment information, you are automatically authorized to charge all
          Subscription fees incurred through your account to any such payment
          instruments.
        </p>

        <h5 className="pt-2 text-info-blue">SUBSCRIPTIONS</h5>

        <p className="pt-2">
          Our services available on a subscription basis (“Subscription(s)”).
          You will be billed in advance on a recurring and periodic basis
          (“Billing Cycle”).
        </p>
        <p className="pt-2">
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions. You may cancel your
          Subscription renewal from your Google Play or App Store account or by
          contacting the customer support team.
        </p>
        <p className="pt-2">
          A valid payment method, including a credit card, is required to
          process the payment for your Subscription. You shall provide with
          accurate and complete billing information including full name,
          address, state, zip code, telephone number, and valid payment method
          information. By submitting such payment information, you are
          automatically authorized to charge all Subscription fees incurred
          through your account to any such payment instruments.
        </p>
        <h5 className="pt-2 text-info-blue">FEE CHANGES</h5>

        <p className="pt-2">
          We at our sole discretion and at any time, may modify the Subscription
          fees for the Subscriptions. Any Subscription fee change will become
          effective at the end of the then-current Billing Cycle. We will
          provide you with reasonable prior notice of any change in Subscription
          fees to give you an opportunity to terminate your Subscription before
          such change becomes effective. Your continued use of the Service after
          the Subscription fee change comes into effect constitutes your
          agreement to pay the modified Subscription fee amount. REFUNDS Certain
          refund requests for Subscriptions may be considered by us on a
          case-by-case basis and granted at our sole discretion.
        </p>

        <h5 className="pt-2 text-info-blue">REFUNDS</h5>

        <p className="pt-2">
          Certain refund requests for Subscriptions may be considered by us on a
          case-by-case basis and granted at our sole discretion of us.
        </p>
        <h5 className="pt-2 text-info-blue">
          DISCLAIMER AND EXCLUSION OF LIABILITY
        </h5>

        <ul>
          <li>
            <p>
              The Get Hype Mobile Application, the Services, the information on
              the Get Hype Mobile Application, and the use of all related
              facilities are provided on an “as is, as available” basis without
              any warranties whether express or implied.
            </p>
          </li>
          <li>
            <p>
              To the fullest extent permitted by applicable law, we disclaim all
              representations and warranties relating to the Get Hype Mobile
              Application and its contents, including in relation to any
              inaccuracies or omissions in the Get Hype Mobile Application,
              warranties of merchantability, quality, fitness for a particular
              purpose, accuracy, availability, non-infringement or implied
              warranties from course of dealing or usage of trade.
            </p>
          </li>
          <li>
            <p>
              We do not warrant that the Get Hype Mobile Application will always
              be accessible, uninterrupted, timely, secure, error free or free
              from computer virus or other invasive or damaging code or that the
              Get Hype Mobile Application will not be affected by any acts of
              God or other force majeure events, including inability to obtain
              or shortage of necessary materials, equipment facilities, power or
              telecommunications, lack of telecommunications equipment or
              facilities and failure of information technology or
              telecommunications equipment or facilities.
            </p>
          </li>
          <li>
            <p>
              While we may use reasonable efforts to include accurate and
              up-to-date information on the Get Hype Mobile Application, we make
              no warranties or representations as to its accuracy, timeliness,
              or completeness.
            </p>
          </li>
          <li>
            <p>
              We shall not be liable for any acts or omissions of any third
              parties howsoever caused, and for any direct, indirect,
              incidental, special, consequential or punitive damages, howsoever
              caused, resulting from or in connection with the Get Hype Mobile
              Application and the services offered in the Get Hype Mobile
              Application, your access to, use of or inability to use the Get
              Hype Mobile Application or the services offered in the Get Hype
              Mobile Application, reliance on or downloading from the Get Hype
              Mobile Application and/or services, or any delays, inaccuracies in
              the information or in its transmission including but not limited
              to damages for loss of business or profits, use, data or other
              intangible, even if we have been advised of the possibility of
              such damages.
            </p>
          </li>
          <li>
            <p>
              We shall not be liable in contract, tort (including negligence or
              breach of statutory duty) or otherwise howsoever and whatever the
              cause thereof, for any indirect, consequential, collateral,
              special or incidental loss or damage suffered or incurred by you
              in connection with the Get Hype Mobile Application and these Terms
              and Conditions of Use. For the purposes of these Terms and
              Conditions of Use, indirect or consequential loss or damage
              includes, without limitation, loss of revenue, profits,
              anticipated savings or business, loss of data or goodwill, loss of
              use or value of any equipment including software, claims of third
              parties, and all associated and incidental costs and expenses.
            </p>
          </li>
          <li>
            <p>
              The above exclusions and limitations apply only to the extent
              permitted by law. None of your statutory rights as a consumer that
              cannot be excluded or limited are affected.
            </p>
          </li>
        </ul>

        <h5 className="pt-2 text-info-blue">INTELLECTUAL PROPERTY RIGHTS</h5>
        <ul>
          <li>
            <p>
              All editorial content, information, photographs, illustrations,
              artwork and other graphic materials, and names, logos and
              trademarks on the Get Hype Mobile Application are protected by
              copyright laws and/or other laws and/or international treaties,
              and belong to us and/or our suppliers, as the case may be. These
              works, logos, graphics, sounds or images may not be copied,
              reproduced, retransmitted, distributed, disseminated, sold,
              published, broadcasted or circulated whether in whole or in part,
              unless expressly permitted by us and/or our suppliers, as the case
              may be.
            </p>
          </li>
          <li>
            <p>
              Nothing contained on the Get Hype Mobile Application should be
              construed as granting by implication, estoppel, or otherwise, any
              license or right to use any trademark displayed on the Get Hype
              Mobile Application without our written permission. Misuse of any
              trademarks or any other content displayed on the Get Hype Mobile
              Application is prohibited.
            </p>
          </li>
          <li>
            <p>
              We will not hesitate to take legal action against any unauthorized
              usage of our trademarks, name, or symbols to preserve and protect
              its rights in the matter. All rights not expressly granted herein
              are reserved. Other product and company names mentioned herein may
              also be the trademarks of their respective owners.
            </p>
          </li>
        </ul>

        <h5 className="pt-2 text-info-blue">COPYRIGHT INFRINGEMENTS</h5>
        <p className="pt-2">
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the App infringes upon any
          copyright you own or control, please immediately notify us using the
          contact information provided below (a “Notification”). A copy of your
          Notification will be sent to the person who posted or stored the
          material addressed in the Notification. Please be advised that
          pursuant to federal law you may be held liable for damages if you make
          material misrepresentations in a Notification. Thus, if you are not
          sure that material located on or linked to by the App infringes your
          copyright, you should consider first contacting an attorney.
        </p>

        <h5 className="pt-2 text-info-blue">
          CHANGES TO THESE TERMS AND CONDITIONS
        </h5>
        <p className="pt-2">
          I may update our Terms and Conditions from time to time. Thus, you are
          advised to review this page periodically for any changes. I will
          notify you of any changes by posting the new Terms and Conditions on
          this page. These changes are effective immediately after they are
          posted on this page.
        </p>

        <p className="pt-2">
          {" "}
          <h6 className="fw-bold d-inline text-gray"> CONTACT US</h6> If you
          have any questions, do not hesitate to contact us at
        </p>

        <p>
          <a href="mailto:leanna@get-hype-app.com">leanna@get-hype-app.com</a>
        </p>
      </div>
    </div>
  );
};

export default Terms;

import React from "react";
import { Col, Row, Card, Button, CardTitle, CardText } from "reactstrap";
import Breadcrumbs from "../../components/common/Breadcrumbs";

const ViewShippingDetail = (props) => {

  return (
    <div className="bg-light-blue page-content pb-5">
      <Breadcrumbs name={"Shipping Details"} />
      <Row className="px-3">
        <Col xxl={4} xl={12} className="pt-3 pt-xxl-0">
          <Card body className="border-0 shadow">
            <h5 className="text-blue fw-bold">Order Details (#14534)</h5>
            
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/datepicker.svg" alt="datepickter" className="pe-4" />
             <span className="text-gray fs-5"> Date Added </span></p>
              </div>
              <p className="text-gray fs-5">13/07/2022</p>
              
            </div>
            <hr className="border-gray" />
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/paymentMethod.svg" alt="datepickter" className="pe-4" />
             <span className="text-gray fs-5"> Payment Method </span></p>
              </div>
              <p className="text-gray fs-5">Online <img src="assets/visatext.svg" alt="visa" /></p>
              
            </div>
            <hr className="border-gray" />
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/shippingMethod.svg" alt="shipping method" className="pe-4" />
             <span className="text-gray fs-5"> Shipping Method </span></p>
              </div>
              <p className="text-gray fs-5">Flat Shipping Rate</p>
              
            </div>
            <hr className="border-gray" />
          </Card>
        </Col>
        <Col xxl={4} xl={12} className="ps-xxl-3 ps-0 pt-3 pt-xxl-0">
          <Card body className="border-0 shadow">
            <h5 className="text-blue fw-bold">Customer Details</h5>
            
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/contact.svg" alt="customer" className="pe-4" />
             <span className="text-gray fs-5"> Customer </span></p>
              </div>
              <p className="text-gray fs-5"> <img src="assets/danwilson.svg" alt="customer" /> Dan Wilson</p>
              
            </div>
            <hr className="border-gray" />
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/email.svg" alt="datepickter" className="pe-4" />
             <span className="text-gray fs-5"> Email </span></p>
              </div>
              <p className="text-gray fs-5">dam@consilting.com</p>
              
            </div>
            <hr className="border-gray" />
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/phone.svg" alt="phone" className="pe-4" />
             <span className="text-gray fs-5"> Phone </span></p>
              </div>
              <p className="text-gray fs-5">+6141 234 567</p>
              
            </div>
            <hr className="border-gray" />
          </Card>
        </Col>
        <Col xxl={4} xl={12} className="ps-xxl-3 ps-0 pt-3 pt-xxl-0">
          <Card body className="border-0 shadow pb-5">
            <h5 className="text-blue fw-bold">Documents</h5>
            
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/invoice.svg" alt="invoice" className="pe-4" />
             <span className="text-gray fs-5"> Invoice </span></p>
              </div>
              <p className="text-gray fs-5">#INV-000414</p>
              
            </div>
            <hr className="border-gray" />
            <div className="d-md-flex d-block justify-content-between text-center pt-2">
            <div>
              <p><img src="assets/paymentMethod.svg" alt="datepickter" className="pe-4" />
             <span className="text-gray fs-5"> Shipping </span></p>
              </div>
              <p className="text-gray fs-5">#SHP-0058001</p>
              
            </div>
            <hr className="border-gray mb-5"/>
            <div className="pb-4"></div>
          </Card>
        </Col>
        <Col xxl={3} xl={12} className="ps-0 pt-3">
          <Card body className="border-0 shadow text-center text-md-start">
            <h5 className="text-blue fw-bold pb-2">Shipping Address</h5>
            <h6>Unit 1/23 Hastings Road,</h6>
            <h6>Melbourne 3000,</h6>
            <h6>Victoria</h6>
            <h6>Australia.</h6>



          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewShippingDetail;

import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";

// Table data

const products = [
  {
    id: 1,
    customer: "Annette Black",
    status: true,
    paymentDetails: "paypal",
    product: "Premium",
    date: "12/9/2022",
    sportUserPlay: "Football",
    age: "27",
  },
  {
    id: 2,
    customer: "Bessie Cooper",
    status: false,
    paymentDetails: "visa",
    product: "Premium",
    date: "12/9/2022",
    sportUserPlay: "Basketball",
    age: "26",
  },
  {
    id: 3,
    customer: "Floyd Miles",
    status: true,
    paymentDetails: "mastercard",
    product: "Premium",
    date: "12/9/2022",
    sportUserPlay: "Baseball",
    age: "25",
  },
  {
    id: 4,
    customer: "Darrell Steward",
    status: false,
    paymentDetails: "visa",
    product: "Premium",
    date: "12/9/2022",
    sportUserPlay: "Soccer",
    age: "23",
  },
];

const products2 = [
  {
    id: 1,
    customer: "Annette Black",
    cateory: "T-Shirts",
    paymentDetails: "paypal",
    product: "T-Shirts",
    date: "12/9/2022",
  },
  {
    id: 2,
    customer: "Bessie Cooper",
    cateory: "shoes",
    paymentDetails: "visa",
    product: "Sport shoes",
    date: "12/9/2022",
  },
  {
    id: 3,
    customer: "Floyd Miles",
    cateory: "Shirts",
    paymentDetails: "mastercard",
    product: "Red cep",
    date: "12/9/2022",
  },
  {
    id: 4,
    customer: "Darrell Steward",
    cateory: "Track Pants",
    paymentDetails: "visa",
    product: "Red cep",
    date: "12/9/2022",
  },
];

const BillingPayment = (props) => {
  const [productData, setProductData] = useState(products);
  const [productData2, setProductData2] = useState(products2);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [billingPayment, setBillingPayment] = useState("merchandise");

  const changeSelectValue = (e) => {
  };

  useEffect(() => {
  }, [billingPayment]);

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "customer",
      text: "Customer",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        return row?.status == true ? (
          <button className="btn-success-green border-0 py-2 px-3 rounded">
            Active
          </button>
        ) : (
          <button className="btn-danger-red border-0 py-2 px-3 rounded">
            Inactive
          </button>
        );
      },
    },
    {
      dataField: "paymentDetails",
      text: "Payment Details",
      formatter: (cell, row) => {
        if (row?.paymentDetails == "visa") {
          return (
            <div>
              <img src="assets/visa.svg" alt="visa" /> Visa
            </div>
          );
        } else if (row?.paymentDetails == "mastercard") {
          return (
            <div>
              <img src="assets/mastercard.svg" alt="mastercard" />
              MasterCard
            </div>
          );
        } else if (row?.paymentDetails == "paypal") {
          return (
            <div>
              <img src="assets/paypal.svg" alt="paypal" />
              Paypal
            </div>
          );
        }
      },
    },
    {
      dataField: "product",
      text: "Product",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
            <button className="border-0 py-2 px-2 rounded">
              <img src="assets/view.svg" alt="view" />
            </button>
          </div>
        );
      },
    },
  ];
  
  const columns2 = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "customer",
      text: "Customer",
    },
    {
      dataField: "cateory",
      text: "Category",
    },
    {
      dataField: "paymentDetails",
      text: "Payment Details",
      formatter: (cell, row) => {
        if (row?.paymentDetails == "visa") {
          return (
            <div>
              <img src="assets/visa.svg" alt="visa" /> Visa
            </div>
          );
        } else if (row?.paymentDetails == "mastercard") {
          return (
            <div>
              <img src="assets/mastercard.svg" alt="mastercard" />
              MasterCard
            </div>
          );
        } else if (row?.paymentDetails == "paypal") {
          return (
            <div>
              <img src="assets/paypal.svg" alt="paypal" />
              Paypal
            </div>
          );
        }
      },
    },
    {
      dataField: "product",
      text: "Product",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
            <button className="border-0 py-2 px-2 rounded">
              <img src="assets/view.svg" alt="view" />
            </button>
          </div>
        );
      },
    },
  ];


  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: products.length,
    custom: true,
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {billingPayment == "premium" ? 
      <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={[...columns]}
              data={[...productData]}
              search
            >
              {(toolkitprops) => (
                <React.Fragment>
                  <div className="container-fluid page-content pt-4 text-center text-lg-start bg-light-blue px-lg-3">
                    <h3 className="text-black fw-bold text-uppercase d-inline">
                    Billing Details </h3> <span className="text-gray fs-4 text-uppercase"> - {billingPayment}</span>
                    
                    <Row>
                      <Col lg="6" md="12" className="text-center text-lg-start">
                        <div className="pt-4">
                          <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              className=""
                              {...paginationProps}
                            />
                          </div>

                          <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                        </div>
                      </Col>
                      <Col lg="6" md="12" className="text-center text-lg-end">
                        <div className="pt-4">
                          <select
                            value={billingPayment}
                            onChange={changeSelectValue}
                            className="border-gray form ps-2 py-2 w-50 rounded-3"
                          >
                            
                            <option value="premium">
                            Premium
                            </option>
                            <option value="merchandise">Merchandise</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 rounded-3 bg-white px-4">
                        <h4 className="py-4 text-blue fw-600">
                        Billing Details
                        </h4>
                        <Row>
                          <div className="table-responsive">
                            <div>
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                data={productData}
                                columns={columns}
                                striped={false}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light text-grey-light"}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                      <h5>Showing 1 - 10 of 350</h5>

                      <div className="ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider> : 
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={[...columns2]}
              data={[...productData2]}
              search
            >
              {(toolkitprops) => (
                <React.Fragment>
                  <div className="container-fluid page-content pt-4 text-center text-lg-start bg-light-blue px-lg-3">
                    <h3 className="text-black fw-bold text-uppercase d-inline">
                    Billing Details </h3><span className="text-gray fs-4 text-uppercase"> - {billingPayment}</span>
                    
                    <Row>
                      <Col lg="6" md="12" className="text-center text-lg-start">
                        <div className="pt-4">
                          <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              className=""
                              {...paginationProps}
                            />
                          </div>

                          <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                        </div>
                      </Col>
                      <Col lg="6" md="12" className="text-center text-lg-end">
                        <div className="pt-4">
                        <select
                            value={billingPayment}
                            onChange={changeSelectValue}
                            className="border-gray ps-2 py-2 w-50 rounded-3"
                          >
                            
                            <option value="premium">
                            Premium
                            </option>
                            <option value="merchandise">Merchandise</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 rounded-3 bg-white px-4">
                        <h4 className="py-4 text-blue fw-600">
                        Billing Details
                        </h4>
                        <Row>
                          <div className="table-responsive">
                            <div>
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                data={products2}
                                columns={columns2}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light text-grey-light"}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                      <h5>Showing 1 - 10 of 350</h5>

                      <div className="ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>}
      
        
    
    </React.Fragment>
  );
};

export default BillingPayment;

import React from "react";
import {
  deleteChallenges,
  deleteGames,
  deleteMotivationalSongs,
  deleteReferedTrack,
  deleteUser,
  disableMerchandise,
  disableRefund,
  disableUser,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteModal = (props) => {
  const navigate = useNavigate();
  const disabledUser = async () => {
    if (props.deleteIndex.isDelete == true) {
      let id = props.deleteIndex.index;
      const data = await deleteGames(id);
      if (data.success == true) {
        props.allGames();
      }
    } else if (props.deleteIndex.isDelete == false) {
      
      let values = { id: props.deleteIndex.index, status: props?.deleteIndex?.data?.status == true ? 0 : 1 };
      const data = await disableUser(values);
      if (data.success == true) {
        props.allUser();
      }
    } else if (props.deleteIndex.isUserTrack == true) {
      let id = props.deleteIndex.index;
      const data = await deleteReferedTrack(id);
      if (data.success == true) {
        props.allUserTrack();
      }
    } else if (props.deleteIndex.isMerchandise == true) {
      let formData = new FormData();
      formData.append("id", props.deleteIndex.index);
      formData.append("isDeleted", 1);
      const data = await disableMerchandise(formData);
      if (data.success == true) {
        props.allMerchandise();
      }
    } else if (props.deleteIndex.isRefund == true) {
      let temp = {
        id: props.deleteIndex.index,
        isRefunded: true,
      };
      const data = await disableRefund(temp);
      if (data.success == true) {
        props.allMerchandiseRefund();
      }
    } else if (props.deleteIndex.isChallenge == true) {
      let id = props.deleteIndex.index;
      const data = await deleteChallenges(id);
      if (data.success == true) {
        props.allChallenge();
      }
    } else if (props.deleteIndex.isDeleteUser == true) {
      let id = props.deleteIndex.index;
      const data = await deleteUser(id);
      if (data.success == true) {
        navigate("/Challenges-Report");
      } else if (data.response.status == 400) {
        const errorNotify = () => toast.error(data?.response?.data?.send);
        errorNotify();
      }
    }  else if (props.deleteIndex.isMedia == true) {


    
      let id = props.deleteIndex.index;
      const data = await deleteMotivationalSongs({id: id});
      if (data.success == true) {
        props.allMedia();
      }
    } 
  };
  return (
    <div>
    <ToastContainer />
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <img
                src="assets/deletemodal.svg"
                alt="deletemodal"
                className="img-fluid d-block mx-auto"
              />
            </div>
            <div className="modal-body text-center border-0">
              {props.deleteIndex.isDelete == false && (
                <>
                {props?.deleteIndex?.data?.status == false ? <h5 className="text-black px-5">
                    Are You Sure You Want to Enable This User?
                  </h5> : <h5 className="text-black px-5">
                    Are You Sure You Want to Disable This User?
                  </h5>}
                  
                </>
              )}
              {props.deleteIndex.isDelete == true && (
                <>
                  <h5 className="text-black px-5">
                    Are You Sure You Want to Delete This Game?
                  </h5>
                </>
              )}
              {props.deleteIndex.isMerchandise == true && (
                <>
                  <h5 className="text-black px-5">
                    Are You Sure You Want to Delete This Product?
                  </h5>
                </>
              )}
              {props.deleteIndex.isRefund == true && (
                <>
                  <h5 className="text-black px-5">
                    Are You Sure You want to Refund {props.deleteIndex.userName}{" "}
                    with stripe ID?
                  </h5>
                  <p className="pt-2 text-danger fw-medium fs-5">
                    ${props.deleteIndex.stripeId}
                  </p>
                </>
              )}
              {props.deleteIndex.isChallenge == true && (
                <>
                  <h5 className="text-black px-5">
                  {` Are You Sure You Want to Delete ${props.deleteIndex.name} Challenge ?`}
                  </h5>
                </>
              )}
              {props.deleteIndex.isDeleteUser == true && (
                <>
                  <h5 className="text-black px-5">
                    {`Are You Sure You Want to Delete ${props.deleteIndex.name} ?`}
                  </h5>
                </>
              )}
              {props.deleteIndex.isMedia == true && (
                <>
                  <h5 className="text-black px-5">
                    {`Are You Sure You Want to Delete This Motivational Video ?`}
                  </h5>
                </>
              )}
            </div>
            <div className="modal-footer border-0 d-flex justify-content-around">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn btn-lg btn-red"
                onClick={() => disabledUser()}
              >
                {props.deleteIndex.isDelete == true
                  ? "Yes, Delete"
                  : props.deleteIndex.isMerchandise == true
                  ? "Yes, Delete" : props.deleteIndex.isMedia == true ? "Yes, Delete" 
                  : props.deleteIndex.isRefund == true
                  ? "Yes, Refuned!"
                  : props.deleteIndex.isChallenge == true
                  ? "Yes, Delete"
                  : props.deleteIndex.isDeleteUser 
                  ? "Yes, Delete" : (props?.deleteIndex?.isDelete == false && props?.deleteIndex?.data?.status == false) ? "Yes, Enable" 
                  : "Yes, Disable!"}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-light text-black fw-bold"
                data-bs-dismiss="modal"
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;

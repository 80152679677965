import React, { useEffect } from "react";
import { Col, Row, Card, Button, CardTitle, CardText } from "reactstrap";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { baseUrl, getOrderById } from "../../Api";
import { useState } from "react";

const SalesOrderDetail = (props) => {
  const [orderDetail, setOrderDetail] = useState({});

  useEffect(() => {
    (async () => {
      const data = await getOrderById({
        id: location.state.salesOrder
          ? location.state.salesOrder.id
          : location.state.orderRefund.orderId,
      });
      if (data.success == true) {
        setOrderDetail(data.data[0]);
      }
    })();
  }, []);
  const location = useLocation();
  let subTotal = 0;
  if (Object.keys(orderDetail).length > 0) {
    Object.values(orderDetail.product).map((x) => {
      return (subTotal += x.avlQuantity * x.unitePrice);
    });
  }

  return (
    <div className="bg-light-blue page-content pb-5">
      <Breadcrumbs name={"Sales Orders Details"} />
      {Object.keys(orderDetail).length > 0 ? (
        <>
          <Row className="px-3">
            <Col xxl={4} xl={12} className="pt-3 pt-xxl-0">
              <Card body className="border-0 shadow">
                <h5 className="text-blue fw-bold">
                  Order Details (#{orderDetail?.id})
                </h5>{" "}
                <span></span>
                <div className="d-md-flex d-block justify-content-between text-center pt-1">
                  <div>
                    <p>
                      <img
                        src="assets/datepicker.svg"
                        alt="datepickter"
                        className="pe-4"
                      />
                      <span className="text-gray fs-5"> Date Added </span>
                    </p>
                  </div>
                  <p className="text-gray fs-5">
                    {moment(orderDetail?.createdAt).format("DD/MM/YYYY")}
                  </p>
                </div>
                <hr className="border-gray" />
                <div className="d-md-flex d-block justify-content-between text-center pt-1">
                  <div>
                    <p>
                      <img
                        src="assets/paymentMethod.svg"
                        alt="datepickter"
                        className="pe-4"
                      />
                      <span className="text-gray fs-5"> Payment Method </span>
                    </p>
                  </div>
                  <p className="text-gray fs-5">
                    {orderDetail.payment?.paymentMethod}
                  </p>
                </div>
                <hr className="border-gray" />
                <div className="d-md-flex d-block justify-content-between text-center pt-1">
                  <div>
                    <p>
                      <img
                        src="assets/shippingMethod.svg"
                        alt="shipping method"
                        className="pe-4"
                      />
                      <span className="text-gray fs-5">Shipping Amount</span>
                    </p>
                  </div>
                  <p className="text-gray fs-5">
                    {orderDetail?.shippingAmount} $
                  </p>
                </div>
                <hr className="border-gray" />
              </Card>
            </Col>
            <Col xxl={4} xl={12} className="ps-xxl-3 ps-0 pt-3 pt-xxl-0">
              <Card body className="border-0 shadow">
                <h5 className="text-blue fw-bold">Order Receiver Details</h5>

                <div className="d-md-flex d-block justify-content-between text-center pt-2">
                  <div>
                    <p>
                      <img
                        src="assets/contact.svg"
                        alt="customer"
                        className="pe-4"
                      />
                      <span className="text-gray fs-5"> Customer </span>
                    </p>
                  </div>
                  <p className="text-gray fs-5"> {orderDetail?.receiverName}</p>
                </div>
                <hr className="border-gray" />
                <div className="d-md-flex d-block justify-content-between text-center pt-3">
                  <div>
                    <p>
                      <img
                        src="assets/email.svg"
                        alt="datepickter"
                        className="pe-4"
                      />
                      <span className="text-gray fs-5"> Email </span>
                    </p>
                  </div>
                  <p className="text-gray fs-5">{orderDetail?.user.email}</p>
                </div>
                <hr className="border-gray" />
                <div className="d-md-flex d-block justify-content-between text-center pt-2">
                  <div>
                    <p>
                      <img
                        src="assets/phone.svg"
                        alt="phone"
                        className="pe-4"
                      />
                      <span className="text-gray fs-5"> Phone </span>
                    </p>
                  </div>
                  <p className="text-gray fs-5">{orderDetail?.phone}</p>
                </div>
                <hr className="border-gray" />
              </Card>
            </Col>
            <Col xxl={4} xl={12} className="ps-xxl-3 ps-0 pt-3 pt-xxl-0">
              <Card body className="border-0 shadow text-center text-md-start">
                <h5 className="text-blue fw-bold pb-2">Shipping Address</h5>
                <h6>{orderDetail?.address}</h6>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4 px-3">
            <div className="bg-white rounded-3">
              <h5 className="text-blue fw-bold pt-3 pb-4 ps-3 text-center text-lg-start">
                Order #{orderDetail?.id}
              </h5>
              <div className="table-responsive px-3">
                <Table className="table table-borderless">
                  <thead className="wordbreak">
                    <tr className="text-gray text-end border-bottom">
                      <th className="text-start">Product</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  {Object.values(orderDetail.product).map((x) => {
                    return (
                      <tbody>
                        <tr className="align-middle text-end font-blue">
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={`${baseUrl}uploads/${x.thumbnail}`}
                                alt="product"
                                className="width-50"
                              />
                              <div className="ps-2 pt-2">
                                <h6 className="text-blue">{x.name}</h6>
                              </div>
                            </div>
                          </td>
                          <td>{x.avlQuantity}</td>
                          <td>${x.unitePrice}</td>
                          <td>${x.avlQuantity * x.unitePrice}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                  <tr className="align-middle text-end font-blue">
                    <td className="py-4"></td>
                    <td></td>
                    <td>Subtotal</td>
                    <td>${subTotal}</td>
                  </tr>
                  <tr className="align-middle text-end font-blue">
                    <td className="py-4"></td>
                    <td></td>
                    <td>VAT (0%)</td>
                    <td>$0.00</td>
                  </tr>
                  <tr className="align-middle text-end font-blue">
                    <td className="py-4"></td>
                    <td></td>
                    <td>Shipping Rate</td>
                    <td>${orderDetail?.shippingAmount}</td>
                  </tr>
                  <tfoot>
                    <tr className="align-middle text-end text-black fw-bold">
                      <td className="py-4"></td>
                      <td></td>
                      <td className="p-0">Grand Total</td>
                      <td className="p-0">
                        ${subTotal + orderDetail?.shippingAmount}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SalesOrderDetail;

import React, { useState } from "react";
import { Row, Col, Table, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Editpermission from "./Editpermission";
import { useEffect } from "react";
import {
  addUserPermission,
  editUserPermission,
  getAllModule,
  getAllUser,
  getModuleById,
} from "../../Api";
import DeleteModal from "../../components/common/DeleteModal";
// Table data

const UserRoleList = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [userList, setuserList] = useState([]);
  const [getModule, setGetModule] = useState([]);
  const [permission, setPermission] = useState({});
  const [getPermission, setGetPermission] = useState({});
  const [deleteIndex, setDeleteIndex] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    allUser();
    allModule();
  }, [page, sizePerPage]);

  const allModule = async () => {
    const data = await getAllModule();
    let objectPermission = {};
    setPermissions(objectPermission, data.data);
    setPermission(objectPermission);
    setGetModule(data.data);
  };

  const setPermissions = (objectPermission, data) => {
    for (let i = 0; i < data.length; i++) {
      objectPermission[`"${data[i].id}"`] = {
        A: false,
        C: false,
        R: false,
        U: false,
        D: false,
      };
      if (data[i].module) {
        setPermissions(objectPermission, data[i].module);
      }
    }
  };


  useEffect(() => {

  }, [permission]);

  const allUser = async () => {
    const data = await getAllUser({
      page: page,
      limit: sizePerPage,
      roleId: 5,
    });
    setuserList(data.data);
    setTotalSize(data.count);
  };

 
  const getEditPermission = async (row) => {
    try {
      const data = await getModuleById({ id: row?.id });
      if (data.success) {
        setGetPermission(data.data.modules);
      } else {
        let objectPermission = {};
        const data = await getAllModule();
        setGetModule(data.data);
        setPermissions(objectPermission, data.data);
        setGetPermission({ ...objectPermission });
        setPermission({ ...objectPermission });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createUserPermission = async () => {
    let postPermission = {};
    for (const key in permission) {
      if (
        permission[key].C ||
        permission[key].R ||
        permission[key].D ||
        permission[key].U
      ) {
        postPermission[key.split('"')[1]] = permission[key];
      }
    }
    delete postPermission[""];

    let temp = {
      userId: userId,
      modules: postPermission,
    };

    const data = await editUserPermission(temp);
    if (data.success == true) {
      const notify = () => toast.success(data.message);
      notify();
    } else {
      const errorNotify = () => toast.error(data.message);
      errorNotify();
    }
  };

  const setP = (objectPermission, status, moduleId, parentModuleId) => {
 
    let tem = ["C", "R", "D", "U"];
    if (moduleId) {
      objectPermission[`"${moduleId}"`][status] =
        !objectPermission[`"${moduleId}"`][status];
      if (parentModuleId == null) {
        if (Array.isArray(getModule)) {
          let A = getModule.forEach((y) => {
            // tem.forEach((x) => {
            //   objectPermission[`"${moduleId}"`][x] =
            //     objectPermission[`"${moduleId}"`][status];
            // });
            // objectPermission[`"${y.id}"`]["A"] =
            //   objectPermission[`"${moduleId}"`][status];
            if (status == "A" && objectPermission[`"${moduleId}"`][status]) {
              getModule
                .filter((x) => x.id == moduleId)[0]
                .module.map((x) => (objectPermission[`"${x.id}"`]["A"] = true));
              tem.forEach((x) => {
                getModule
                  .filter((y) => y.id == moduleId)[0]
                  .module.map((z) => (objectPermission[`"${z.id}"`][x] = true));
              });
            } else {
              getModule
                .filter((x) => x.id == moduleId)[0]
                .module.map(
                  (x) => (objectPermission[`"${x.id}"`]["A"] = false)
                );
              tem.forEach((x) => {
                getModule
                  .filter((y) => y.id == moduleId)[0]
                  .module.map(
                    (z) => (objectPermission[`"${z.id}"`][x] = false)
                  );
              });
            }
          });
        }
      }
      if (status == "A" && objectPermission[`"${moduleId}"`][status] == true) {
        tem.forEach((x) => {
          objectPermission[`"${moduleId}"`][x] = true;
        });
      } else if (
        status == "A" &&
        objectPermission[`"${moduleId}"`][status] == false
      ) {
        tem.forEach((x) => {
          objectPermission[`"${moduleId}"`][x] = false;
        });
      } else if (
        tem.filter((x) => objectPermission[`"${moduleId}"`][x]).length == 0
      ) {
        objectPermission[`"${moduleId}"`].A = false;
      } else if (
        tem.filter((x) => objectPermission[`"${moduleId}"`][x]).length > 3
      ) {
        objectPermission[`"${moduleId}"`].A = true;
      } else {
        objectPermission[`"${moduleId}"`].A = false;
      }
    }
    if (parentModuleId) {
      getModule.forEach((x) => {
        if (x.id === parentModuleId) {
          let A = x.module.filter((y) => objectPermission[`"${y.id}"`][status]);

          if (A.length) {
            objectPermission[`"${parentModuleId}"`][status] = true;
            if (
              tem.filter((x) => objectPermission[`"${moduleId}"`][x]).length > 3
            ) {
              objectPermission[`"${parentModuleId}"`]["A"] = true;
            }
            if (status == "A") {
              tem.forEach((x) => {
                objectPermission[`"${parentModuleId}"`][x] = true;
              });
            }
          } else {
            objectPermission[`"${parentModuleId}"`][status] = false;
            objectPermission[`"${parentModuleId}"`]["A"] = false;
            if (status == "A") {
              tem.forEach((x) => {
                objectPermission[`"${parentModuleId}"`][x] = false;
              });
            }
          }
        }
      });
    }
    switch (status) {
      case "All":
        for (const key in objectPermission) {
          objectPermission[key] = {
            A: true,
            C: true,
            R: true,
            U: true,
            D: true,
          };
        }
        setPermission({ ...objectPermission });
      case "selectAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            A: true,
            C: true,
            R: true,
            U: true,
            D: true,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "dselectAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            A: false,
            C: false,
            R: false,
            U: false,
            D: false,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "rAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            R: true,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "drAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            R: false,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "cAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            C: true,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "dcAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            C: false,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "dAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            D: true,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "ddAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            D: false,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "uAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            U: true,
          };
        }
        setPermission({ ...objectPermission });
        break;
      case "duAll":
        for (const key in objectPermission) {
          objectPermission[key] = {
            ...objectPermission[key],
            U: false,
          };
        }
        setPermission({ ...objectPermission });
        break;
      default:
        for (const key in objectPermission) {
          if (objectPermission[key].A == false) {
            document.getElementById("select_all1").checked = false;
          }
          if (objectPermission[key].C == false) {
            document.getElementById("select_all2").checked = false;
          }
          if (objectPermission[key].R == false) {
            document.getElementById("select_all3").checked = false;
          }
          if (objectPermission[key].U == false) {
            document.getElementById("select_all4").checked = false;
          }
          if (objectPermission[key].D == false) {
            document.getElementById("select_all5").checked = false;
          }
        }
        setPermission({ ...objectPermission });
    }
   
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "fullname",
      text: "Full Name",
      searchable: true,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: (cell, row) => {
        return cell == 1 ? "Male" : "Female";
      },
    },
    {
      dataField: "age",
      text: "Age",
    },
    {
      dataField: "status",
      text: "User Status",
      formatter: (cell, row) => {
        return cell == true ? (
          <button className="btn-success-green border-0 py-2 px-3 rounded">
            Active
          </button>
        ) : (
          <button className="btn-danger-red border-0 py-2 px-3 rounded">
            Inactive
          </button>
        );
      },
    },
    JSON.parse(localStorage.getItem("authUser")).roleId == 5
      ? temp && (temp.U == true || temp.D == true)
        ? {
            dataField: "action",
            text: "Action",
            formatter: (cell, row) => {
              return (
                <div className="btn-group">
                  {temp && temp.U == true ? (
                    <>
                      <button className="border-0 me-3 py-2 px-2 rounded">
                        <Link to="/add-role-user" state={{ userRole: row }}>
                          <img src="assets/edit.svg" alt="edit" />
                        </Link>
                      </button>
                      <button
                        className="border-0 me-3 py-2 px-2 rounded"
                        type="submit"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        onClick={() => {
                          setUserId(row?.id);
                          getEditPermission(row);
                        }}
                      >
                        <img
                          src="assets/editpermission.svg"
                          alt="editpermission"
                        />
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  {temp && temp.D == true ? (
                    <button
                      className="border-0 bh-light py-2 px-2 rounded"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() =>
                        setDeleteIndex({ isDelete: false, index: row?.id })
                      }
                    >
                      <img src="assets/delete.svg" alt="delete" />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              );
            },
          }
        : {}
      : {
          dataField: "action",
          text: "Action",
          formatter: (cell, row) => {
            return (
              <div className="btn-group">
                <button className="border-0 me-3 py-2 px-2 rounded">
                  <Link to="/add-role-user" state={{ userRole: row }}>
                    <img src="assets/edit.svg" alt="edit" />
                  </Link>
                </button>
                <button
                  className="border-0 me-3 py-2 px-2 rounded"
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  onClick={() => {
                    setUserId(row?.id);
                    getEditPermission(row);
                  }}
                >
                  <img src="assets/editpermission.svg" alt="editpermission" />
                </button>
                <button
                  className="border-0 bh-light py-2 px-2 rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() =>
                    setDeleteIndex({ isDelete: false, index: row?.id })
                  }
                >
                  <img src="assets/delete.svg" alt="delete" />
                </button>
              </div>
            );
          },
        },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],

    page: page,
    totalSize: totalSize,
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
        <>
          {temp && temp.R == true ? (
            <>
              <ToastContainer />
              <DeleteModal deleteIndex={deleteIndex} allUser={allUser} />
              {userList.length > 0 ? (
                <>
                  {
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={[...columns]}
                          data={[...userList]}
                          search
                        >
                          {(toolkitprops) => (
                            <React.Fragment>
                              <div className="container-fluid page-content bg-light-blue px-lg-3">
                                <h3 className="text-black fw-bold text-uppercase pt-3">
                                  User Role List
                                </h3>
                                <Row>
                                  <Col
                                    xxl="10"
                                    md="6"
                                    sm="12"
                                    className="text-md-start text-center"
                                  >
                                    <div className="pt-4">
                                      <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          className=""
                                          {...paginationProps}
                                        />
                                      </div>

                                      <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl="2"
                                    md="6"
                                    sm="12"
                                    className="text-md-end pt-4 text-center"
                                  >
                                    <div
                                      className="modal fade"
                                      id="exampleModal1"
                                      tabIndex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-centered modal-xl">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h4
                                              className="modal-title text-black"
                                              id="exampleModalLabel"
                                            >
                                              Edit Permissions
                                            </h4>
                                            <button
                                              onClick={() =>
                                                setPermissions(
                                                  permission,
                                                  getModule
                                                )
                                              }
                                              type="button"
                                              className="btn-close text-blue"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body text-start">
                                            <div className="">
                                              <h5 className="pt-4">
                                                Role Permissions
                                              </h5>
                                              <div className="table-responsive">
                                                <Table className="w-100">
                                                  <tbody>
                                                    <tr className="align-middle">
                                                      <th scope="row">
                                                        Administrator Access
                                                      </th>
                                                      <td className="">
                                                        <div className="d-block d-lg-inline text-center text-lg-start">
                                                          <Input
                                                            id="select_all1"
                                                            name="all"
                                                            type="checkbox"
                                                            className="fs-4"
                                                            onChange={(e) => {
                                                              document.getElementById(
                                                                "select_all3"
                                                              ).checked = e
                                                                .target.checked
                                                                ? true
                                                                : false;
                                                              document.getElementById(
                                                                "select_all2"
                                                              ).checked = e
                                                                .target.checked
                                                                ? true
                                                                : false;
                                                              document.getElementById(
                                                                "select_all4"
                                                              ).checked = e
                                                                .target.checked
                                                                ? true
                                                                : false;
                                                              document.getElementById(
                                                                "select_all5"
                                                              ).checked = e
                                                                .target.checked
                                                                ? true
                                                                : false;
                                                              setP(
                                                                permission,
                                                                e.target.checked
                                                                  ? "selectAll"
                                                                  : "dselectAll"
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <Label
                                                          className="ps-3 pt-2 d-block d-lg-inline"
                                                          for="select_all1"
                                                        >
                                                          Select all
                                                        </Label>
                                                      </td>
                                                      <td className="">
                                                        {" "}
                                                        <div className="d-block d-lg-inline text-center text-lg-start">
                                                          <Input
                                                            id="select_all2"
                                                            name="allWrite"
                                                            type="checkbox"
                                                            className="fs-4"
                                                            onChange={(e) => {
                                                              setP(
                                                                permission,
                                                                e.target.checked
                                                                  ? "cAll"
                                                                  : "dcAll"
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <Label
                                                          className="ps-3 pt-2 d-block d-lg-inline"
                                                          for="select_all2"
                                                        >
                                                          Select all
                                                        </Label>
                                                      </td>
                                                      <td className="">
                                                        {" "}
                                                        <div className="d-block d-lg-inline text-center text-lg-start">
                                                          <Input
                                                            id="select_all3"
                                                            name="allCreate"
                                                            type="checkbox"
                                                            className="fs-4"
                                                            onChange={(e) => {
                                                              setP(
                                                                permission,
                                                                e.target.checked
                                                                  ? "rAll"
                                                                  : "drAll"
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <Label
                                                          className="ps-3 pt-2 d-block d-lg-inline"
                                                          for="select_all3"
                                                        >
                                                          Select all
                                                        </Label>
                                                      </td>
                                                      <td className="">
                                                        {" "}
                                                        <div className="d-block d-lg-inline text-center text-lg-start">
                                                          <Input
                                                            id="select_all4"
                                                            name="allRead"
                                                            type="checkbox"
                                                            className="fs-4"
                                                            onChange={(e) => {
                                                              setP(
                                                                permission,
                                                                e.target.checked
                                                                  ? "uAll"
                                                                  : "duAll"
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <Label
                                                          className="ps-3 pt-2 d-block d-lg-inline"
                                                          for="select_all4"
                                                        >
                                                          Select all
                                                        </Label>
                                                      </td>
                                                      <td className="">
                                                        {" "}
                                                        <div className="d-block d-lg-inline text-center text-lg-start">
                                                          <Input
                                                            id="select_all5"
                                                            name="allUpdate"
                                                            type="checkbox"
                                                            className="fs-4"
                                                            onChange={(e) => {
                                                              setP(
                                                                permission,
                                                                e.target.checked
                                                                  ? "dAll"
                                                                  : "ddAll"
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <Label
                                                          className="ps-3 pt-2 d-block d-lg-inline"
                                                          for="select_all5"
                                                        >
                                                          Select all
                                                        </Label>
                                                      </td>
                                                    </tr>
                                                    {getModule?.map((x) => {
                                                      return (
                                                        <Editpermission
                                                          getPermission={
                                                            getPermission
                                                          }
                                                          allModule={getModule}
                                                          setP={setP}
                                                          permission={
                                                            permission
                                                          }
                                                          key={x.id}
                                                          name={x.name}
                                                          module={x}
                                                        />
                                                      );
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-footer border-0 d-block mx-auto">
                                            <button
                                              onClick={() =>
                                                setPermissions(
                                                  permission,
                                                  getModule
                                                )
                                              }
                                              type="button"
                                              className="btn btn-lg btn-light-secondary me-3 fw-bold"
                                              data-bs-dismiss="modal"
                                            >
                                              Discard
                                            </button>
                                            <button
                                              type="submit"
                                              onClick={() =>
                                                createUserPermission()
                                              }
                                              className="btn btn-blue btn-lg text-white ms-3 fw-bold"
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3 rounded-3 bg-white px-4">
                                    <h4 className="py-4 text-blue fw-600">
                                      User Role List
                                    </h4>
                                    <Row>
                                      <div className="table-responsive">
                                        <div>
                                          <BootstrapTable
                                            remote
                                            onTableChange={() => false}
                                            headerClasses="align-self-center"
                                            rowClasses=""
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            bootstrap4={true}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light text-grey-light"}
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>

                                <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                                <h6 className="text-blue fw-600 ps-2">
                           {userList.length == 0 ? "Showing 0" : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${totalSize < sizePerPage
                              ? totalSize
                              : sizePerPage * page >= totalSize
                              ? totalSize
                              : sizePerPage * page}
                            of ${totalSize}`} 
                          </h6>
                                  <div className="ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <><div className="container-fluid page-content bg-light-blue px-lg-3">
                <h3 className="text-black fw-bold text-uppercase pt-3">
                  User Role List
                </h3>
              </div></>
          )}
        </>
      ) : (
        <>
          <ToastContainer />
          <DeleteModal deleteIndex={deleteIndex} allUser={allUser} />
          {userList.length > 0 ? (
            <>
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[...columns]}
                      data={[...userList]}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              User Role List
                            </h3>
                            <Row>
                              <Col
                                xxl="10"
                                md="6"
                                sm="12"
                                className="text-md-start text-center"
                              >
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                                </div>
                              </Col>
                              <Col
                                xxl="2"
                                md="6"
                                sm="12"
                                className="text-md-end pt-4 text-center"
                              >
                                <div
                                  className="modal fade"
                                  id="exampleModal1"
                                  tabIndex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-dialog-centered modal-xl">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4
                                          className="modal-title text-black"
                                          id="exampleModalLabel"
                                        >
                                          Edit Permissions
                                        </h4>
                                        <button
                                          onClick={() =>
                                            setPermissions(
                                              permission,
                                              getModule
                                            )
                                          }
                                          type="button"
                                          className="btn-close text-blue"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="modal-body text-start">
                                        <div className="">
                                          <h5 className="pt-4">
                                            Role Permissions
                                          </h5>
                                          <div className="table-responsive">
                                            <Table className="w-100">
                                              <tbody>
                                                <tr className="align-middle">
                                                  <th scope="row">
                                                    Administrator Access
                                                  </th>
                                                  <td className="">
                                                    <div className="d-block d-lg-inline text-center text-lg-start">
                                                      <Input
                                                        id="select_all1"
                                                        name="all"
                                                        type="checkbox"
                                                        className="fs-4"
                                                        onChange={(e) => {
                                                          document.getElementById(
                                                            "select_all3"
                                                          ).checked = e.target
                                                            .checked
                                                            ? true
                                                            : false;
                                                          document.getElementById(
                                                            "select_all2"
                                                          ).checked = e.target
                                                            .checked
                                                            ? true
                                                            : false;
                                                          document.getElementById(
                                                            "select_all4"
                                                          ).checked = e.target
                                                            .checked
                                                            ? true
                                                            : false;
                                                          document.getElementById(
                                                            "select_all5"
                                                          ).checked = e.target
                                                            .checked
                                                            ? true
                                                            : false;
                                                          setP(
                                                            permission,
                                                            e.target.checked
                                                              ? "selectAll"
                                                              : "dselectAll"
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <Label
                                                      className="ps-3 pt-2 d-block d-lg-inline"
                                                      for="select_all1"
                                                    >
                                                      Select all
                                                    </Label>
                                                  </td>
                                                  <td className="">
                                                    {" "}
                                                    <div className="d-block d-lg-inline text-center text-lg-start">
                                                      <Input
                                                        id="select_all2"
                                                        name="allWrite"
                                                        type="checkbox"
                                                        className="fs-4"
                                                        onChange={(e) => {
                                                          setP(
                                                            permission,
                                                            e.target.checked
                                                              ? "cAll"
                                                              : "dcAll"
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <Label
                                                      className="ps-3 pt-2 d-block d-lg-inline"
                                                      for="select_all2"
                                                    >
                                                      Select all
                                                    </Label>
                                                  </td>
                                                  <td className="">
                                                    {" "}
                                                    <div className="d-block d-lg-inline text-center text-lg-start">
                                                      <Input
                                                        id="select_all3"
                                                        name="allCreate"
                                                        type="checkbox"
                                                        className="fs-4"
                                                        onChange={(e) => {
                                                          setP(
                                                            permission,
                                                            e.target.checked
                                                              ? "rAll"
                                                              : "drAll"
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <Label
                                                      className="ps-3 pt-2 d-block d-lg-inline"
                                                      for="select_all3"
                                                    >
                                                      Select all
                                                    </Label>
                                                  </td>
                                                  <td className="">
                                                    {" "}
                                                    <div className="d-block d-lg-inline text-center text-lg-start">
                                                      <Input
                                                        id="select_all4"
                                                        name="allRead"
                                                        type="checkbox"
                                                        className="fs-4"
                                                        onChange={(e) => {
                                                          setP(
                                                            permission,
                                                            e.target.checked
                                                              ? "uAll"
                                                              : "duAll"
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <Label
                                                      className="ps-3 pt-2 d-block d-lg-inline"
                                                      for="select_all4"
                                                    >
                                                      Select all
                                                    </Label>
                                                  </td>
                                                  <td className="">
                                                    {" "}
                                                    <div className="d-block d-lg-inline text-center text-lg-start">
                                                      <Input
                                                        id="select_all5"
                                                        name="allUpdate"
                                                        type="checkbox"
                                                        className="fs-4"
                                                        onChange={(e) => {
                                                          setP(
                                                            permission,
                                                            e.target.checked
                                                              ? "dAll"
                                                              : "ddAll"
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <Label
                                                      className="ps-3 pt-2 d-block d-lg-inline"
                                                      for="select_all5"
                                                    >
                                                      Select all
                                                    </Label>
                                                  </td>
                                                </tr>
                                                {getModule?.map((x) => {
                                                  return (
                                                    <Editpermission
                                                      getPermission={
                                                        getPermission
                                                      }
                                                      allModule={getModule}
                                                      setP={setP}
                                                      permission={permission}
                                                      key={x.id}
                                                      name={x.name}
                                                      module={x}
                                                    />
                                                  );
                                                })}
                                              </tbody>
                                            </Table>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-footer border-0 d-block mx-auto">
                                        <button
                                          onClick={() =>
                                            setPermissions(
                                              permission,
                                              getModule
                                            )
                                          }
                                          type="button"
                                          className="btn btn-lg btn-light-secondary me-3 fw-bold"
                                          data-bs-dismiss="modal"
                                        >
                                          Discard
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() => createUserPermission()}
                                          className="btn btn-blue btn-lg text-white ms-3 fw-bold"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue">
                                  User Role List
                                </h4>
                                <Row>
                                  <div className="table-responsive">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={() => false}
                                        headerClasses="align-self-center"
                                        rowClasses=""
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        bootstrap4={true}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light text-grey-light"}
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                            <h6 className="text-blue fw-600 ps-2">
                           {userList.length == 0 ? "Showing 0" : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${totalSize < sizePerPage
                              ? totalSize
                              : sizePerPage * page >= totalSize
                              ? totalSize
                              : sizePerPage * page}
                            of ${totalSize}`} 
                          </h6>
                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          ) : (
            <>
              <div className="container-fluid page-content bg-light-blue px-lg-3">
                <h3 className="text-black fw-bold text-uppercase pt-3">
                  User Role List
                </h3>
              </div>{" "}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default UserRoleList;

import React, { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Row, Col } from "reactstrap";
import { Form, Field } from "react-final-form";
import { addGoogleAds, getGoogleAds } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";

const GoogleAds = (props) => {
  const [id, setId] = useState(null);
  const [isAndroid, setIsAndroid] = useState("true");
  const [googleNames, setGoogleName] = useState({
    appOpen: "",
    banner: "",
    interstitial: "",
    nativeAdvanced: "",
    rewarded: "",
    status: "",
  });

  const handleChange = (e) => {
    setGoogleName((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }

  useEffect(() => {
    (async () => {
      const data = await getGoogleAds(isAndroid == "true" ? true : false);

      if (data && data.data && data.data.length != 0) {
        setGoogleName({
          appOpen: data.data.appOpen,
          banner: data.data.banner,
          interstitial: data.data.interstitial,
          nativeAdvanced: data.data.nativeAdvanced,
          rewarded: data.data.rewarded,
          status:
            data.data.status == true
              ? "true"
              : data.data.status == false
              ? "false"
              : "",
        });
        setId(Object.keys(data.data).length > 0 ? data.data.id : null);
      } else {
        setGoogleName({
          appOpen: "",
          banner: "",
          interstitial: "",
          nativeAdvanced: "",
          rewarded: "",
          status: "",
        });
        setId(null);
      }
    })();
  }, [isAndroid]);

  const onSubmit = async (values) => {
    if (isAndroid == "true") {
      let temp = {
        appOpen: googleNames.appOpen,
        banner: googleNames.banner,
        isAndroid: isAndroid == "true" ? true : false,
        interstitial: googleNames.interstitial,
        nativeAdvanced: googleNames.nativeAdvanced,
        rewarded: googleNames.rewarded,
        status:
          googleNames.status == "true"
            ? true
            : googleNames.status == "false"
            ? false
            : "",
        id: id,
      };

      const data = await addGoogleAds(temp);
      if (data.success == true) {
        const notify = () => toast.success(data.message);
        notify();
      } else {
        const errorNotify = () => toast.error(data.message);
        errorNotify();
      }
    } else {
      let temp = {
        appOpen: googleNames.appOpen,
        banner: googleNames.banner,
        interstitial: googleNames.interstitial,
        nativeAdvanced: googleNames.nativeAdvanced,
        isAndroid: isAndroid == "true" ? true : false,
        rewarded: googleNames.rewarded,
        status:
          googleNames.status == "true"
            ? true
            : googleNames.status == "false"
            ? false
            : "",
        id: id,
      };

      const data = await addGoogleAds(temp);
      if (data.success == true) {
        const notify = () => toast.success(data.message);
        notify();
      } else {
        const errorNotify = () => toast.error(data.message);
        errorNotify();
      }
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container-fluid page-content pb-5 bg-light-blue">
        <Row>
          <Breadcrumbs name={"Google Ads"} />
        </Row>
        <Form
          onSubmit={onSubmit}
          validate={(values) => {
            const errors = {};
            const appOpen = googleNames.appOpen;
            const banner = googleNames.banner;
            const interstitial = googleNames.interstitial;
            const nativeAdvanced = googleNames.nativeAdvanced;
            const rewarded = googleNames.rewarded;
            const status = googleNames.status;
            if (!appOpen) {
              errors.appOpen = "This value is required";
            }
            if (!banner) {
              errors.banner = "This value is required";
            }
            if (!interstitial) {
              errors.interstitial = "This value is required";
            }
            if (!nativeAdvanced) {
              errors.nativeAdvanced = "This value is required";
            }
            if (!rewarded) {
              errors.rewarded = "This value is required";
            }
            if (status == "") {
              errors.status = "This value is required";
            }
            return errors;
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Row className="bg-white px-4 py-4 rounded">
                <Row className="d-flex align-items-center">
                  <Col lg="6" md="12" className="text-center text-lg-start">
                    <h5 className="text-blue fw-bold">Google Ads</h5>
                  </Col>
                  <Col lg="6" md="12" className="text-center text-lg-end">
                    <div className="pt-4">
                      <select
                        name="game"
                        className="shadow-box form-select bg-white border-0 ps-3 py-3 w-50 rounded-3"
                        value={isAndroid}
                        onChange={(e) => {
                          setIsAndroid(e.target.value);
                        }}
                      >
                        <option value="true">Android</option>
                        <option value="false">Ios</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                {/* <div className="d-flex">
                  <h5 className="text-blue fw-bold">Google Ads</h5>
                  <Field name="status">
                    {({ input, meta }) => (
                      <>
                        <label htmlFor="status">
                          <h5 className="text-blue pt-3">Google Ads Status</h5>
                        </label>
                          <select
                            {...input}
                            rows="8"
                            name="status"
                            value={googleNames.status}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className="form-control form-select border-light-blue w-100 text-black py-3 fw-600"
                            placeholder="Enter basePrice"
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="true">Enable</option>
                            <option value="false">Disable</option>
                          </select>
                        {meta.touched && meta.error && (
                          <p className="text-danger">{meta.error}</p>
                        )}
                      </>
                    )}
                  </Field>
                </div> */}

                <Field name="status">
                  {({ input, meta }) => (
                    <>
                      <h4 className="text-blue pt-5 fw-bold">Status</h4>
                      <label htmlFor="status">
                        <h5 className="text-blue pt-3">Google Ads Status</h5>
                      </label>
                      <div className="col-lg-6 pt-2">
                        <select
                          {...input}
                          rows="8"
                          name="status"
                          value={googleNames.status}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="form-control form-select border-light-blue w-100 text-black py-3 fw-600"
                          placeholder="Enter basePrice"
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </div>
                      <p className="text-black pt-2 mb-0">
                        Select Product Status
                      </p>
                      {meta.touched && meta.error && (
                        <p className="text-danger">{meta.error}</p>
                      )}
                    </>
                  )}
                </Field>
                <Field name="appOpen">
                  {({ input, meta }) => (
                    <div className="row">
                      <label htmlFor="appOpen">
                        <h5 className="text-blue pt-3">App Open</h5>
                      </label>
                      <div className="pt-2">
                        <textarea
                          {...input}
                          type="text"
                          rows="5"
                          name="appOpen"
                          value={googleNames.appOpen}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="form-control border-light-blue text-black py-3 fw-600"
                          placeholder="Google Ads Code"
                        ></textarea>
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="banner">
                  {({ input, meta }) => (
                    <div className="row">
                      <label htmlFor="banner">
                        <h5 className="text-blue pt-3">Banner</h5>
                      </label>
                      <div className="pt-2">
                        <textarea
                          {...input}
                          type="text"
                          rows="5"
                          name="banner"
                          value={googleNames.banner}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="form-control border-light-blue text-black py-3 fw-600"
                          placeholder="Google Ads Code"
                        ></textarea>
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="interstitial">
                  {({ input, meta }) => (
                    <div className="row">
                      <label htmlFor="interstitial">
                        <h5 className="text-blue pt-3">Interstitial</h5>
                      </label>
                      <div className="pt-2">
                        <textarea
                          {...input}
                          type="text"
                          rows="5"
                          name="interstitial"
                          value={googleNames.interstitial}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="form-control border-light-blue text-black py-3 fw-600"
                          placeholder="Google Ads Code"
                        ></textarea>
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="nativeAdvanced">
                  {({ input, meta }) => (
                    <div className="row">
                      <label htmlFor="nativeAdvanced">
                        <h5 className="text-blue pt-3">Native Advanced</h5>
                      </label>
                      <div className="pt-2">
                        <textarea
                          {...input}
                          type="text"
                          rows="5"
                          name="nativeAdvanced"
                          value={googleNames.nativeAdvanced}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="form-control border-light-blue text-black py-3 fw-600"
                          placeholder="Google Ads Code"
                        ></textarea>
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="rewarded">
                  {({ input, meta }) => (
                    <div className="row">
                      <label htmlFor="rewarded">
                        <h5 className="text-blue pt-3">Rewarded</h5>
                      </label>
                      <div className="pt-2">
                        <textarea
                          {...input}
                          type="text"
                          rows="5"
                          name="rewarded"
                          value={googleNames.rewarded}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="form-control border-light-blue text-black py-3 fw-600"
                          placeholder="Google Ads Code"
                        ></textarea>
                        {meta.error && meta.touched && (
                          <div className="text-danger pt-2">{meta.error}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
              </Row>
              {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
                temp && temp.C == true ? (
                  <div className="mt-5 text-lg-end text-center">
                    <button
                      type="reset"
                      onClick={() => {
                        for (let keys in values) {
                          form.resetFieldState(keys);
                        }
                        form.reset();
                      }}
                      className="btn btn-light btn-lg text-secondary fw-bold me-3"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-blue px-5 btn-lg text-white fw-bold"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div></div>
                )
              ) : (
                <div className="mt-5 text-lg-end text-center">
                  <button
                    type="reset"
                    onClick={() => {
                      for (let keys in values) {
                        form.resetFieldState(keys);
                      }
                      form.reset();
                    }}
                    className="btn btn-light btn-lg text-secondary fw-bold me-3"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-blue px-5 btn-lg text-white fw-bold"
                  >
                    Save
                  </button>
                </div>
              )}
            </form>
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default GoogleAds;

import React, { useState, useEffect } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  addFreeSongs,
  addMotivationalSongs,
  addPaidSongs,
  baseUrl,
  getPaidSongs,
  updateMotivationalSongs,
} from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, useField } from "react-final-form-hooks";

const AddMedia = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }

  const location = useLocation();
  let formdata = location?.state?.media;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [selectedFiles3, setSelectedFiles3] = useState([]);
  const [selectedFiles4, setSelectedFiles4] = useState([]);
  const [selectedFiles5, setSelectedFiles5] = useState([]);
  const [selectedFiles6, setSelectedFiles6] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  };

  const handleAcceptedFiles2 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles2(files);
  };

  const handleAcceptedFiles3 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles3(files);
  };

  const handleAcceptedFiles4 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles4(files);
  };

  const handleAcceptedFiles5 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles5(files);
  };

  const handleAcceptedFiles6 = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles6(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const onSubmit = async (values) => {


    if(formdata) {
      let formData = new FormData();
      Object.keys(values).forEach((x) => {
        if(values[x] != undefined){
          formData.append(x, values[x])
        }
      } );
      let thumbnail = selectedFiles.filter((x) => "formattedSize" in x);
      if (Array.isArray(thumbnail) && thumbnail.length) {
        formData.append("thumbnail", thumbnail[0]);
      }
      let videoId = selectedFiles2.filter((x) => "formattedSize" in x);
      if (Array.isArray(videoId) && videoId.length) {
        formData.append("videoId", videoId[0]);
      }
      const data = await updateMotivationalSongs({id: formdata.id, data: formData});
      if (data.success == true) {
        const notify = () => toast.success(data.message);
        notify();
        setTimeout(() => {
          navigate("/media-list?name=motivationalVideo");
        }, 2000);
      } else {
        const errorNotify = () => toast.error(data.message);
        errorNotify();
      }
    } else {
      let formData = new FormData();
      Object.keys(values).forEach((x) => formData.append(x, values[x]));
      if (selectedFiles.length) {
        formData.append("thumbnail", selectedFiles[0]);
      }
      if (selectedFiles2.length) {
        formData.append("videoId", selectedFiles2[0]);
      }
      const data = await addMotivationalSongs(formData);
      if (data.success == true) {
        const notify = () => toast.success(data.message);
        notify();
        setTimeout(() => {
          navigate("/media-list?name=motivationalVideo");
        }, 2000);
      } else {
        const errorNotify = () => toast.error(data.message);
        errorNotify();
      }
    }


   
  };

  let navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (selectedFiles.length < 1) {
      errors.thumbnail = "This value is required";
    }
    if (selectedFiles2.length < 1) {
      errors.videoId = "This value is required";
    }
    if (!values.title) {
      errors.title = "This value is required";
    }

    return errors;
  };

  const {
    form,
    handleSubmit,
    values,
    pristine,
    submitting,
    errors,
    hasValidationErrors,
  } = useForm({
    onSubmit,
    validate,
  });

  const thumbnail = useField("thumbnail", form);
  const videoId = useField("videoId", form);
  const title = useField("title", form);

  useEffect(() => {
    if (formdata) {
      form.reset({
        title: formdata.title,
        thumbnail: setSelectedFiles([
          {
            name: formdata.thumbnailId,
            preview: `${baseUrl}uploads/${formdata.thumbnailId}`,
            path: `${baseUrl}uploads/${formdata.thumbnailId}`,
          },
        ]),
        videoId: setSelectedFiles2([
          {
            name: formdata.videoId,
            preview: `${baseUrl}uploads/${formdata.videoId}`,
            path: `${baseUrl}uploads/${formdata.videoId}`,
          },
        ]),
      });
    }
  }, []);

  const onSubmit2 = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((x) => formData.append(x, values[x]));
    if (selectedFiles3.length) {
      formData.append("thumbnail", selectedFiles3[0]);
    }
    if (selectedFiles4.length) {
      formData.append("item", selectedFiles4[0]);
    }
    const data = await addFreeSongs(formData);
    if (data.success == true) {
      const notify = () => toast.success(data.message);
      notify();
      setTimeout(() => {
        navigate("/media-list?name=freeSongs");
      }, 2000);
    } else {
      const errorNotify = () => toast.error(data.message);
      errorNotify();
    }
  };

  const onSubmit3 = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((x) => formData.append(x, values[x]));
    if (selectedFiles5.length) {
      formData.append("thumbnail", selectedFiles5[0]);
    }
    if (selectedFiles6.length) {
      formData.append("item", selectedFiles6[0]);
    }
    const data = await addPaidSongs(formData);
    if (data.success == true) {
      const notify = () => toast.success(data.message);
      notify();
      setTimeout(() => {
        navigate("/media-list?name=paidSongs");
      }, 2000);
    } else {
      const errorNotify = () => toast.error(data.message);
      errorNotify();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="pb-5 bg-light-blue page-content">
        <div className="container">
          <ul className="nav nav-tabs border-0 pt-4" id="myTab" role="tablist">
            {/* motivational video start */}
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active border-0 bg-transparent"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <span className="fw-bold"> Motivational Video</span>
              </button>
            </li>
            {/* motivational video end */}

            {/* free song start */}
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link border-0 px-5 bg-transparent"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <span className="fw-bold">Free Songs</span>
              </button>
            </li> */}
            {/* free song end */}

            {/* paid song start */}
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link border-0 px-5 bg-transparent"
                id="paidSong-tab"
                data-bs-toggle="tab"
                data-bs-target="#paidSong"
                type="button"
                role="tab"
                aria-controls="paidSong"
                aria-selected="false"
              >
                <span className="fw-bold"> Paid Songs </span>
              </button>
            </li> */}
            {/* paid song end */}
          </ul>

          <div className="tab-content pt-5" id="myTabContent">
            {/* motivational video start */}
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <form onSubmit={handleSubmit}>
                <h3 className="text-black pb-3">Upload Media</h3>
                <Card className="py-3 border-0 shadow">
                  <CardBody>
                    <CardTitle className="text-blue fs-5 fw-600">
                      Upload Media
                    </CardTitle>
                    <CardSubtitle>
                      <div>
                        <label htmlFor="thumbnail">
                          <h5 className="text-blue pt-3">
                            Upload Media Thumbnail{" "}
                          </h5>
                        </label>
                        <div className="text-center">
                          <Dropzone
                            {...thumbnail.input}
                            accept={{
                              "image/png": [".png"],
                              "image/jpeg": [".jpeg", ".jpg"],
                            }}
                            id="recommendationDiv"
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick pt-5"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="fs-2 fa-solid text-gray fa-cloud-arrow-up"></i>
                                  </div>
                                  <h4>Drop logo here or click to upload.</h4>
                                  <p className="font-blue fs-6">
                                    Set the product thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews d-flex mt-3"
                            id="file-previews"
                          >
                            {selectedFiles?.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border-0 d-inline"
                                  key={i + "-file"}
                                >
                                  <div className="thumb">
                                    <img
                                      data-dz-thumbnail=""
                                      className="img-fluid d-inline thumbInner"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setSelectedFiles(selectedFiles.pop());

                                        setSelectedFiles([...selectedFiles]);
                                      }}
                                      className="bg-transparent border-0"
                                    >
                                      {" "}
                                      <div className="middle">
                                        <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                      </div>
                                    </button>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                        <p className="font-blue pt-2 mb-0">
                          A product name is required and recommended to be
                          unique.
                        </p>
                        {thumbnail.meta.touched && thumbnail.meta.error && (
                          <p className="text-danger">{thumbnail.meta.error}</p>
                        )}
                      </div>
                      <div className="pt-4 pb-1">
                        <label htmlFor="videoId">
                          <h5 className="text-blue py-2">Upload Media File </h5>
                        </label>
                        <div>
                          <Dropzone
                            {...videoId.input}
                            accept={{
                              "video/x-msvideo": [".avi"],
                              "video/mp4": [".mp4"],
                              "audio/mpeg": [".mp3"],
                              "video/mpeg": [".mpeg"],
                              "video/ogg": [".ogv"],
                              "video/mp2t": [".ts"],
                              "video/webm": [".webm"],
                              "video/3gpp": [".3gp"],
                              "audio/3gpp": [".3gp"],
                              "video/3gpp2": [".3g2"],
                              "audio/3gpp2": [".3g2"],
                            }}
                            multiple={false}
                            onDrop={(acceptedFiles) =>
                              handleAcceptedFiles2(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone-sec">
                                <div
                                  className="d-flex pt-4"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3 ms-3">
                                    <img
                                      src="assets/mediafile.svg"
                                      alt="media file"
                                      className="mt-2"
                                    />
                                  </div>
                                  <div className="ps-3">
                                    <h5 className="fw-600">
                                      Select the Media.
                                    </h5>
                                    <p>Upload Media files</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3 d-flex justify-content-between flex-wrap"
                            id="file-previews"
                          >
                            {selectedFiles2.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border-0 d-inline"
                                  key={i + "-file"}
                                >
                                  <div className="thumb2">
                                    <video
                                      controls
                                      className="d-inline thumbInner bg-black"
                                      width={"250px"}
                                    >
                                      <source src={f.preview} />
                                    </video>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setSelectedFiles2(selectedFiles2.pop());

                                        setSelectedFiles2([...selectedFiles2]);
                                      }}
                                      className="bg-transparent border-0"
                                    >
                                      {" "}
                                      <div className="middle">
                                        <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                      </div>
                                    </button>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                          <p className="font-blue fs-6 pt-2">
                            Select the Media.
                          </p>
                          {videoId.meta.error && videoId.meta.touched && (
                            <div className="text-danger">
                              {videoId.meta.error}
                            </div>
                          )}
                        </div>
                      </div>
                      <h4 className="text-blue py-2">Media Datails</h4>
                      <div className="pt-4 pb-1">
                        <>
                          <label htmlFor="title">
                            <h5 className="text-blue py-2">Media Name </h5>
                          </label>
                          <input
                            {...title.input}
                            type="text"
                            className="form-control border-light-blue text-black py-3 fw-600"
                            placeholder="Media Name"
                          />
                          {title.meta.error && title.meta.touched && (
                            <div className="text-danger">
                              {title.meta.error}
                            </div>
                          )}
                        </>
                      </div>
                      <div className="mt-5 text-lg-end text-center">
                        <button
                          type="reset"
                          onClick={() => {
                            setSelectedFiles([]);
                            setSelectedFiles2([]);

                            form.reset();
                          }}
                          className="btn btn-light btn-lg text-secondary fw-bold me-3"
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-blue px-5 btn-lg text-white fw-bold"
                        >
                          Save
                        </button>
                      </div>
                      {/* <Field name="videoId">
                        {({ input, meta }) => (
                          <>
                            <div className="pt-4 pb-1">
                              <label htmlFor="media">
                                <h5 className="text-blue py-2">
                                  Upload Media File{" "}
                                </h5>
                              </label>

                              <Dropzone
                                accept={{
                                  "video/x-msvideo": [".avi"],
                                  "video/mp4": [".mp4"],
                                  "audio/mpeg": [".mp3"],
                                  "video/mpeg": [".mpeg"],
                                  "video/ogg": [".ogv"],
                                  "video/mp2t": [".ts"],
                                  "video/webm": [".webm"],
                                  "video/3gpp": [".3gp"],
                                  "audio/3gpp": [".3gp"],
                                  "video/3gpp2": [".3g2"],
                                  "audio/3gpp2": [".3g2"],
                                }}
                                multiple={false}
                                onDrop={(acceptedFiles) =>
                                  handleAcceptedFiles2(acceptedFiles)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone-sec">
                                    <div
                                      className="d-flex pt-4"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-3 ms-3">
                                        <img
                                          src="assets/mediafile.svg"
                                          alt="media file"
                                          className="mt-2"
                                        />
                                      </div>
                                      <div className="ps-3">
                                        <h5 className="fw-600">
                                          Select the Media.
                                        </h5>
                                        <p>Upload Media files</p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3 d-flex justify-content-between flex-wrap"
                                id="file-previews"
                              >
                                {selectedFiles2.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border-0 d-inline"
                                      key={i + "-file"}
                                    >
                                      <div className="thumb2">
                                        <video
                                          controls
                                          className="d-inline thumbInner bg-black"
                                          width={"250px"}
                                        >
                                          <source src={f.preview} />
                                        </video>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setSelectedFiles2(
                                              selectedFiles2.pop()
                                            );

                                            setSelectedFiles2([
                                              ...selectedFiles2,
                                            ]);
                                          }}
                                          className="bg-transparent border-0"
                                        >
                                          {" "}
                                          <div className="middle">
                                            <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                          </div>
                                        </button>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>
                              <p className="font-blue fs-6 pt-2">
                                Select the Media.
                              </p>
                              {meta.error && meta.touched && (
                                <div className="text-danger">{meta.error}</div>
                              )}
                            </div>
                          </>
                        )}
                      </Field>

                      <h4 className="text-blue py-2">Media Datails</h4>
                      <Field name="title">
                        {({ input, meta }) => (
                          <>
                            <label htmlFor="title">
                              <h5 className="text-blue py-2">Media Name </h5>
                            </label>
                            <input
                              {...input}
                              type="text"
                              className="form-control border-light-blue text-black py-3 fw-600"
                              placeholder="Media Name"
                            />
                            {meta.error && meta.touched && (
                              <div className="text-danger">{meta.error}</div>
                            )}
                          </>
                        )}
                      </Field> */}
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </form>
              {/* <Form
                onSubmit={onSubmit1}
                initialValues={{ thumbnail: "", videoId: "", title: "" }}
                validate={(values) => {
                  const errors = {};
                  if (selectedFiles.length < 1) {
                    errors.thumbnail = "This value is required";
                  }
                  if (selectedFiles2.length < 1) {
                    errors.videoId = "This value is required";
                  }
                  if (!values.title) {
                    errors.title = "This value is required";
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  submitting,
                  pristine,
                  values,
                  errors,
                  valid,
                  touched,
                  form,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <h3 className="text-black pb-3">Upload Media</h3>
                    <Card className="py-3 border-0 shadow">
                      <CardBody>
                        <CardTitle className="text-blue fs-5 fw-600">
                          Upload Media
                        </CardTitle>
                        <CardSubtitle>
                          <Field name="thumbnail">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="thumbnail">
                                  <h5 className="text-blue pt-3">
                                    Upload Media Thumbnail{" "}
                                  </h5>
                                </label>
                                <div className="text-center">
                                  <Dropzone
                                    accept={{
                                      "image/png": [".png"],
                                      "image/jpeg": [".jpeg", ".jpg"],
                                    }}
                                    id="recommendationDiv"
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                      handleAcceptedFiles(acceptedFiles);
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick pt-5"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="fs-2 fa-solid text-gray fa-cloud-arrow-up"></i>
                                          </div>
                                          <h4>
                                            Drop logo here or click to upload.
                                          </h4>
                                          <p className="font-blue fs-6">
                                            Set the product thumbnail image.
                                            Only *.png, *.jpg and *.jpeg image
                                            files are accepted
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews d-flex mt-3"
                                    id="file-previews"
                                  >
                                    {selectedFiles?.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                                          key={i + "-file"}
                                        >
                                          <div className="thumb">
                                            <img
                                              data-dz-thumbnail=""
                                              className="img-fluid d-inline thumbInner"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setSelectedFiles(
                                                  selectedFiles.pop()
                                                );

                                                setSelectedFiles([
                                                  ...selectedFiles,
                                                ]);
                                              }}
                                              className="bg-transparent border-0"
                                            >
                                              {" "}
                                              <div className="middle">
                                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                              </div>
                                            </button>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>

                          <Field name="videoId">
                            {({ input, meta }) => (
                              <>
                                <div className="pt-4 pb-1">
                                  <label htmlFor="media">
                                    <h5 className="text-blue py-2">
                                      Upload Media File{" "}
                                    </h5>
                                  </label>

                                  <Dropzone
                                    accept={{
                                      "video/x-msvideo": [".avi"],
                                      "video/mp4": [".mp4"],
                                      "audio/mpeg": [".mp3"],
                                      "video/mpeg": [".mpeg"],
                                      "video/ogg": [".ogv"],
                                      "video/mp2t": [".ts"],
                                      "video/webm": [".webm"],
                                      "video/3gpp": [".3gp"],
                                      "audio/3gpp": [".3gp"],
                                      "video/3gpp2": [".3g2"],
                                      "audio/3gpp2": [".3g2"],
                                    }}
                                    multiple={false}
                                    onDrop={(acceptedFiles) =>
                                      handleAcceptedFiles2(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone-sec">
                                        <div
                                          className="d-flex pt-4"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3 ms-3">
                                            <img
                                              src="assets/mediafile.svg"
                                              alt="media file"
                                              className="mt-2"
                                            />
                                          </div>
                                          <div className="ps-3">
                                            <h5 className="fw-600">
                                              Select the Media.
                                            </h5>
                                            <p>Upload Media files</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3 d-flex justify-content-between flex-wrap"
                                    id="file-previews"
                                  >
                                    {selectedFiles2.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                                          key={i + "-file"}
                                        >
                                          <div className="thumb2">
                                            <video
                                              controls
                                              className="d-inline thumbInner bg-black" width={"250px"}
                                            >
                                              <source
                                                src={f.preview}
                                              />
                                            </video>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setSelectedFiles2(
                                                  selectedFiles2.pop()
                                                );

                                                setSelectedFiles2([
                                                  ...selectedFiles2,
                                                ]);
                                              }}
                                              className="bg-transparent border-0"
                                            >
                                              {" "}
                                              <div className="middle">
                                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                              </div>
                                            </button>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                  <p className="font-blue fs-6 pt-2">
                                    Select the Media.
                                  </p>
                                  {meta.error && meta.touched && (
                                    <div className="text-danger">
                                      {meta.error}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>

                          <h4 className="text-blue py-2">Media Datails</h4>
                          <Field name="title">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="title">
                                  <h5 className="text-blue py-2">
                                    Media Name{" "}
                                  </h5>
                                </label>
                                <input
                                  {...input}
                                  type="text"
                                  className="form-control border-light-blue text-black py-3 fw-600"
                                  placeholder="Media Name"
                                />
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                    {JSON.parse(localStorage.getItem("authUser")).roleId ==
                    5 ? (
                      <>
                        {temp && temp.C == true ? (
                          <div className="mt-5 text-lg-end text-center">
                            <button
                              type="reset"
                              onClick={() => {
                                setSelectedFiles([]);
                                setSelectedFiles2([]);
                                for (let keys in values) {
                                  form.resetFieldState(keys);
                                }
                                form.reset();
                              }}
                              className="btn btn-light btn-lg text-secondary fw-bold me-3"
                            >
                              Discard
                            </button>
                            <button
                              type="submit"
                              className="btn btn-blue px-5 btn-lg text-white fw-bold"
                              onClick={() => {
                                if (valid) {
                                  onSubmit1();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="mt-5 text-lg-end text-center">
                          <button
                            type="reset"
                            onClick={() => {
                              setSelectedFiles([]);
                              setSelectedFiles2([]);
                              for (let keys in values) {
                                form.resetFieldState(keys);
                              }
                              form.reset();
                            }}
                            className="btn btn-light btn-lg text-secondary fw-bold me-3"
                          >
                            Discard
                          </button>
                          <button
                            type="submit"
                            className="btn btn-blue px-5 btn-lg text-white fw-bold"
                            onClick={() => {
                              if (valid) {
                                onSubmit1();
                              }
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                )}
              /> */}
            </div>
            {/* motivational video end */}

            {/* free song start */}
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <Form
                onSubmit={onSubmit2}
                initialValues={{
                  thumbnail: "",
                  item: "",
                  artist: "",
                  name: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (selectedFiles3.length < 1) {
                    errors.thumbnail = "This value is required";
                  }
                  if (selectedFiles4.length < 1) {
                    errors.item = "This value is required";
                  }
                  if (!values.name) {
                    errors.name = "This value is required";
                  }
                  if (!values.artist) {
                    errors.artist = "This value is required";
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  submitting,
                  pristine,
                  values,
                  errors,
                  valid,
                  touched,
                  form,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <h3 className="text-black pb-3">Upload Media</h3>
                    <Card className="py-3 border-0 shadow">
                      <CardBody>
                        <CardTitle className="text-blue fs-5 fw-600">
                          Upload Media
                        </CardTitle>
                        <CardSubtitle>
                          <Field name="thumbnail">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="thumbnail">
                                  <h5 className="text-blue pt-3">
                                    Upload Media Thumbnail{" "}
                                  </h5>
                                </label>
                                <div className="text-center">
                                  <Dropzone
                                    accept={{
                                      "image/png": [".png"],
                                      "image/jpeg": [".jpeg", ".jpg"],
                                    }}
                                    multiple={false}
                                    onDrop={(acceptedFiles) =>
                                      handleAcceptedFiles3(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick pt-5"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="fs-2 fa-solid text-gray fa-cloud-arrow-up"></i>
                                          </div>
                                          <h4>
                                            Drop logo here or click to upload.
                                          </h4>
                                          <p className="font-blue fs-6">
                                            Set the product thumbnail image.
                                            Only *.png, *.jpg and *.jpeg image
                                            files are accepted
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews d-flex mt-3"
                                    id="file-previews"
                                  >
                                    {selectedFiles3?.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                                          key={i + "-file"}
                                        >
                                          <div className="thumb">
                                            <img
                                              data-dz-thumbnail=""
                                              className="img-fluid d-inline thumbInner"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setSelectedFiles3(
                                                  selectedFiles3.pop()
                                                );

                                                setSelectedFiles3([
                                                  ...selectedFiles3,
                                                ]);
                                              }}
                                              className="bg-transparent border-0"
                                            >
                                              {" "}
                                              <div className="middle">
                                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                              </div>
                                            </button>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                          <Field name="item">
                            {({ input, meta }) => (
                              <>
                                <div className="pt-4 pb-1">
                                  <label htmlFor="media">
                                    <h5 className="text-blue py-2">
                                      Upload Media File{" "}
                                    </h5>
                                  </label>
                                  <Dropzone
                                    accept={{
                                      "video/x-msvideo": [".avi"],
                                      "video/mp4": [".mp4"],
                                      "audio/mpeg": [".mp3"],
                                      "video/mpeg": [".mpeg"],
                                      "video/ogg": [".ogv"],
                                      "video/mp2t": [".ts"],
                                      "video/webm": [".webm"],
                                      "video/3gpp": [".3gp"],
                                      "audio/3gpp": [".3gp"],
                                      "video/3gpp2": [".3g2"],
                                      "audio/3gpp2": [".3g2"],
                                    }}
                                    multiple={false}
                                    onDrop={(acceptedFiles) =>
                                      handleAcceptedFiles4(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone-sec">
                                        <div
                                          className="d-flex pt-4"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3 ms-3">
                                            <img
                                              src="assets/mediafile.svg"
                                              alt="media file"
                                              className="mt-2"
                                            />
                                          </div>
                                          <div className="ps-3">
                                            <h5 className="fw-600">
                                              Select the Media.
                                            </h5>
                                            <p>Upload Media files</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3 d-flex justify-content-between flex-wrap"
                                    id="file-previews"
                                  >
                                    {selectedFiles4.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                                          key={i + "-file"}
                                        >
                                          <div className="thumb2">
                                            <audio
                                              controls
                                              className="d-inline bg-black thumbInner h-100"
                                            >
                                              <source src={f.preview} />
                                            </audio>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setSelectedFiles4(
                                                  selectedFiles4.pop()
                                                );

                                                setSelectedFiles4([
                                                  ...selectedFiles4,
                                                ]);
                                              }}
                                              className="bg-transparent border-0"
                                            >
                                              {" "}
                                              <div className="middle">
                                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                              </div>
                                            </button>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                  <p className="font-blue fs-6 pt-2">
                                    Select the Media.
                                  </p>
                                  {meta.error && meta.touched && (
                                    <div className="text-danger">
                                      {meta.error}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                          <h4 className="text-blue py-2">Media Datails</h4>
                          <Field name="name">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="name">
                                  <h5 className="text-blue py-2">Song Name </h5>
                                </label>
                                <input
                                  {...input}
                                  type="text"
                                  className="form-control border-light-blue text-black py-3 fw-600"
                                  placeholder="Song name"
                                />
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>

                          <Field name="artist">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="artist">
                                  <h5 className="text-blue py-2">
                                    Song Artist
                                  </h5>
                                </label>
                                <input
                                  type="text"
                                  {...input}
                                  className="form-control border-light-blue text-black py-3 fw-600"
                                  placeholder="Song artist name"
                                />
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                        </CardSubtitle>
                      </CardBody>
                    </Card>

                    {JSON.parse(localStorage.getItem("authUser")).roleId ==
                    5 ? (
                      <>
                        {temp && temp.C == true ? (
                          <div className="mt-5 text-lg-end text-center">
                            <button
                              type="reset"
                              onClick={() => {
                                setSelectedFiles3([]);
                                setSelectedFiles4([]);
                                for (let keys in values) {
                                  form.resetFieldState(keys);
                                }
                                form.reset();
                              }}
                              className="btn btn-light btn-lg text-secondary fw-bold me-3"
                            >
                              Discard
                            </button>
                            <button
                              type="submit"
                              className="btn btn-blue px-5 btn-lg text-white fw-bold"
                              onClick={() => {
                                if (valid) {
                                  onSubmit2();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="mt-5 text-lg-end text-center">
                          <button
                            type="reset"
                            onClick={() => {
                              setSelectedFiles3([]);
                              setSelectedFiles4([]);
                              for (let keys in values) {
                                form.resetFieldState(keys);
                              }
                              form.reset();
                            }}
                            className="btn btn-light btn-lg text-secondary fw-bold me-3"
                          >
                            Discard
                          </button>
                          <button
                            type="submit"
                            className="btn btn-blue px-5 btn-lg text-white fw-bold"
                            onClick={() => {
                              if (valid) {
                                onSubmit2();
                              }
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                )}
              />
            </div>
            {/* free song end */}

            {/* paid song start */}
            <div
              className="tab-pane fade"
              id="paidSong"
              role="tabpanel"
              aria-labelledby="paidSong-tab"
            >
              <Form
                onSubmit={onSubmit3}
                initialValues={{
                  thumbnail: "",
                  item: "",
                  artist: "",
                  name: "",
                  category: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (selectedFiles5.length < 1) {
                    errors.thumbnail = "This value is required";
                  }
                  if (selectedFiles6.length < 1) {
                    errors.item = "This value is required";
                  }
                  if (!values.name) {
                    errors.name = "This value is required";
                  }
                  if (!values.artist) {
                    errors.artist = "This value is required";
                  }
                  if (!values.category) {
                    errors.category = "This value is required";
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  submitting,
                  pristine,
                  values,
                  valid,
                  errors,
                  touched,
                  form,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <h3 className="text-black pb-3">Upload Media</h3>
                    <Card className="py-3 border-0 shadow">
                      <CardBody>
                        <CardTitle className="text-blue fs-5 fw-600">
                          Upload Media
                        </CardTitle>
                        <Field
                          name="category"
                          className="rounded border-0 text-white fs-6 ps-2 my-4 pe-5 py-2 btn-blue"
                          component="select"
                        >
                          <option
                            className="bg-white fs-6 text-black"
                            value=""
                            disabled
                          >
                            Select
                          </option>
                          <option
                            className="bg-white fs-6 text-black"
                            value="1"
                          >
                            Visualize Track
                          </option>
                          <option
                            className="bg-white fs-6 text-black"
                            value="2"
                          >
                            Repeat Track
                          </option>
                          <option
                            className="bg-white fs-6 text-black"
                            value="3"
                          >
                            Hype Pre-game Track
                          </option>
                        </Field>
                        <CardSubtitle>
                          <Field name="thumbnail">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="thumbnail">
                                  <h5 className="text-blue pt-3">
                                    Upload Media Thumbnail{" "}
                                  </h5>
                                </label>
                                <div className="text-center">
                                  <Dropzone
                                    accept={{
                                      "image/png": [".png"],
                                      "image/jpeg": [".jpeg", ".jpg"],
                                    }}
                                    multiple={false}
                                    onDrop={(acceptedFiles) =>
                                      handleAcceptedFiles5(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick pt-5"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="fs-2 fa-solid text-gray fa-cloud-arrow-up"></i>
                                          </div>
                                          <h4>
                                            Drop logo here or click to upload.
                                          </h4>
                                          <p className="font-blue fs-6">
                                            Set the product thumbnail image.
                                            Only *.png, *.jpg and *.jpeg image
                                            files are accepted
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews d-flex mt-3"
                                    id="file-previews"
                                  >
                                    {selectedFiles5?.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                                          key={i + "-file"}
                                        >
                                          <div className="thumb">
                                            <img
                                              data-dz-thumbnail=""
                                              className="img-fluid d-inline thumbInner"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setSelectedFiles5(
                                                  selectedFiles5.pop()
                                                );

                                                setSelectedFiles5([
                                                  ...selectedFiles5,
                                                ]);
                                              }}
                                              className="bg-transparent border-0"
                                            >
                                              {" "}
                                              <div className="middle">
                                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                              </div>
                                            </button>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                          <Field name="item">
                            {({ input, meta }) => (
                              <>
                                <div className="pt-4 pb-1">
                                  <label htmlFor="media">
                                    <h5 className="text-blue py-2">
                                      Upload Media File{" "}
                                    </h5>
                                  </label>
                                  <Dropzone
                                    accept={{
                                      "video/x-msvideo": [".avi"],
                                      "video/mp4": [".mp4"],
                                      "audio/mpeg": [".mp3"],
                                      "video/mpeg": [".mpeg"],
                                      "video/ogg": [".ogv"],
                                      "video/mp2t": [".ts"],
                                      "video/webm": [".webm"],
                                      "video/3gpp": [".3gp"],
                                      "audio/3gpp": [".3gp"],
                                      "video/3gpp2": [".3g2"],
                                      "audio/3gpp2": [".3g2"],
                                    }}
                                    multiple={false}
                                    onDrop={(acceptedFiles) =>
                                      handleAcceptedFiles6(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone-sec">
                                        <div
                                          className="d-flex pt-4"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3 ms-3">
                                            <img
                                              src="assets/mediafile.svg"
                                              alt="media file"
                                              className="mt-2"
                                            />
                                          </div>
                                          <div className="ps-3">
                                            <h5 className="fw-600">
                                              Select the Media.
                                            </h5>
                                            <p>Upload Media files</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3 d-flex justify-content-between flex-wrap"
                                    id="file-previews"
                                  >
                                    {selectedFiles6.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border-0 d-inline"
                                          key={i + "-file"}
                                        >
                                          <div className="thumb2">
                                            <audio
                                              controls
                                              className="d-inline bg-black thumbInner  h-100"
                                            >
                                              <source src={f.preview} />
                                            </audio>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setSelectedFiles6(
                                                  selectedFiles6.pop()
                                                );

                                                setSelectedFiles6([
                                                  ...selectedFiles6,
                                                ]);
                                              }}
                                              className="bg-transparent border-0"
                                            >
                                              {" "}
                                              <div className="middle">
                                                <i className="fa-solid fa-circle-xmark display-6 text-info"></i>
                                              </div>
                                            </button>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                  <p className="font-blue fs-6 pt-2">
                                    Select the Media.
                                  </p>
                                  {meta.error && meta.touched && (
                                    <div className="text-danger">
                                      {meta.error}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                          <h5 className="text-blue py-2">Media Datails</h5>
                          <Field name="name">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="name">
                                  <h5 className="text-blue py-2">Song Name </h5>
                                </label>
                                <input
                                  {...input}
                                  type="text"
                                  className="form-control border-light-blue text-black py-3 fw-600"
                                  placeholder="Media Name"
                                />
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                          <Field name="artist">
                            {({ input, meta }) => (
                              <>
                                <label htmlFor="artist">
                                  <h5 className="text-blue py-2">
                                    Song Artist
                                  </h5>
                                </label>
                                <input
                                  {...input}
                                  type="text"
                                  className="form-control border-light-blue text-black py-3 fw-600"
                                  placeholder="Song artist name"
                                />
                                {meta.error && meta.touched && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                    {JSON.parse(localStorage.getItem("authUser")).roleId ==
                    5 ? (
                      <>
                        {temp && temp.C == true ? (
                          <div className="mt-5 text-lg-end text-center">
                            <button
                              type="reset"
                              onClick={() => {
                                setSelectedFiles5([]);
                                setSelectedFiles6([]);
                                for (let keys in values) {
                                  form.resetFieldState(keys);
                                }
                                form.reset();
                              }}
                              className="btn btn-light btn-lg text-secondary fw-bold me-3"
                            >
                              Discard
                            </button>
                            <button
                              type="submit"
                              className="btn btn-blue px-5 btn-lg text-white fw-bold"
                              onClick={() => {
                                if (valid) {
                                  onSubmit3();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="mt-5 text-lg-end text-center">
                          <button
                            type="reset"
                            onClick={() => {
                              setSelectedFiles5([]);
                              setSelectedFiles6([]);
                              for (let keys in values) {
                                form.resetFieldState(keys);
                              }
                              form.reset();
                            }}
                            className="btn btn-light btn-lg text-secondary fw-bold me-3"
                          >
                            Discard
                          </button>
                          <button
                            type="submit"
                            className="btn btn-blue px-5 btn-lg text-white fw-bold"
                            onClick={() => {
                              if (valid) {
                                onSubmit3();
                              }
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                )}
              />
            </div>
            {/* paid song end */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddMedia;

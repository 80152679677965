import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import Login from "../../pages/authentication/Login";
import Signup from "../../pages/authentication/Signup";
import ForgotPass from "../../pages/authentication/ForgotPass";
import SideBar from "../../components/sidebar/SideBar";

import Topbar from "./Topbar";
import Dashboard from "../../pages/dashboard/Dashboard";
import Userinfo from "../../pages/user-information/Userinfo";
import AddMerchandise from "../../pages/merchandise/AddMerchandise";
import AddCategories from "../../pages/merchandise/AddCategories";
import MerchandiseList from "../../pages/merchandise/MerchandiseList";
import EditMerchandise from "../../pages/merchandise/EditMerchandise";
import PaidUserPremium from "../../pages/paiduser/PaidUserPremium";
import UserTrack from "../../pages/user-track/UserTrack";
import ShippingDetail from "../../pages/shipping/ShippingDetail";
import ViewShippingDetail from "../../pages/shipping/ViewShippingDetail";
import AddUserRole from "../../pages/user-role/AddUserRole";
import UserRoleList from "../../pages/user-role/UserRoleList";
import GoogleAds from "../../pages/google-ads/GoogleAds";
import UserStates from "../../pages/highest-user-states/UserStates";
import SalesOrderListing from "../../pages/sales-order/SalesOrderListing";
import SalesOrderDetail from "../../pages/sales-order/SalesOrderDetail";
import AddMedia from "../../pages/media/AddMedia";
import MediaList from "../../pages/media/MediaList";
import BillingPayment from "../../pages/billing-payment/BillingPayment";
import LiveLocation from "../../pages/live-location/LiveLocation";
import AddGame from "../../pages/add-game/AddGame";
import GameList from "../../pages/add-game/GameList";
import Resetpass from "../../pages/authentication/Resetpass";
import Home from "../../pages/home/Home";
import MerchandiseRefund from "../../pages/merchandiseRefund/MerchandiseRefund";
import { getAllModule, getModuleById } from "../../Api";
import Terms from "../../pages/terms-condition/Terms";
import PrivacyPolicy from "../../pages/terms-condition/PrivacyPolicy";
import ChallengedReport from "../../pages/challenges-report/ChallengedReport";
import DetailReportChallenge from "../../pages/challenges-report/DetailReportChallenge";

const Content = () => {
  const isAuth = localStorage.getItem("authUser");
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [moduleById, setModuleById] = useState([]);
  const [activeIndexs, setActiveIndexs] = useState([]);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (
      authRoutes.filter((x) => x.path == location.pathname).length &&
      isAuth == null
    ) {
      if (location.pathname == "/privacy-policy") {
        navigate("/privacy-policy");
      } else if (location.pathname == "/terms-conditions") {
        navigate("/terms-conditions");
      } else {
        navigate("/login");
      }
      // navigate("/login");
    } else {
      if (!isAuth && location.pathname != "/login") {
        if (location.pathname == "/privacy-policy") {
          navigate("/privacy-policy");
        } else if (location.pathname == "/terms-conditions") {
          navigate("/terms-conditions");
        } else {
          navigate("/");
        }
      }
    }
  }, []);
  useEffect(() => {}, [moduleById]);
  useEffect(() => {
    // if(document.getElementById("left_side_menu")) {
    //   let openMenuList = document.getElementById("left_side_menu").childNodes;
    //   for (const key in openMenuList) {
    //     if (openMenuList[key].tagName == "DIV" && activeIndexs[0] == openMenuList[key].id) {
    //       openMenuList[key].childNodes[0].classList.add(
    //         "menu-open",
    //         "active-item"
    //       );
    //       openMenuList[key].childNodes[1].classList.add("show");
    //     } else if (openMenuList[key].tagName == "DIV") {
    //       openMenuList[key].childNodes[0].classList.remove(
    //         "menu-open",
    //         "active-item"
    //       );
    //       openMenuList[key].childNodes[1].classList.remove("show");
    //     }
    //   }
    // }
  }, [activeIndexs]);
  useEffect(() => {
    (async () => {
      if (isAuth) {
        if (JSON.parse(localStorage.getItem("authUser")).roleId == 5) {
          const data = await getModuleById({
            id: JSON.parse(localStorage.getItem("authUser")).id,
          });
          if (data.data) setModuleById(data.data.modules);
        }
      }
    })();
  }, []);

  const authRoutes = [
    {
      path: "/Dashboard",
      element: (
        <Dashboard
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/User-information",
      element: (
        <Userinfo
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/Edit-Merchandise",
      element: (
        <EditMerchandise
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/Add-Merchandise",
      element: (
        <AddMerchandise
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/add-categories",
      element: (
        <AddCategories
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/Merchandise-List",
      element: (
        <MerchandiseList
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/edit-merchandise",
      element: (
        <EditMerchandise
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/Paid-User",
      element: (
        <PaidUserPremium
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/refered-user-track",
      element: (
        <UserTrack
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/shipping-details",
      element: (
        <ShippingDetail
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/view-shipping-detail",
      element: (
        <ViewShippingDetail
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/add-role-user",
      element: (
        <AddUserRole
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/role-list",
      element: (
        <UserRoleList
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/adsgoogle",
      element: (
        <GoogleAds
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/highest-user-states",
      element: (
        <UserStates
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/billing-for-payments",
      element: (
        <BillingPayment
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/merchandise-refund",
      element: (
        <MerchandiseRefund
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/sales",
      element: (
        <SalesOrderListing
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/sales-view-detail",
      element: (
        <SalesOrderDetail
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/add-media",
      element: (
        <AddMedia
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/media-list",
      element: (
        <MediaList
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/live-location",
      element: (
        <LiveLocation
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    // {
    //   path: "/add-game",
    //   element: (
    //     <AddGame
    //       moduleById={moduleById}
    //       activeIndexs={activeIndexs}
    //       setActiveIndexs={setActiveIndexs}
    //     />
    //   ),
    // },
    // {
    //   path: "/games-list",
    //   element: (
    //     <GameList
    //       moduleById={moduleById}
    //       activeIndexs={activeIndexs}
    //       setActiveIndexs={setActiveIndexs}
    //     />
    //   ),
    // },
    {
      path: "/Challenges-Report",
      element: (
        <ChallengedReport
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/detail-report-challenge",
      element: (
        <DetailReportChallenge
          moduleById={moduleById}
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/terms-conditions",
      element: (
        <Terms activeIndexs={activeIndexs} setActiveIndexs={setActiveIndexs} />
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <PrivacyPolicy
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
  ];
  const publicRoutes = [
    {
      path: "/",
      element: (
        <Home activeIndexs={activeIndexs} setActiveIndexs={setActiveIndexs} />
      ),
    },
    {
      path: "/login",
      element: (
        <Login activeIndexs={activeIndexs} setActiveIndexs={setActiveIndexs} />
      ),
    },
    {
      path: "/signup",
      element: (
        <Signup activeIndexs={activeIndexs} setActiveIndexs={setActiveIndexs} />
      ),
    },
    {
      path: "/forgotPass",
      element: (
        <ForgotPass
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/reset-password",
      element: (
        <Resetpass
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
    {
      path: "/terms-conditions",
      element: (
        <Terms activeIndexs={activeIndexs} setActiveIndexs={setActiveIndexs} />
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <PrivacyPolicy
          activeIndexs={activeIndexs}
          setActiveIndexs={setActiveIndexs}
        />
      ),
    },
  ];

  const routes = useRoutes(isAuth != null ? authRoutes : publicRoutes);

  return (
    <>
      {location.pathname == "/privacy-policy" ||
      location.pathname == "/terms-conditions" ? (
        routes
      ) : (
        <>
          <div className="App wrapper">
            {isAuth && (
              <SideBar
                toggle={toggleSidebar}
                isOpen={sidebarIsOpen}
                activeIndexs={activeIndexs}
                setActiveIndexs={setActiveIndexs}
              />
            )}

            <Container
              fluid
              className={classNames("content", { "is-open": sidebarIsOpen })}
            >
              {isAuth && (
                <Topbar
                  toggleSidebar={toggleSidebar}
                  activeIndexs={activeIndexs}
                  setActiveIndexs={setActiveIndexs}
                />
              )}
              {routes}
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Content;

import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { baseUrl, getAllSalesOrder, getAllUser, getDashboard } from "../../Api";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import Breadcrumbs from "../../components/common/Breadcrumbs";
import moment from "moment";

const Dashboard = () => {
  const [orderList, setOrderList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [count, setCount] = useState({
    userCount:"",
    totalMerchandise: "",
    totalOrder:"",
    merchandiseSaleAmount:""
  });

  useEffect(() => {
    allSalesOrder();
  }, []);

  const allSalesOrder = async () => {
    const data = await getDashboard();
    if(data.success == true){
      setOrderList(data.data.salesOrders);
      setUserList(data.data.users)
      setCount({
        userCount: data.data.userCount,
        totalMerchandise: data.data.totalMerchandise,
        totalOrder: data.data.totalOrder,
        merchandiseSaleAmount: data.data.merchandiseSaleAmount,
      })
    }
  };



  const columns2 = [
    {
      dataField: "fullname",
      text: "#",
      hidden: true,
      searchable: true,
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "userName",
      text: "Name",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row align-items-center">
          <div>
            {!row?.profileImagePath ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2">
            <span className="fw-bold"> {row?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "roleId",
      text: "Role",
      formatter: (cell, row) => {
        if(cell == 1){
          return "Player"
        } else if(cell == 2){
          return "Coach"
        }else if(cell == 3){
          return "Parent"
        }
      },
    },
    {
      dataField: "university",
      text: "School/University"
    },
    {
      dataField: "email",
      text: "Email"
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: (cell, row) => {
        return cell == 1 ? "Male" : "Female";
      },
    },
    {
      dataField: "sport",
      text: "Sport User Play",
      formatter: (cell, row) => {
        return cell ? cell.name : "";
      },
    },
    {
      dataField: "age",
      text: "Age",
    },
    // {
    //   dataField: "infoFavPlayer",
    //   text: "Fav. Pro. Player",
    //   formatter: (cell, row) => {
    //     return cell?.length > 27 ? cell.substring(0, 27) + "..." : cell;
    //   },
    //   style: (cell, row, rowIndex, colIndex) => {
    //     return {
    //       wordBreak: "break-word",
    //       width: "300px",
    //     };
    //   },
    // },
    {
      dataField: "status",
      text: "User Status",
      formatter: (cell, row) => {
        return cell == true ? (
          <button className="btn-success-green border-0 py-2 px-3 rounded">
            Active
          </button>
        ) : (
          <button className="btn-danger-red border-0 py-2 px-3 rounded">
            Inactive
          </button>
        );
      },
    },
  ];

  const columns1 = [
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "id",
      text: "Order Id",
    },
    {
      dataField: "userName",
      text: "Customer",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row align-items-center">
          <div>
            {!row?.user?.profileImagePath? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.user?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2">
            <span className="fw-bold"> {row?.user?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        if (cell == 1) {
          return (
            <button className="btn-success-green border-0 py-2 px-3 rounded">
              Ordered
            </button>
          );
        } else if (cell == 2) {
          return (
            <button className="btn-blue-info border-0 py-2 px-3 rounded">
              Delivered
            </button>
          );
        } else {
          return (
            <button className="btn-danger-red border-0 py-2 px-3 rounded">
              Refunded
            </button>
          );
        }
      },
    },
    {
      dataField: "TotalAmount",
      text: "Total Amount",
      formatter: (cell, row) => {
        return `$ ${cell}`;
      },
    },
    {
      dataField: "createdAt",
      text: "Order Date",
      formatter: (cell, row) => {
        return moment(cell).format("DD MMM, YYYY");
      },
    },
    {
      dataField: "phone",
      text: "Phone No",
    },
  ];
  return (
    <React.Fragment>
      <div className="bg-light-blue pb-5 page-content">
        <Row>
          <Breadcrumbs name={"Dashboard"} />
        </Row>
        <Row>
          <Col xxl={3} md={6} sm={12} className="py-3 px-3">
            <div className="bg-white rounded py-3 px-3">
              <div className="row">
                <div className="col-12">
                  <img src="assets/totalUser.svg" alt="income" />
                  <h5 className="text-secondary fw-medium py-3">Total Users</h5>
                  <h3 className="fw-medium">{count.userCount ? parseInt(count.userCount).toLocaleString() : 0}</h3>
                </div>
                {/* <div className="col-1 text-end">
                  <button className="bg-light-gray border-0 px-3 py-1 rounded">
                    <i className="fa-solid fa-ellipsis-vertical fs-4"></i>
                  </button>
                  <div className="pt-5">
                    <img src="assets/graphgreen.svg" alt="graphline" className="img-fluid mt-5" />
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          <Col xxl={3} md={6} sm={12} className="py-3 ps-sm-3 ps-0 pe-3">
            <div className="bg-white rounded py-3 px-3">
              <div className="row">
                <div className="col-12">
                  <img src="assets/totalMerchandise.svg" alt="dashboarduser" />
                  <h5 className="text-secondary fw-medium py-3">
                    Total Active Merchandise
                  </h5>
                  <h3 className="fw-medium">{count.totalMerchandise ? parseInt(count.totalMerchandise).toLocaleString() : 0}</h3>
                </div>
                {/* <div className="col-1 text-end">
                  <button className="bg-light-gray border-0 px-3 py-1 rounded">
                    <i className="fa-solid fa-ellipsis-vertical fs-4 "></i>
                  </button>
                  <div className="pt-5">
                    <img src="assets/graphred.svg" alt="graphline" className="img-fluid mt-5" />
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          <Col xxl={3} md={6} sm={12} className="py-3 ps-sm-3 ps-0 pe-3">
            <div className="bg-white rounded py-3 px-3">
              <div className="row">
                <div className="col-12">
                  <img src="assets/income.svg" alt="income" />
                  <h5 className="text-secondary fw-medium py-3">
                    Total Orders
                  </h5>
                  <h3 className="fw-medium">{count.totalOrder ? parseInt(count.totalOrder).toLocaleString() : 0}</h3>
                </div>
                {/* <div className="col-3 text-end">
                  <button className="bg-light-gray border-0 px-3 py-1 rounded">
                    <i className="fa-solid fa-ellipsis-vertical fs-4 "></i>
                  </button>
                  <div className="pt-5">
                    <img src="assets/graphgreen.svg" alt="graphline" className="img-fluid mt-5" />
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          <Col xxl={3} md={6} sm={12} className="py-3 ps-sm-3 ps-0 pe-3">
            <div className="bg-white rounded py-3 px-3">
              <div className="row">
                <div className="col-12">
                  <img src="assets/totalSaleAmount.svg" alt="income" />
                  <h5 className="text-secondary fw-medium py-3">
                    Merchandise Sale Amount
                  </h5>
                  <h3 className="fw-medium"> $ {count.merchandiseSaleAmount ? parseInt(count.merchandiseSaleAmount).toLocaleString() : 0} </h3>
                </div>
                {/* <div className="col-1 text-end">
                  <button className="bg-light-gray border-0 px-3 py-1 rounded">
                    <i className="fa-solid fa-ellipsis-vertical fs-4 "></i>
                  </button>
                  <div className="pt-5">
                    <img src="assets/graphgreen.svg" alt="graphline" className="img-fluid mt-5" />
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-4 mx-3 rounded-3 bg-white px-4">
          <Col md={6} sm={12} className="text-md-start text-center">
            <h4 className="py-4 text-blue fw-600">Sales Orders Listing</h4>
          </Col>
          <Col md={6} sm={12} className="text-md-end text-center pt-3">
            <Link to="/sales">
              <button className="btn-blue py-2 fs-5 text-white rounded border-0 px-4 fw-600">
                View All
              </button>{" "}
            </Link>
          </Col>
          <Row>
            <div className="table-responsive">
              <div>
                <BootstrapTable
                  remote
                  onTableChange={() => false}
                  headerClasses="align-self-center"
                  rowClasses=""
                  keyField="id"
                  responsive
                  bordered={false}
                  bootstrap4={true}
                  striped={false}
                  data={orderList}
                  columns={columns1}
                  classes={"table align-middle table-nowrap"}
                  headerWrapperClasses={"thead-light text-grey-light"}
                />
              </div>
            </div>
          </Row>
        </Row>

        <Row className="mt-5 mx-3 rounded-3 bg-white px-4">
          <Col md={6} sm={12} className="text-md-start text-center">
            <h4 className="py-4 text-blue fw-600">User Information</h4>
          </Col>
          <Col md={6} sm={12} className="text-md-end text-center pt-3">
            <Link to="/User-Information">
              <button className="btn-blue py-2 fs-5 text-white rounded border-0 px-4 fw-600">
                View All
              </button>{" "}
            </Link>
          </Col>
          <Row>
            <div className="table-responsive wordbreak">
              <div>
                <BootstrapTable
                  remote
                  onTableChange={() => false}
                  headerClasses="align-self-center"
                  rowClasses=""
                  keyField="id"
                  responsive
                  bordered={false}
                  bootstrap4={true}
                  striped={false}
                  data={userList}
                  columns={columns2}
                  classes={"table align-middle table-nowrap"}
                  headerWrapperClasses={"thead-light text-grey-light"}
                />
              </div>
            </div>
          </Row>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

import React, { useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Row, Card, Col } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useForm, useField } from "react-final-form-hooks";
import { disableUser, signUpAdmin } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const AddUserRole = (props) => {
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }

  const location = useLocation();

  let formdata = location?.state?.userRole;

  const onSubmit = async (values) => {
    if (formdata) {
      let temp = {
        id: formdata.id,
        fullname: values.fullName,
        password: values.password,
        email: values.email,
        age: values.age,
        gender: values.gender,
        isSubAdmin: true,
        status: parseInt(values.status),
      };

      const data = await disableUser(temp);
      if (data.success == true) {
        {
          const notify = () => toast.success(data.message);
          notify();
        }
      } else {
        {
          const errorNotify = () => toast.error(data.message);
          errorNotify();
        }
      }
    } else {
      let temp = {
        fullname: values.fullName,
        password: values.password,
        email: values.email,
        signupvia: 1,
        gender: parseInt(values.gender),
        age: values.age,
        roleId: 5,
        status: parseInt(values.status),
      };
      const data = await signUpAdmin(temp);
      if (data.success == true) {
        {
          const notify = () => toast.success(data.message);
          notify();
        }
      } else {
        {
          const errorNotify = () => toast.error(data.message);
          errorNotify();
        }
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
    const regexPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (!values.fullName) {
      errors.fullName = "This value is required";
    }
    if (!values.email) {
      errors.email = "This value is required";
    } else if (!regexEmail.test(values.email)) {
      errors.email = "Please Provide valid Email ID";
    }
    if (!formdata) {
      if (!values.password) {
        errors.password = "This value is required";
      } else if (!regexPass.test(values.password)) {
        errors.password = "Please enter unique values";
      }
      if (values.confirmPass !== values.password) {
        errors.confirmPass = "Password must match";
      }
    }

    if (!values.age) {
      errors.age = "This value is required";
    }
    if (!values.gender) {
      errors.gender = "This value is required";
    }
    if (!values.status) {
      errors.status = "This value is required";
    }
    return errors;
  };

  const { form, handleSubmit, values, pristine, submitting, initialValues } =
    useForm({
      onSubmit,
      validate,
      initialValues: {
        fullName: "",
        email: "",
        password: "",
        confirmPass: "",
        age: "",
        gender: "",
        status: "",
      },
    });

  const fullName = useField("fullName", form);
  const email = useField("email", form);
  const password = useField("password", form);
  const confirmPass = useField("confirmPass", form);
  const age = useField("age", form);
  const gender = useField("gender", form);
  const status = useField("status", form);

  useEffect(() => {
    if (formdata) {
      form.reset({
        fullName: formdata.fullname,
        email: formdata.email,
        password: formdata.password,
        confirmPass: formdata.confirmPass,
        age: formdata.age,
        gender: formdata.gender,
        status: formdata.status == true ? "1" : "2",
      });
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container-fluid page-content pb-5 bg-light-blue">
        <Row>
          {formdata ? (
            <Breadcrumbs name={"Edit Role User"} />
          ) : (
            <Breadcrumbs name={"Add Role User"} />
          )}
        </Row>

        <form onSubmit={handleSubmit}>
          <Row className="bg-white px-4 py-4 rounded">
            <div className="row">
              <label htmlFor="fullName">
                <h5 className="text-blue pt-3"> Full Name</h5>
              </label>
              <div className="pt-2">
                <input
                  {...fullName.input}
                  type="text"
                  className="form-control border-light-blue text-black py-3 fw-600"
                  placeholder="Full Name"
                />
                {fullName.meta.error && fullName.meta.touched && (
                  <div className="text-danger pt-2">{fullName.meta.error}</div>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="email">
                <h5 className="text-blue pt-3"> Email</h5>
              </label>
              <div className="pt-2">
                <input
                  {...email.input}
                  type="email"
                  className="form-control border-light-blue text-black py-3 fw-600"
                  placeholder="Email"
                />
                {email.meta.error && email.meta.touched && (
                  <div className="text-danger pt-2">{email.meta.error}</div>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="password">
                <h5 className="text-blue pt-3"> Set Password</h5>
              </label>
              <div className="pt-2">
                <input
                  {...password.input}
                  type="password"
                  className="form-control border-light-blue text-black py-3 fw-600"
                  placeholder="Password"
                />
                {password.meta.error && password.meta.touched && (
                  <div className="text-danger pt-2">{password.meta.error}</div>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="confirmPass">
                <h5 className="text-blue pt-3"> Confirm Password</h5>
              </label>
              <div className="pt-2">
                <input
                  {...confirmPass.input}
                  type="password"
                  className="form-control border-light-blue text-black py-3 fw-600"
                  placeholder="Confirm Password"
                />
                {confirmPass.meta.error && confirmPass.meta.touched && (
                  <div className="text-danger pt-2">
                    {confirmPass.meta.error}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="age">
                <h5 className="text-blue pt-3"> Age</h5>
              </label>
              <div className="pt-2">
                <input
                  {...age.input}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="form-control border-light-blue text-black py-3 fw-600"
                  placeholder="User Age"
                />
                {age.meta.error && age.meta.touched && (
                  <div className="text-danger pt-2">{age.meta.error}</div>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="gender">
                <h5 className="text-blue pt-3"> Gender</h5>
              </label>
              <div className="pt-2">
                <select
                  {...gender.input}
                  type="text"
                  className="form-control w-100 form-select border-light-blue text-black py-3 fw-600"
                  placeholder="Admin"
                >
                  <option value="">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </select>
                {gender.meta.error && gender.meta.touched && (
                  <div className="text-danger pt-2">{gender.meta.error}</div>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="gender">
                <h5 className="text-blue pt-3"> Status</h5>
              </label>
              <div className="pt-2">
                <select
                  {...status.input}
                  type="text"
                  className="form-control form-select border-light-blue text-black py-3 w-100 fw-600"
                  placeholder="Admin"
                >
                  <option value="">Select</option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </select>
                {status.meta.error && status.meta.touched && (
                  <div className="text-danger pt-2">{status.meta.error}</div>
                )}
              </div>
            </div>
          </Row>
          {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
            temp && (temp.C == true || temp.U == true) ? (
              <div className="mt-5 text-lg-end text-center">
                <button
                  type="button"
                  onClick={() => {
                    for (let keys in values) {
                      form.resetFieldState(keys);
                    }
                    form.reset();
                  }}
                  className="btn btn-light btn-lg text-secondary fw-bold me-3"
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-blue btn-lg text-white fw-bold"
                >
                  {formdata ? "Save" : "Add User"}
                </button>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div className="mt-5 text-lg-end text-center">
              <button
                type="button"
                onClick={() => {
                  for (let keys in values) {
                    form.resetFieldState(keys);
                  }
                  form.reset();
                }}
                className="btn btn-light btn-lg text-secondary fw-bold me-3"
              >
                Discard
              </button>
              <button
                type="submit"
                className="btn btn-blue btn-lg text-white fw-bold"
              >
                {formdata ? "Save" : "Add User"}
              </button>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddUserRole;

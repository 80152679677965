import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light py-xxl-4 bg-white">
        <div className="container-fluid px-xxl-5 px-3">
          <div className="ps-0 ps-md-5">
            <a href="/#/">
              <img
                src="assets/images/mainlogo.png"
                alt="logo"
                className="img-fluid width-100"
              />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ps-xl-5">
                <a className="nav-link nav-links d-inline-block" href="##home">
                  Home
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a className="nav-link nav-links d-inline-block" href="##about">
                  About
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a className="nav-link nav-links d-inline-block" href="##blog">
                  Blog
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a
                  className="nav-link nav-links d-inline-block"
                  href="##feature"
                >
                  Feature
                </a>
              </li>
              <li className="nav-item ps-xl-5">
                <a
                  className="nav-link nav-links d-inline-block"
                  href="##contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="d-lg-flex d-block ps-0">
              {/* <li className="nav-item dropdown list-unstyled pe-xxl-3">
                <a
                  className="nav-link dropdown-toggle text-dark fs-5 fw-bold ps-0"
                  href="##"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  En <i className="fa-solid fa-angle-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="##">
                      Language 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="##">
                      Language 2
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item list-unstyled pe-xxl-5">
                <a
                  className="nav-link d-inline-block text-dark fs-5 fw-bold ps-0"
                  href="##"
                >
                  <img
                    src="assets/images/contact-vector.png"
                    alt="contact-vector"
                    className="img-fluid"
                  />
                  My Account
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="bg-light-blue text-info-blue fw-bold py-5 text-center">
        <h1 className="py-5">Privacy Policy</h1>
      </div>

      <div className="container pt-3">
        <p className="pt-5">
          Please read our Privacy Policy carefully as it explains the following:
        </p>
        <p className="pt-3">
          In order to use the App, you must first acknowledge and agree to our
          Privacy Policy. You cannot use the App without first accepting our
          Privacy Policy.
        </p>
        <h5 className="pt-2 text-info-blue">GENERAL</h5>
        <p className="pt-2">
          GET HYPE LLC (“Get Hype LLC”, “we”, “us”) recognizes and understands
          the importance of the privacy of its users (“users”, “You”) and wants
          to respect their desire to store and access personal information in a
          private and secure manner. This Privacy Policy applies to our
          application and describes how Get Hype LLC manages, stores, and
          utilizes your personal data through its products.
        </p>
        <p className="pt-2">
          In order to use our app, we require you to consent to the collection
          and processing of your personal data before you start using the app.
          If you do not agree with the terms of this Privacy Policy, you may not
          use in any manner the app.
        </p>
        <p className="pt-2">
          Get Hype LLC is committed to protecting the privacy of all of its
          users' personal data and providing a secure, user-controlled
          environment for the use of the app.
        </p>
        <h5 className="pt-2 text-info-blue">PERSONAL DATA YOU PROVIDE</h5>
        <p className="pt-2">
          Account Credentials. In order to use the Get Hype services, you are
          required to provide an Account Authentication Mechanism (phone number,
          first name, last name, email address and address, or a third-party
          social log-in like Google, or Facebook). When creating an account, Get
          Hype stores a hashed version of your Account Authentication Mechanism
          for authentication and/or verification purposes. We use a third-party
          service for verification and authentication.
        </p>
        <p className="pt-2">
          Profile Information. When you create a Get Hype account, you choose to
          provide us with.
        </p>
        <p className="pt-2">
          Personal Data about yourself. For example, in the Get Hype App, you
          may provide your Personal Data, such as first and last name, email
          address, phone number, address and any other information that is
          required to positively identify the user.
        </p>
        <p className="pt-2">
          Feedback/Support. If you provide us feedback or contact customer
          support, we collect your name and email address and possibly other
          Personal Data (e.g. IP address), as well as any other content that you
          send to us in order to reply and address any issue. If you contact
          customer support, we also collect and retain certain technical
          diagnostic data, such as your phone model. We may keep records of our
          communication with you, including any complaints we receive from you
          about other users (and from other users about you).
        </p>
        <p className="pt-2">
          Photos, Videos, and Audio. If you upload a photo or video, while using
          the Get Hype Services, you will be required to permit Get Hype to
          access your camera, photo gallery, and microphone in which case Get
          Hype will have access to the photos and videos stored in your photo
          gallery.
        </p>
        <h5 className="pt-2 text-info-blue">PERMISSIONS REQUIRED</h5>
        <h6 className="fw-bold text-gray pt-3"> INTERNET</h6>
        <h6 className="fw-bold text-gray"> PHOTO LIBRARY</h6>
        <h6 className="fw-bold text-gray">CAMERA</h6>
        <h6 className="fw-bold text-gray">NOTIFICATION</h6>
        <p className="pt-2">
          Link to the privacy policy of third-party service providers used by
          the app.
        </p>
        <p className="pt-2 mb-0">Apple App Store Services</p>
        <p className="pt-0">Google Play Store Services</p>
        <h5 className="pt-2 text-info-blue">LOG DATA</h5>
        <p className="pt-2">
          Get Hype LLC wants to inform you that whenever you use our Service, in
          case of an error in the app, we collect data and information (through
          third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics
        </p>

        <h5 className="pt-2 text-info-blue">COOKIES & USAGE DATA</h5>
        <p className="pt-2">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device’s internal
          memory.
        </p>
        <p className="pt-2">
          This Service does not use these “cookies” explicitly. However, the app
          may use third-party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </p>
        <h5 className="pt-2 text-info-blue">USAGE DATA</h5>
        <p className="pt-2">
          When you access the Service with a mobile device, we may collect
          certain information automatically, including, but not limited to, the
          type of mobile device you use, your mobile device unique ID, the IP
          address of your mobile device, your mobile operating system, the type
          of mobile Internet browser you use, unique device identifiers and
          other diagnostic data (“Usage Data”).
        </p>
        <h5 className="pt-2 text-info-blue">YOUR PERSONAL DATA</h5>
        <p className="pt-2">
          Get Hype LLC is using a strong backend for the Get Hype App. In the
          backend, all the necessary information will be stored. All Data will
          remain safe and secure. No, single information/data will be sent
          anywhere.
        </p>
        <h5 className="pt-2 text-info-blue">SECURITY</h5>
        <p className="pt-2">
          We value your trust in providing us with your Personal Information,
          thus we are striving to use commercially acceptable means of
          protecting it. But remember that no method of transmission over the
          internet, or method of electronic storage is 100% secure and reliable,
          and We cannot guarantee its absolute security.
        </p>
        <h5 className="pt-2 text-info-blue">PAYMENT TERMS</h5>
        <p className="pt-2">
          We may provide paid products and/or services within the Service. In
          that case, we use third-party services for payment processing (e.g.
          payment processors).
        </p>
        <p className="pt-2">
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy.
        </p>
        <h5 className="pt-2 fw-bold text-gray">
          The payment processors we work with are:
        </h5>
        <p className="pt-2">Apple Store In-App Payments</p>
        <p className="">
          Their Privacy Policy can be viewed at
          <a href="https://www.apple.com/legal/privacy/en-ww/">
            {" "}
            https://www.apple.com/legal/privacy/en-ww/.
          </a>
        </p>
        <p className="">Google Play In-App Payments</p>
        <p className="">
          Their Privacy Policy can be viewed at
          <a href="https://www.google.com/policies/privacy/">
            {" "}
            https://www.google.com/policies/privacy/.
          </a>
        </p>
        <h5 className="pt-2 text-info-blue">SERVICE PROVIDERS</h5>
        <p className="pt-2">
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <p className="pt-2 mb-0">To facilitate our Service.</p>
        <p className="mb-0">To provide the Service on our behalf.</p>
        <p className="mb-0">To perform Service-related services; or</p>
        <p className="mb-0">
          To assist us in analyzing how our Service is used.
        </p>
        <p className="pt-2">
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>
        <h5 className="pt-2 text-info-blue">CHILDREN’S PRIVACY</h5>
        <p className="pt-2">
          These Services do not address anyone under the age of 4. We do not
          knowingly collect personally identifiable information from children
          under 4. In the case We discover that a child under 4 has provided me
          with personal information, We immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that We will be able to do the necessary actions.
        </p>
        <h5 className="pt-2 text-info-blue">BEHAVIORAL REMARKETING</h5>
        <p className="pt-2">
          Get Hype LLC uses remarketing services to advertise on third-party
          websites to you after you visited our Service. We and our third-party
          vendors use cookies to inform, optimize and serve ads based on your
          past visits to our Service.
        </p>
        <h5 className="pt-2 text-info-blue">Google Ads (AdWords)</h5>
        <p className="">
          Google Ads (AdWords) remarketing service is provided by Google Inc.
        </p>
        <p className="">
          You can opt out of Google Analytics for Display Advertising and
          customize the Google Display
        </p>
        <p className="">
          Network ads by visiting the Google Ads Settings page:
          <a target="_blank" href="http://www.google.com/settings/ads">
            {" "}
            http://www.google.com/settings/ads.
          </a>
        </p>
        <p className="">
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on{" "}
          <a target="_blank" href="https://tools.google.com/dlpage/gaoptout">
            {" "}
            – https://tools.google.com/dlpage/gaoptout –{" "}
          </a>
          for your web browser. Google Analytics Opt-out Browser Add-on provides
          visitors with the ability to prevent their data from being collected
          and used by Google Analytics.
        </p>
        <p className="">
          For more information on the privacy practices of Google, please visit
          the Google Privacy Terms web page:{" "}
          <a target="_blank" href="https://policies.google.com/privacy?hl=en">
            {" "}
            https://policies.google.com/privacy?hl=en
          </a>
        </p>

        <h5 className="pt-2 text-info-blue">Facebook</h5>
        <p className="pt-2">
          Facebook remarketing service is provided by Facebook Inc.
        </p>
        <p className="pt-2">
          You can learn more about interest-based advertising from Facebook by
          visiting this page:
        </p>
        <p className="pt-2">
          <a
            target="_blank"
            href="https://www.facebook.com/help/164968693837950"
          ></a>
          https://www.facebook.com/help/164968693837950
        </p>
        <p className="pt-2">
          To opt-out from Facebook’s interest-based ads, follow these
          instructions from Facebook:
          <a
            target="_blank"
            href="https://www.facebook.com/help/568137493302217"
          >
            https://www.facebook.com/help/568137493302217.
          </a>
        </p>

        <p className="pt-2">
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioral Advertising established by the Digital Advertising
          Alliance. You can also opt out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA{" "}
          <a target="_blank" href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/,{" "}
          </a>{" "}
          the Digital Advertising Alliance of Canada in Canada{" "}
          <a target="_blank" href="http://youradchoices.ca/">
            http://youradchoices.ca/{" "}
          </a>{" "}
          or the European Interactive Digital Advertising Alliance in Europe{" "}
          <a target="_blank" href="http://www.youronlinechoices.eu/">
            http://www.youronlinechoices.eu/,
          </a>{" "}
          or opt-out using your mobile device settings.
        </p>
        <p className="pt-2">
          For more information on the privacy practices of Facebook, please
          visit Facebook’s Data Policy:{" "}
          <a
            target="_blank"
            href="https://www.facebook.com/privacy/explanation"
          >
            {" "}
            https://www.facebook.com/privacy/explanation.
          </a>
        </p>

        <h5 className="pt-2 text-info-blue">
          UPDATES OR CHANGES TO OUR PRIVACY POLICY
        </h5>
        <p className="pt-2">
          Occasionally, we may change or update this Privacy Policy to allow us
          to use or share your previously collected Personal Data for other
          purposes. If Get Hype LLC would use your Personal Data in a manner
          materially different from that stated at the time of the collection,
          we will provide you with a notice on our Website and in our Get Hype
          Mobile Application indicating that the Privacy Policy has been changed
          or updated and request you to agree with the updated or changed
          Privacy Policy.
        </p>
        <h5 className="pt-2 text-info-blue">CONTROLLER</h5>
        <p className="pt-2">
          Get Hype LLC{" "}
          <a target="_blank" href="mailto:leanna@get-hype-app.com">
            {" "}
            leanna@get-hype-app.com{" "}
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";

// Table data

const products = [
  {
    id: 1,
    userRank: "#01",
    userName: "David Wiley",
    orderId: "13379",
    thumbnail: "assets/Avatar.png",
    status: true,
    locationsLiveLink: "https://www.google.com/maps/place/Opera+business",
    locations: "3891 Ranchview Dr. Richardson, California 62639",
  },
  {
    id: 2,
    userRank: "#02",
    userName: "David Wiley",
    orderId: "45698",
    thumbnail: "assets/Avatar.png",
    status: false,
    locationsLiveLink: "https://www.google.com/maps/place/Opera+business",
    locations: "2715 Ash Dr. San Jose, South Dakota 83475",
  },
  {
    id: 3,
    userRank: "#03",
    userName: "David Wiley",
    orderId: "78965",
    thumbnail: "assets/Avatar.png",
    status: false,
    locationsLiveLink: "https://www.google.com/maps/place/Opera+business",
    locations: "2118 Thornridge Cir. Syracuse, Connecticut 35624",
  },
  {
    id: 4,
    userRank: "#04",
    userName: "David Wiley",
    orderId: "13379",
    thumbnail: "assets/Avatar.png",
    status: true,
    locationsLiveLink: "https://www.google.com/maps/place/Opera+business",
    locations: "4140 Parker Rd. Allentown, New Mexico 31134",
  },
];

const LiveLocation = (props) => {
  const [productData, setProductData] = useState(products);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "userRank",
      text: "User Rank",
    },
    {
      dataField: "customer",
      text: "Customer",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row">
          <div>
            <img src={row?.thumbnail} alt="thumbnail" />
          </div>
          <div className="ps-2 pt-3">
            <span className="fw-bold"> {row?.userName} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        if (row?.status == true) {
          return (
            <button className="btn-success-green border-0 py-2 px-3 rounded">
              Active
            </button>
          );
        } else {
          return (
            <button className="btn-danger-red border-0 py-2 px-3 rounded">
             Inactive
            </button>
          );
        }
      },
    },
    {
      dataField: "locationsLiveLink",
      text: "Locations Live Link",
      formatter: (cell, row) => {
       return <a href="##">{row?.locationsLiveLink}</a>
      },
    },
    {
      dataField: "locations",
      text: "Locations",
    },
  ];

  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: products.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <DeleteModal />
      {
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={[...columns]}
              data={[...productData]}
              search
            >
              {(toolkitprops) => (
                <React.Fragment>
                  <div className="container-fluid page-content bg-light-blue px-lg-3">
                    <h3 className="text-black fw-bold text-uppercase pt-3">
                    Live Locations
                    </h3>
                    <Row>
                      <Col lg="6" md="12" className="text-center text-lg-start">
                        <div className="pt-4">
                          <p className="d-inline pe-3 fs-6 fw-600 text-blue">Show</p>
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              className=""
                              {...paginationProps}
                            />
                          </div>

                          <p className="d-inline ps-3 fs-6 fw-600 text-blue">Entries</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3 rounded-3 bg-white px-4">
                        <h4 className="py-4 text-blue fw-600">User Live Locations </h4>
                        <Row>
                          <div className="table-responsive">
                            <div>
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light text-grey-light"}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                      <h5>Showing 1 - 10 of 350</h5>

                      <div className="ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      }
    </React.Fragment>
  );
};

export default LiveLocation;

import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
// import companyImage from '../../../public/images/dashboard-images/companyLogo.svg'

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/common/DeleteModal";
import { baseUrl, getAllUser } from "../../Api";

// Table data

const Userinfo2 = (props) => {
  const { SearchBar } = Search;
  let temp;
  if (props.moduleById) {
    temp = props.moduleById[props.activeIndexs[1]];
  }
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [deleteId, setDeleteId] = useState(0);
  const [totalSize, setTotalSize] = useState(10);
  const [userList, setuserList] = useState([]);
  const [userStatus, setUserStatus] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userName, setUserName] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");
  const rowStyle = (row, rowIndex) => {
    return { whiteSpace: "pre-line" };
  };
  useEffect(() => {
    allUser();
  }, [page, sizePerPage, userRole, userStatus, userName, totalSize]);

  const allUser = async () => {
    const data = await getAllUser({
      page: page,
      limit: sizePerPage,
      roleId: userRole,
      name: userName,
    });
    if (data.success == true) {
      setTotalSize(data.count);
      setuserList(data.data);
    }
    getFilter();
  };

  const columns = [
    {
      dataField: "fullname",
      text: "#",
      hidden: true,
      searchable: true,
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "srNo",
      text: "#",
      formatter: (cell, row, rowIndex) => {
        if (rowIndex + 1 < 10) {
          return "0" + (rowIndex + 1);
        } else {
          return rowIndex + 1;
        }
      },
    },
    {
      dataField: "userName",
      text: "Name",
      classes: "items-center",
      formatter: (cell, row) => (
        <div className="d-flex flex-row align-items-center">
          <div>
            {!row?.profileImagePath ? (
              <img src="assets/Avatar.png" className="width-50" />
            ) : (
              <img
                src={row?.profileImagePath}
                className="width-50"
              />
            )}
          </div>
          <div className="ps-2">
            <span className="fw-bold"> {row?.fullname} </span>
          </div>
        </div>
      ),
    },
    {
      dataField: "roleId",
      text: "Role",
      formatter: (cell, row) => {
        if(cell == 1){
          return "Player"
        } else if(cell == 2){
          return "Coach"
        }else if(cell == 3){
          return "Parent"
        }
      },
    },
    {
      dataField: "university",
      text: "School/University"
    },
    {
      dataField: "email",
      text: "Email"
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: (cell, row) => {
        return cell == 1 ? "Male" : "Female";
      },
    },
    {
      dataField: "sport",
      text: "Sport User Play",
      formatter: (cell, row) => {
        return cell ? cell.name : "";
      },
    },
    {
      dataField: "age",
      text: "Age",
    },
    // {
    //   dataField: "infoFavPlayer",
    //   text: "Fav. Pro. Player",
    //   formatter: (cell, row) => {
    //     return cell?.length > 27 ? cell.substring(0, 27) + "..." : cell;
    //   },
    //   style: (cell, row, rowIndex, colIndex) => {
    //     return {
    //       wordBreak: "break-word",
    //       width: "300px",
    //     };
    //   },
    // },
    {
      dataField: "status",
      text: "User Status",
      formatter: (cell, row) => {
        return cell == true ? (
          <button className="btn-success-green border-0 py-2 px-3 rounded">
            Active
          </button>
        ) : (
          <button className="btn-danger-red border-0 py-2 px-3 rounded">
            Inactive
          </button>
        );
      },
    },
    JSON.parse(localStorage.getItem("authUser")).roleId == 5
      ? temp && temp.D == true
        ? {
            dataField: "action",
            text: "Action",
            formatter: (cell, row) => {
              return (
                <div>
                  <button
                    className="border-0 bh-light py-2 px-2 rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() =>
                      setDeleteIndex({ isDelete: false, index: row?.id })
                    }
                  >
                    <img src="assets/delete.svg" alt="delete" />
                  </button>
                </div>
              );
            },
          }
        : {}
      : {
          dataField: "action",
          text: "Action",
          formatter: (cell, row) => {
            return (
              <div class="form-check form-switch">
                <input
                  className="form-check-input widthperRem"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  checked={row.status == true ? true : false}
                  onChange={(e) => {
                    setDeleteIndex({
                      isDelete: false,
                      index: row?.id,
                      data: row,
                    });
                  }}
                />
              </div>
            );
          },
        },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    onPageChange: (page, limit) => {
      setPage(page);
      setSizePerPage(limit);
    },
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => {
      return (
        <li key={text} role="presentation" className="dropdown-item">
          <a
            href="#"
            className="text-dark dropdown-item"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              onSizePerPageChange(page);
              setPage("1");
              setSizePerPage(page);
            }}
          >
            {text}
          </a>
        </li>
      );
    },
    sizePerPageList: [10, 20, 30],
    // onSizePerPageChange: (sizePerPage, page) => {
    //   setPage(page);
    //   setSizePerPage(sizePerPage);
    // },
    // sizePerPageList: [
    //   {
    //     text: "10",
    //     value: 10,
    //   },
    //   {
    //     text: "20",
    //     value: 20,
    //   },
    //   {
    //     text: "30",
    //     value: 30,
    //   },
    // ],

    page: page,
    totalSize: totalSize,
    custom: true,
  };

  const getFilter = () => {
    return userList?.filter((x) => {
      if (userStatus == "") return x;
      if (userStatus == JSON.stringify(x.status)) return x;
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      {userList.length > 0 ? (
        <>
          {JSON.parse(localStorage.getItem("authUser")).roleId == 5 ? (
            <>
              {temp && temp.R == true ? (
                <>
                  <DeleteModal deleteIndex={deleteIndex} allUser={allUser} />
                  {
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={[...columns]}
                          data={getFilter()}
                          search
                        >
                          {(toolkitprops) => (
                            <React.Fragment>
                              <div className="container-fluid page-content bg-light-blue px-lg-3">
                                <h3 className="text-black fw-bold text-uppercase pt-3">
                                  User Information
                                </h3>
                                <Row>
                                  <Col
                                    xxl="3"
                                    sm="12"
                                    className="text-xxl-start text-center"
                                  >
                                    <div className="pt-4">
                                      <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                        Show
                                      </p>
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          className=""
                                          {...paginationProps}
                                        />
                                      </div>

                                      <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                        Entries
                                      </p>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl="3"
                                    sm="12"
                                    className="text-xxl-end text-center pt-4"
                                  >
                                    <div className="me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <label
                                          htmlFor="search"
                                          className="pt-2 fs-6 text-blue fw-600 pe-1 pe-lg-3"
                                        >
                                          Search
                                        </label>
                                        <input
                                          value={userName}
                                          onChange={(e) => {
                                            setPage(1);
                                            setUserName(e.target.value);
                                            allUser();
                                          }}
                                          className="border-light-primary bg-transparent py-1 rounded ps-1"
                                          type="search"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl="3"
                                    sm="12"
                                    className="text-xxl-end text-center pt-4"
                                  >
                                    <div>
                                      <div className="pt-xl-2 pt-0">
                                        <label
                                          htmlFor="status"
                                          className="pt-2 fs-6 text-blue fw-600 pe-1 d-inline pe-lg-3"
                                        >
                                          User Status
                                        </label>
                                        <select
                                          name="userStatus"
                                          className="d-inline bg-transparent form-select border-light-primary py-2 pe-5 ps-2 rounded"
                                          onChange={(e) => {
                                            setPage(1);
                                            setUserStatus(e.target.value);
                                          }}
                                        >
                                          <option value="">All</option>
                                          <option value="true">Active</option>
                                          <option value="false">
                                            Inactive
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl="3"
                                    sm="12"
                                    className="text-xxl-end text-center pt-4"
                                  >
                                    <div>
                                      <div className="pt-xl-2 pt-0">
                                        <label
                                          htmlFor="Role"
                                          className="pt-2 fs-6 text-blue fw-600 pe-1 d-inline pe-lg-3"
                                        >
                                          User Role
                                        </label>
                                        <select
                                          name="userRole"
                                          className="d-inline bg-transparent ps-2 form-select border-light-primary py-2 pe-5 rounded"
                                          value={userRole}
                                          onChange={(e) => {
                                            setPage(1);
                                            setUserRole(e.target.value);
                                            allUser();
                                          }}
                                        >
                                          <option value="">All</option>
                                          <option value="1">Player</option>
                                          <option value="2">Coach</option>
                                          <option value="3">Parent</option>
                                        </select>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <>
                                  <Row>
                                    <Col className="mt-3 rounded-3 bg-white px-4">
                                      <h4 className="py-4 text-blue fw-600">
                                        User Information
                                      </h4>
                                      <Row>
                                        <div className="table-responsive wordbreak">
                                          <div>
                                            <BootstrapTable
                                              remote
                                              onTableChange={function () {}}
                                              keyField="id"
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              columns={columns}
                                              data={getFilter()}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light text-grey-light"
                                              }
                                              {...toolkitprops.baseProps}
                                              {...paginationTableProps}
                                            />
                                            {/* <BootstrapTable
                                              remote
                                              keyField="id"
                                              headerClasses="align-self-center"
                                              rowClasses=""                                            
                                              responsive
                                              bordered={false}
                                              bootstrap4={true}
                                              onTableChange={() => false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light text-grey-light"
                                              }
                                              {...props.baseProps}
                                              {...toolkitprops.baseProps}
                                              {...paginationTableProps}
                                              pagination={paginationFactory(
                                                options
                                              )}
                                            /> */}
                                          </div>
                                        </div>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                                    <h6 className="text-blue fw-600 ps-2">
                                      {userList.length == 0
                                        ? "Showing 0"
                                        : `Showing ${
                                            (page - 1) * sizePerPage + 1
                                          } -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                                    </h6>
                                    <div className="ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </>
                                {/* {userList.length > 0 ? (
                              <>
                                <Row>
                                  <Col className="mt-3 rounded-3 bg-white px-4">
                                    <h4 className="py-4 text-blue">
                                      User Information
                                    </h4>
                                    <Row>
                                      <div className="table-responsive wordbreak">
                                        <div>
                                          <BootstrapTable
                                            remote
                                            onTableChange={() => false}
                                            headerClasses="align-self-center"
                                            rowClasses=""
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            bootstrap4={true}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...props.baseProps}
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            pagination={paginationFactory(
                                              options
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>

                                <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                                  <h5>
                                    Showing {(page - 1) * sizePerPage + 1} -{" "}
                                    {totalSize < sizePerPage
                                      ? totalSize
                                      : sizePerPage * page >= totalSize
                                      ? totalSize
                                      : sizePerPage * page}{" "}
                                    of {totalSize}
                                  </h5>

                                  <div className="ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </>
                            ) : (
                              <>

                              
                                <div className="container-fluid bg-white py-2 px-lg-3 mt-3">
                                  <h3 className="text-black text-center fw-bold text-uppercase pt-3">
                                    Data Not Found
                                  </h3>
                                </div>
                         
                              </>
                            )} */}
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <DeleteModal deleteIndex={deleteIndex} allUser={allUser} />
              {
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={[...columns]}
                      data={getFilter()}
                      search
                    >
                      {(toolkitprops) => (
                        <React.Fragment>
                          <div className="container-fluid page-content bg-light-blue px-lg-3">
                            <h3 className="text-black fw-bold text-uppercase pt-3">
                              User Information
                            </h3>
                            <Row>
                              <Col
                                xxl="3"
                                sm="12"
                                className="text-xxl-start text-center"
                              >
                                <div className="pt-4">
                                  <p className="d-inline pe-3 fs-6 fw-600 text-blue">
                                    Show
                                  </p>
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      className=""
                                      {...paginationProps}
                                    />
                                  </div>

                                  <p className="d-inline ps-3 fs-6 fw-600 text-blue">
                                    Entries
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xxl="3"
                                sm="12"
                                className="text-xxl-end text-center pt-4"
                              >
                                <div className="me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <label
                                      htmlFor="search"
                                      className="pt-2 fs-5 pe-1 fs-6 text-blue fw-600 pe-lg-3"
                                    >
                                      Search
                                    </label>
                                    <input
                                      placeholder="Search"
                                      value={userName}
                                      onChange={(e) => {
                                        setPage(1);
                                        setUserName(e.target.value);
                                        allUser();
                                      }}
                                      className="border-light-primary bg-transparent py-2 rounded ps-1"
                                      type="search"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xxl="3"
                                sm="12"
                                className="text-xxl-end text-center pt-4"
                              >
                                <div>
                                  <div className="pt-xl-2 pt-0">
                                    <label
                                      htmlFor="status"
                                      className="pt-2 fs-6 text-blue fw-600 pe-1 d-inline pe-lg-3"
                                    >
                                      User Status
                                    </label>
                                    <select
                                      name="userStatus"
                                      className="d-inline bg-transparent form-select fs-6 border-light-primary py-2 pe-5 ps-2 rounded"
                                      onChange={(e) => {
                                        setPage(1);
                                        setUserStatus(e.target.value);
                                      }}
                                    >
                                      <option value="">All</option>
                                      <option value="true">Active</option>
                                      <option value="false">Inactive</option>
                                    </select>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xxl="3"
                                sm="12"
                                className="text-xxl-end text-center pt-4"
                              >
                                <div>
                                  <div className="pt-xl-2 pt-0">
                                    <label
                                      htmlFor="Role"
                                      className="pt-2 fs-6 text-blue fw-600 pe-1 d-inline pe-lg-3"
                                    >
                                      User Role
                                    </label>
                                    <select
                                      name="userRole"
                                      className="d-inline bg-transparent ps-2 form-select border-light-primary py-2 pe-5 rounded"
                                      value={userRole}
                                      onChange={(e) => {
                                        setPage(1);
                                        setUserRole(e.target.value);
                                        allUser();
                                      }}
                                    >
                                      <option value="">All</option>
                                      <option value="1">Player</option>
                                      <option value="2">Coach</option>
                                      <option value="3">parent</option>
                                    </select>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <>
                              <Row>
                                <Col className="mt-3 rounded-3 bg-white px-4">
                                  <h4 className="py-4 text-blue fw-600">
                                    User Information
                                  </h4>
                                  <Row>
                                    <div className="table-responsive wordbreak">
                                      <div>
                                        <BootstrapTable
                                          remote
                                          onTableChange={function () {}}
                                          keyField="id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          columns={columns}
                                          data={getFilter()}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={
                                            "thead-light text-grey-light"
                                          }
                                          {...toolkitprops.baseProps}
                                          {...paginationTableProps}
                                        />
                                        {/* <BootstrapTable
                                          remote
                                          headerClasses="align-self-center"
                                          rowClasses=""
                                          keyField="id"
                                          responsive
                                          onTableChange={() => false}
                                          bordered={false}
                                          bootstrap4={true}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={
                                            "thead-light text-grey-light"
                                          }
                                          {...props.baseProps}
                                          {...toolkitprops.baseProps}
                                          {...paginationTableProps}
                                          pagination={paginationFactory(
                                            options
                                          )}
                                        /> */}
                                      </div>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>

                              <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                                <h6 className="text-blue fw-600 ps-2">
                                  {userList.length == 0
                                    ? "Showing 0"
                                    : `Showing ${(page - 1) * sizePerPage + 1} -
                            ${
                              totalSize < sizePerPage
                                ? totalSize
                                : sizePerPage * page >= totalSize
                                ? totalSize
                                : sizePerPage * page
                            }
                            of ${totalSize}`}
                                </h6>
                                <div className="ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </>
                            {/* {userList.length > 0 ? (
                          <>
                            <Row>
                              <Col className="mt-3 rounded-3 bg-white px-4">
                                <h4 className="py-4 text-blue">
                                  User Information
                                </h4>
                                <Row>
                                  <div className="table-responsive wordbreak">
                                    <div>
                                      <BootstrapTable
                                        remote
                                        onTableChange={() => false}
                                        headerClasses="align-self-center"
                                        rowClasses=""
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        bootstrap4={true}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...props.baseProps}
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        pagination={paginationFactory(options)}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Col className="inner-custom-pagination mt-4 d-block text-center d-sm-flex justify-content-lg-between">
                              <h5>
                                Showing {(page - 1) * sizePerPage + 1} -{" "}
                                {totalSize < sizePerPage
                                  ? totalSize
                                  : sizePerPage * page >= totalSize
                                  ? totalSize
                                  : sizePerPage * page}{" "}
                                of {totalSize}
                              </h5>

                              <div className="ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                         
                            <div className="container-fluid bg-white py-2 px-lg-3 mt-3">
                              <h5 className="text-black text-center fw-bold text-uppercase pt-3">
                                Data Not Found
                              </h5>
                            </div>
                           
                          </>
                        )} */}
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              }
            </>
          )}
        </>
      ) : (
        <>
          <div className="container-fluid page-content bg-light-blue px-lg-3">
            <h3 className="text-black fw-bold text-uppercase pt-3">
              User Information
            </h3>
            <Row>
              <Col
                xxl="3"
                sm="12"
                className="text-xxl-end text-center pt-4"
              ></Col>
              <Col xxl="3" sm="12" className="text-xxl-end text-center pt-4">
                <div className="me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <label
                      htmlFor="search"
                      className="pt-2 fs-6 text-blue fw-600 pe-1 pe-lg-3"
                    >
                      Search
                    </label>
                    <input
                      placeholder="search"
                      value={userName}
                      onChange={(e) => {
                        setPage(1);
                        setUserName(e.target.value);
                        allUser();
                      }}
                      className="border-light-primary bg-transparent py-1 rounded ps-1"
                      type="search"
                    />
                  </div>
                </div>
              </Col>
              <Col xxl="3" sm="12" className="text-xxl-end text-center pt-4">
                <div>
                  <div className="pt-xl-2 pt-0">
                    <label
                      htmlFor="status"
                      className="pt-2 fs-6 text-blue fw-600 pe-1 d-inline pe-lg-3"
                    >
                      User Status
                    </label>
                    <select
                      name="userStatus"
                      className="d-inline bg-transparent form-select fs-6 border-light-primary py-2 pe-5 ps-2 rounded"
                      onChange={(e) => setUserStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col xxl="3" sm="12" className="text-xxl-end text-center pt-4">
                <div>
                  <div className="pt-xl-2 pt-0">
                    <label
                      htmlFor="Role"
                      className="pt-2 fs-6 text-blue fw-600 pe-1 d-inline pe-lg-3"
                    >
                      User Role
                    </label>
                    <select
                      name="userRole"
                      className="d-inline ps-2 bg-transparent form-select border-light-primary py-2 pe-5 rounded"
                      value={userRole}
                      onChange={(e) => {
                        setUserRole(e.target.value);
                        allUser();
                      }}
                    >
                      <option value="">All</option>
                      <option value="1">Player</option>
                      <option value="2">Coach</option>
                      <option value="3">parent</option>
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="container-fluid bg-white py-2 px-lg-3 mt-3">
              <h5 className="text-black text-center fw-bold text-uppercase pt-3">
                Data Not Found
              </h5>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Userinfo2;
